import { EventBus, SafioEvents } from './event-bus';

export interface ApiError {
  code: string;
  message: string;
  status: number;
  stack?: string;
}

export interface ApiResponse<T> {
  success: boolean;
  error?: ApiError;
  response?: T;
}

class ApiService {

  /**
   *
   * @param {string} name
   * @returns {string}
   */
  public getCookie(name: string): string|null {
    const { cookie } = document;
    const startIndex = cookie.indexOf(name + '=')
    if (startIndex < 0) {
      return null
    }
    const start = startIndex + name.length + 1;
    let end   = cookie.indexOf(';', start);
    if (end === -1) {
      end = cookie.length
    }
    const value = cookie.slice(start, end);
    if (value.length) {
      return value
    }
    return null
  }

  public expiresIn = ''

  public setCookie(name: string, value: string, expiresIn: string) {
    document.cookie = `${name}=${value};Max-Age=${expiresIn}`
    // document.cookie = "accessToken=" + accessToken + ";Max-Age=" + expiresIn;
    // document.cookie = "username=" + encodeURIComponent(username) + ";Max-Age=" + expiresIn;
    // document.cookie = "namespace=" + encodeURIComponent(namespace) + ";Max-Age=" + expiresIn;
    // document.cookie = "roles=" + encodeURIComponent(roles.join(",")) + ";Max-Age=" + expiresIn;
  }

  public deleteCookie(name: string) {
    this.setCookie(name, '', new Date().toISOString())
  }

  private setItem(key: string, value: string | null) {
    if (value) {
      localStorage.setItem(key, value)
      this.setCookie(key, value, this.expiresIn)
    } else {
      localStorage.removeItem(key)
      this.deleteCookie(key)
    }
    return value
  }


  public set accessToken(value: string | null) {
    this.setItem('safio-token', value)
    if (value) {
      this.setCookie('accessToken', value, this.expiresIn)
    } else {
      this.deleteCookie('accessToken')
    }
  }

  public get accessToken(): string | null {
    const token = localStorage.getItem('safio-token')
    if (!token) {
      return this.getCookie('accessToken')
    }
    return token
  }

  public set tenant(value: string | null) {
    const prevVal = localStorage.getItem('safio-tenant')
    this.setItem('safio-tenant', value)

    if (value) {
      this.setCookie('namespace', encodeURIComponent(this.getBasicCodeAppNameSpace(value)), this.expiresIn)
      EventBus.$emit(SafioEvents.tenantChanged, value, prevVal)
    } else {
      this.deleteCookie('namespace')
    }
  }

  public get tenant(): string | null {
    const tenant = localStorage.getItem('safio-tenant')
    if (!tenant) {
      return this.getCookie('namespace')
    }
    return tenant
  }

  public getBasicCodeAppNameSpace(value: string): string {
    let val = value.toLowerCase()
    let env = ''
    if (val.endsWith('dev')) {
      env = 'Dev'
      val = val.replace('dev', '')
    } else if (val.endsWith('stage')) {
      env = 'Stage'
      val = val.replace('stage', '')
    }
    if (val === 'worldwise') {
      return `WorldWise${env}`
    }
    if (val === 'uniek') {
      return `Uniek${env}`
    }
    if (val === 'lovewellness') {
      return `LoveWellness${env}`
    }
    if (val === 'debriefme') {
      return `DebriefMe${env}`
    }
    if (val === 'aunaturale') {
      return `Aunaturale${env}`
    }
    if (val === 'wodbottom') {
      return `WodBottom${env}`
    }
    if (val === 'demo') {
      return 'Demo'
    }
    if (val === 'custom-demo' || val === 'customdemo') {
      return 'CustomDemo'
    }
    return value
  }

  private get apiVersion(): string {
    return process.env.VUE_APP_API_VERSION!
  }

  /**
   * Get the URL that the API is hosted at. This is dynamic based on the URL
   * that the frontend is being served from.
   */
  public get apiHost(): string {
    // the same Docker image (with the exact same env vars) is used on
    // production and staging, so we have to determine where we're being
    // hosted
    const frontendHost = window.location.host

    const tenantWithIndividualServers = ['emg', 'ferndale']

    if (process.env.NODE_ENV === 'development') {
      // when in development, use the value directly from the .env file to make
      // testing on different API servers easy
      return process.env.VUE_APP_API_HOST!
    } else if (tenantWithIndividualServers.includes(this.tenant!) && frontendHost === 'safio.app') {
      // emg and ferndale have their own API servers, but they're accessed
      // through the normal frontend
      return `https://api.${this.tenant}.safio.app/api/`
    } else {

      // The API is always hosted on an "api" subdomain of whatever domain we're
      // on. For example:
      // safio.app -> api.safio.app
      // qa.safio.app -> api.qa.safio.app
      return `https://api.${frontendHost}/api/`
    }
  }

  public get tenantApiUrl(): string {
    return `${this.apiHost}${this.apiVersion}/${this.tenant}`
  }
}

export default new ApiService()
