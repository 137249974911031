export class User {
  sub!: string
  names?: string
  nickname?: string
  picture?: string
  updatedAt?: string
  email!: string
  emailVerified?: boolean

  provider?: string
  id?: string

  givenName?: string
  familyName?: string
  locale?: string
  [key: string]: string | boolean | undefined | string[] | Function
  tenants: string[] = []
  allTenants: string[] = []
  roles: string[] = []
  safioRoles: string[] = []
  legacyRoles: string[] = []

  constructor (auth0User: { [key: string]: string | string[] | boolean | undefined }) {
    if (!auth0User) return
    for (const key in auth0User) {
      // Add legacy roles
      if (key.indexOf('http://basic-code') === 0) {
        if (key.endsWith('namespaces')) {
          this.allTenants = auth0User[key] as string[]
        } else if (key.endsWith('roles')) {
          this.legacyRoles = auth0User[key] as string[]
        }
      } else {
        this[key] = auth0User[key]
        if (key === 'http://safio/roles') {
          this.safioRoles = auth0User[key] as string[]
        }
      }
    }

    this.sub = auth0User.sub as string
    this.provider = this.sub.split('|')[0]
    this.id = this.sub.split('|')[1]
    this.roles = this.safioRoles || []

    this.filterTenants()
  }

  /**
   * Filters the list of namespaces from auth0 to only show the entries for the current environment.
   */
  private filterTenants () {
    // the same Docker image (with the exact same env vars) is used on
    // production and staging, so we have to determine where we're being
    // hosted
    const frontendHost = window.location.host

    if (process.env.NODE_ENV === 'development') {
      this.tenants = this.allTenants!.filter(n => n.toLowerCase().endsWith('dev') || n.toLowerCase().includes('unit-test')).concat('emg')
    } else if (frontendHost === 'qa.safio.app') {
      this.tenants = this.allTenants!.filter(n => n.toLowerCase().endsWith('qa'))
    } else {
      // production
      this.tenants = this.allTenants!.filter(n => {
        const name = n.toLowerCase()
        const isDev = name.endsWith('dev')
        const isQa = name.endsWith('qa')
        const isStage = name.endsWith('stage')
        const isDemo = name.endsWith('demo')
        return !isDev && !isStage && !isDemo && !isQa
      })
    }
  }

  public hasRole(role: string): boolean {
    return !!this.roles?.includes(role)
  }

  public isAdmin(): boolean {
    return this.hasRole('superadmin')
  }

  public isTenantSuperAdmin(): boolean {
    return this.hasRole('admin')
  }

  public isTenantAdmin(): boolean {
    return this.hasRole('manager')
  }

  public isTenantSalesRep(): boolean {
    return this.hasRole('salesperson')
  }

  public isPlanner(): boolean {
    return this.hasRole('planner')
  }
}
