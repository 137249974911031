import {ItemTotalType} from "@/types";
import Common from "@/util/common";
import CommonForecastHelper from "@/util/forecastCalculations/common-forecast-helper";
import {ForecastMonthData, PlannerForecastYear, PlannerForecastDto} from "@basic-code/shared";

export default class PlannerFcCalculations {
    public static getAdjustmentTotal(pfYear: PlannerForecastYear, totalType: ItemTotalType): number {
        let total = 0
        for (const fcMonth of pfYear.forecastMonths) {
            total += Common.getNumber(fcMonth.adjustmentAmount)
            if (CommonForecastHelper.hasReachedYtdTotal(totalType, fcMonth.month)) break
        }
        return total
    }

    public static getFlowTotal(pfYear: PlannerForecastYear, totalType: ItemTotalType): number {
        let total = 0
        for (const fcMonth of pfYear?.forecastMonths) {
            total += Common.getNumber(fcMonth.flowAmount)
            if (CommonForecastHelper.hasReachedYtdTotal(totalType, fcMonth.month)) break
        }
        return total
    }

    public static getShipmentsTotal(pfYear: PlannerForecastYear, totalType: ItemTotalType): number {
        let total = 0;
        for (const fcMonth of pfYear.forecastMonths) {
            total += Common.getNumber(fcMonth.shipmentAmount)
            if (CommonForecastHelper.hasReachedYtdTotal(totalType, fcMonth.month)) break
        }
        return total
    }

    public static getAccountAdjustmentTotal(pfYear: PlannerForecastYear, totalType: ItemTotalType): number {
        let total = 0;
        for (const fcMonth of pfYear.accountForecast.monthTotals) {
            total += Common.getNumber(fcMonth.adjustmentTotal)
            if (CommonForecastHelper.hasReachedYtdTotal(totalType, fcMonth.month)) break
        }
        return total
    }

    public static getBomUsageTotal(pfYear: PlannerForecastYear, totalType: ItemTotalType): number {
        let total = 0;
        for (const fcMonth of pfYear.forecastMonths) {
            total += this.getBomMonthUsage(fcMonth)
            if (CommonForecastHelper.hasReachedYtdTotal(totalType, fcMonth.month)) break;
        }

        return total
    }

    public static getBomMonthUsage(pfMonth: PlannerForecastDto | ForecastMonthData): number {
        if (CommonForecastHelper.isMonthInThePast(pfMonth.calendarYear, pfMonth.month)) {
            return Common.getNumber(pfMonth.shipmentAmount);
        } else {
            return Common.getNumber(pfMonth.usageAmount);
        }
    }
}