import { BaseHttpService } from '@/services/base-http-service'
import { DataGroupDto } from '@basic-code/shared'

class DataGroupService extends BaseHttpService {
    public async getDataGroup(id: number): Promise<DataGroupDto> {
        const uri = `/data-groups/${id}`
        const response = await this.doApiGet<DataGroupDto>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getDataGroups(): Promise<DataGroupDto[]> {
        const uri = '/data-groups'
        const response = await this.doApiGet<DataGroupDto[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async createDataGroup(dataGroup: Partial<DataGroupDto>): Promise<DataGroupDto> {
        const uri = '/data-groups'
        const response = await this.doApiPost<DataGroupDto>(uri, JSON.stringify({ dataGroup }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateDataGroup(id: number, dataGroup: Partial<DataGroupDto>): Promise<DataGroupDto> {
        const uri = `/data-groups/${id}`
        const response = await this.doApiPut<DataGroupDto>(uri, JSON.stringify({ dataGroup }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async deleteDataGroup(id: number): Promise<boolean> {
        const uri = `/data-groups/${id}`
        const response = await this.doApiDelete<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async runDataGroup(id: number): Promise<boolean> {
        const uri = `/data-groups/${id}/run`
        const response = await this.doApiPost<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new DataGroupService()
