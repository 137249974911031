










import { EventBus, SafioEvents } from '@/services/event-bus'
import DtoFactory from '@/util/dto-factory'
import { AccountForecastEdit, PaginationResult, ProductAttributeOptionDto, VendorLocationDto, VendorProductDto,
  DataGroupDataImportRunLogDto } from '@basic-code/shared'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { SafioMessages } from '@/services/dialog-events'
import { SkuAccounts, VendorFilter } from '@/types'

@Component
export default class PaginationNav extends Vue {

  @Prop({ required: true, default: () => { return DtoFactory.placeholderPaginationResult() } })
  public paginationResult: PaginationResult<AccountForecastEdit[]|SkuAccounts[]|ProductAttributeOptionDto[]|
      VendorLocationDto[]|VendorProductDto[]|VendorFilter[]|DataGroupDataImportRunLogDto[]>

  @Prop({ required: true })
  public changeEventName: string

  @Prop({ default: false, type: Boolean })
  public loading: boolean

  @Prop({ type: String, default: 'sku' })
  public sectionType: string

  isDirty = false

  created() {
    EventBus.$on(SafioEvents.forecastChangeDirty, this.updateDirty)
  }

  beforeDestroy() {
    EventBus.$off(SafioEvents.forecastChangeDirty, this.updateDirty)
  }

  get isFirstPage (): boolean {
    if (!this.paginationResult) {
      return true
    }
    return this.paginationResult.currentPage === 1
  }

  get isLastPage (): boolean {
    if (!this.paginationResult || this.paginationResult.totalPages === 0) { return true }
    return this.paginationResult.currentPage === this.paginationResult.totalPages
  }

  get statusDisplay() {
    return `${this.paginationResult.totalPages ? this.paginationResult.currentPage : 0} of ${this.paginationResult.totalPages}`
  }

  private updateDirty(isDirty: boolean) {
    this.isDirty = isDirty
  }

  private async canChangePage(): Promise<boolean> {
    if (this.isDirty) {
      const response = await this.$bvModal.msgBoxConfirm(SafioMessages.discardChanges)
      if (!response) {
        return false
      } else {
        this.isDirty = false
      }
    }
    return true
  }

  async goFirst() {
    if (await this.canChangePage()) {
      EventBus.$emit(this.changeEventName, 1, this.paginationResult)
    }
  }

  async goPrev() {
    if (this.paginationResult.currentPage > 1 && await this.canChangePage()) {
      EventBus.$emit(this.changeEventName, this.paginationResult.currentPage - 1, this.paginationResult)
    }
  }

  async goNext() {
    if (this.paginationResult.currentPage < this.paginationResult.totalPages && await this.canChangePage()) {
      EventBus.$emit(this.changeEventName, this.paginationResult.currentPage + 1, this.paginationResult)
    }
  }

  async goLast() {
    if (this.paginationResult.currentPage !== this.paginationResult.totalPages && await this.canChangePage()) {
      EventBus.$emit(this.changeEventName, this.paginationResult.totalPages, this.paginationResult)
    }
  }
}
