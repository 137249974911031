import { BaseHttpService } from '@/services/base-http-service'
import { TopAccountDto } from '@basic-code/shared'

class TopAccountService extends BaseHttpService {
    public async getTopAccounts(): Promise<TopAccountDto[]> {
        const uri = '/top-accounts'
        const response = await this.doApiGet<TopAccountDto[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new TopAccountService()
