









import { Vue, Component } from 'vue-property-decorator'
import { User } from '../auth/user'
import ApiService from '../services/api-service'
import { EventBus, SafioEvents } from '../services/event-bus'

@Component
export default class TenantSelector extends Vue {

  selectedTenant: string|null = null

  user: User|null = null

  created() {
    EventBus.$on(SafioEvents.userLoaded, this.onUserLoaded)
  }

  beforeDestroy() {
    EventBus.$off(SafioEvents.userLoaded, this.onUserLoaded)
  }

  switchTenant(event: any) {
    this.selectedTenant = event.target.value
    ApiService.tenant = this.selectedTenant
  }

  onUserLoaded(user: User) {
    this.user = user
    const tenant = ApiService.tenant
    // this if check covers the case for local dev where an env has changed and the saved tenant is no longer in the list.
    if (tenant && user.tenants.includes(tenant)) {
      this.selectedTenant = tenant
    } else {
      // There is no previously selected tenant, or it is no longer in their filtered tenants.  Burn the ships.
      // This will prevent an attempt to load the previously selected or missing tenant.
      this.selectedTenant = ''
      ApiService.tenant = ''
      EventBus.$emit(SafioEvents.tenantChanged, '', tenant)
    }
  }


}
