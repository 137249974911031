import { BaseHttpService } from '@/services/base-http-service'

class AWSService extends BaseHttpService {
    public async importFile(importName: string, fileType: string, importMultipleFiles = false): Promise<any> {
        const data = {
            importName: importName,
            fileType: fileType,
            importMultipleFiles: importMultipleFiles,
        }
        const uri = '/aws/import-file'
        const response = await this.doApiPost(uri, JSON.stringify(data))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async uploadFiles(formData: FormData): Promise<string[]> {
        const uri = '/aws/upload'
        const response = await this.doFileUpload<string[]>('POST', uri, formData)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new AWSService()
