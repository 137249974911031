
















































































import { ProductDto, VendorProductDto, ProductAttributeValueDto, ProductFilter, ProductFilterField, ProductFilterOperation, ProductFieldType, SystemSettingDto } from '@basic-code/shared'
import VendorOverride from './VendorOverride.vue'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { EventBus, SafioEvents } from '@/services/event-bus'
import DtoFactory from '@/util/dto-factory'
import { Getter, State } from 'vuex-class'
import { WorksheetState } from '@/store/types'
import VendorProductService from '@/services/v3/vendor-product-service'
import { SafioMessages } from '@/services/dialog-events'
import { FilterChangeEventData } from '@/types'
import _ from 'lodash'

interface FilterableAttributes {
  Vendor: boolean;
  SkuStatus: boolean;
  "Product ID": boolean;
  Curve: boolean;
  "Purchase Lead Time": boolean;
  [index: string]: boolean;
}

const namespace = 'worksheetStore'

@Component<SkuPropertiesDisplay>({
  components: {
    VendorOverride
  }
})
export default class SkuPropertiesDisplay extends Vue {

  @Getter('settings') settings: SystemSettingDto
  @State(namespace) worksheetState: WorksheetState
  @Getter('totalProposedQty', { namespace }) totalProposedQty: number

  @Prop({ required: true, default: () => { return ProductDto.create() } })
  product: ProductDto

  primaryVendorProduct: VendorProductDto
  primaryVendorName = ''

  filtered: FilterableAttributes = {
    Vendor: false,
    SkuStatus: false,
    "Product ID": false,
    Curve: false,
    "Purchase Lead Time": false,
  }

  localFilterResults: ProductDto[] = []
  selectedVendorSku = ''
  originalVendorSku = ''
  vendorSkuIsDirty = false

  get hasPromotedVendor(): boolean {
    if (this.product.vendorProducts?.length) {
      const overrideVendor = this.product.vendorProducts.find(vp => vp.poOverride)
      if (overrideVendor) {
        return true
      }
    }

    return false
  }

  get promotedVendorContractedPrice(): number {
    let contractedPrice

    if (this.product.vendorProducts?.length) {
      contractedPrice = this.product.vendorProducts.find(vp => vp.poOverride)?.contractedPrice || null
      if (!contractedPrice) {
        contractedPrice = this.primaryVendorProduct.contractedPrice
      }
    } else {
      contractedPrice = this.primaryVendorProduct.contractedPrice
    }

    return contractedPrice
  }

  get promotedVendorExtendedCost(): number {
    return this.promotedVendorContractedPrice * this.totalProposedQty
  }

  get promotedVendorName(): string {
    if (this.product.vendorProducts?.length) {
      let name = this.product.vendorProducts.find(vp => vp.poOverride)?.vendor?.name || ''
      if (!name) {
        name = this.product.vendorProducts[0].vendor?.name || ''
      }
      return name
    }
    return ''
  }

  get productGroupName(): string {
    return this.product.productGroup?.name || ''
  }

  get curveName(): string {
    return this.product.activeCurve?.name || this.product.curve?.name || ''
  }

  get isDefaultCurve(): boolean {
    return this.product.curve?.isDefault || false
  }

  get attributeValues(): ProductAttributeValueDto[] {
    return this.product.attributeValues?.filter(av => av.productAttribute!.sortOrder > 0) || []
  }

  get saveDisabled(): boolean {
    return !this.vendorSkuIsDirty || this.selectedVendorSku === ''
  }

  created() {
    EventBus.$on(SafioEvents.filterChanged, this.onFilterChanged)
    EventBus.$on(SafioEvents.filterResultsUpdated, this.onFilterResultsChanged)
  }

  beforeDestroy() {
    EventBus.$off(SafioEvents.filterChanged, this.onFilterChanged)
    EventBus.$off(SafioEvents.filterResultsUpdated, this.onFilterResultsChanged)
  }

  onFilterChanged(filterChangeData: FilterChangeEventData) {
    for (const key of Object.keys(this.filtered)) {
      this.filtered[key as keyof FilterableAttributes] = filterChangeData.filterProperties.includes(key)
    }
    Vue.set(this, 'filtered', _.clone(this.filtered))
  }

  onFilterResultsChanged(filterResults: ProductDto[]) {
    this.localFilterResults = filterResults
  }

  addAttributeFilter(attrVal: ProductAttributeValueDto) {
    const filterVal = this.product!.getAttributeValue(attrVal)
    const filter: ProductFilter = {
      field: ProductFilterField.productAttribute,
      fieldType: ProductFieldType.Text,
      operation: ProductFilterOperation.equal,
      fieldIdentifier: attrVal.productAttribute!.id,
      fieldName: attrVal.productAttribute!.name,
      value: filterVal,
      options: [],
    }
    EventBus.$emit(SafioEvents.filterAdd, filter)
  }

  addFilter(name: string) {
    const filter: Partial<ProductFilter> = {
      field: ProductFilterField.noSelection,
      operation: ProductFilterOperation.equal,
      options: [],
    }

    switch(name) {
      case 'vendor':
        filter.field = ProductFilterField.vendor
        filter.fieldType = ProductFieldType.Text
        filter.value = this.product!.getPrimaryVendorProduct()?.vendor?.name || ''
        filter.displayValue = this.product!.getPrimaryVendorProduct()?.vendor?.name || ''
        break
      case 'status':
        filter.field = ProductFilterField.productAttribute
        filter.fieldType = ProductFieldType.Text
        filter.fieldName = 'SkuStatus'
        filter.fieldIdentifier = this.product!.getAttributeValueDto('SkuStatus')!.productAttribute!.id
        filter.value = this.product!.getAttributeValue('SkuStatus')
        break
      case 'productId':
        filter.field = ProductFilterField.productGroupId
        filter.fieldType = ProductFieldType.ForeignKey
        filter.value = this.product!.productGroup?.id
        filter.displayValue = this.product!.productGroup?.name
        break
      case 'curve':
        filter.field = ProductFilterField.curve
        filter.fieldType = ProductFieldType.ForeignKey
        filter.value = this.product!.curve!.id
        filter.displayValue = this.product!.curve!.name
        break
      case 'purchaseLeadTime':
        filter.field = ProductFilterField.purchaseLeadTime
        filter.fieldType = ProductFieldType.Number
        filter.value = this.primaryVendorProduct!.purchaseLeadTime
        break
      case 'productGroupId':
        filter.field = ProductFilterField.productGroupId
        filter.fieldType = ProductFieldType.ForeignKey
        filter.value = this.product!.productGroup!.id
        filter.displayValue = this.product!.productGroup!.name
        break
      default:
        break
    }
    EventBus.$emit(SafioEvents.filterAdd, filter)
  }

  hasFilter(): boolean {
    for (const key of Object.keys(this.filtered)) {
      if (this.filtered[key]) {
        return true
      }
    }

    return false
  }

  @Watch('product', { immediate: true })
  onProductChange(product: ProductDto) {
    if (!product) {
      this.primaryVendorProduct = DtoFactory.blankVendorProductDto()
    } else {
      this.primaryVendorProduct = product.getPrimaryVendorProduct() || DtoFactory.blankVendorProductDto()
    }
    this.primaryVendorName = this.primaryVendorProduct.vendor?.name || ''
    this.selectedVendorSku = this.primaryVendorProduct.vendorSku
    this.originalVendorSku = this.primaryVendorProduct.vendorSku
    if (!this.hasFilter()) {
      this.filtered = {
        Vendor: false,
        SkuStatus: false,
        "Product ID": false,
        Curve: false,
        "Purchase Lead Time": false,
      }
      if (product && product.attributeValues) {
        for (const attr of product.attributeValues) {
          this.filtered[attr.productAttribute!.name] = false
        }
      }
    }
  }

  revertVendorSku() {
    this.selectedVendorSku = this.originalVendorSku
    this.vendorSkuIsDirty = false
  }

  async updateVendorSku() {
    try {
      await VendorProductService.updateVendorProduct(this.primaryVendorProduct.id, { vendorSku: this.selectedVendorSku }, this.product.id)
      this.primaryVendorProduct.vendorSku = this.selectedVendorSku
      this.originalVendorSku = this.selectedVendorSku
      this.vendorSkuIsDirty = false
    } catch (err) {
      await this.$bvModal.msgBoxOk(`${SafioMessages.errorMessage} Error: ${err}`)
    }
  }

  getAttributeValue(attrVal: ProductAttributeValueDto) {
    return this.product.getAttributeValue(attrVal)
  }
}
