import Vue from 'vue'

export const Dialogs = new Vue()

export interface SafioDialogEvents {
    discardChanges: string;
    saveChanges: string;
    clearData: string;
    clearPlannerAdjustments: string;
    errorMessage: string;
    deleteMessage: string;
    leaveEditMessage: string;
    invalidDate: string;
    forecastDownloadError: string;
    forecastUploadError: string;
    forecastClearData: string;
}

export const SafioMessages: SafioDialogEvents = {
    discardChanges: 'Are you sure you want to discard your unsaved changes?',
    saveChanges: 'Are you sure you want to save your changes?',
    clearData: 'Are you sure you want to set the values to 0? You will still need to save the form after this event.',
    clearPlannerAdjustments: 'Are you sure you would like to set all Planner Adjustments to 0? You will still need to save the form after this event.',
    errorMessage: 'There was an application error, please refresh the page.  If the problem persists, contact support.',
    deleteMessage: 'Are you sure you want to delete the selected data?',
    leaveEditMessage: 'You are about to leave edit mode, are you sure you want to discard your unsaved changes?',
    invalidDate: 'Date is not in valid format (YYYY-MM-DD)',
    forecastDownloadError: 'Error downloading template. If the problem persists, contact support.',
    forecastUploadError: 'Error uploading data. Please confirm uploaded data is in proper format. If the problem persists, contact support.',
    forecastClearData: 'Are you sure you want to set the adjustment and flow values to 0? You will still need to save the form after this event.',
}
