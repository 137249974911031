
























import { Component, Prop, Vue } from 'vue-property-decorator'
import AuthDisplay from './AuthDisplay.vue'
import { SafioVersion } from '@/util/version'

@Component({
  components: {
    AuthDisplay,
  }
})
export default class PageHeader extends Vue {
  safioVersion = SafioVersion

  @Prop({ type: String, default: '' })
  public rightBarClass: string
}
