import { VueAuth } from '@/auth/vue-auth'
import ForecastEditService from './forecast-edit-service'
import WorksheetService from './worksheet-service'
import SalesRepService from './v3/sales-rep-service'
import SystemSettingsService from './v3/system-settings-service'
import ProductAttributesService from './v3/product-attributes-service'
import ProductsService from './v3/product-service'
import BomsService from './v3/bom-service'
import AuthorizationService from './authorization-service'
import CeligoImportService from '@/services/v3/celigo-import-service'
import AWSService from '@/services/v3/aws-service'
import DataExportService from '@/services/v3/data-export-service'
import ProductAttributeOptionsService from '@/services/v3/product-attribute-options-service'
import VendorService from '@/services/v3/vendor-service'
import ProductGroupService from '@/services/v3/product-group-service'
import VendorProductService from '@/services/v3/vendor-product-service'
import ShopifyService from '@/services/v3/shopify-service'
import ProductAttributeValuesService from '@/services/v3/product-attribute-values-service'
import VendorLocationService from '@/services/v3/vendor-location-service'
import ShippingMethodService from '@/services/v3/shipping-method-service'
import PurchaseOrderService from '@/services/v3/purchase-order-service'
import ImportService from '@/services/v3/import-service'
import DataImportService from '@/services/v3/data-import-service'
import AdminViewService from '@/services/v3/admin-view-service'
import ViewService from '@/services/v3/view-service'
import PlannerService from '@/services/v3/planner-service'
import AccountService from '@/services/v3/account-service'
import TableauImportService from '@/services/v3/tableau-import-service'
import TopAccountService from '@/services/v3/top-account-service'
import DataGroupService from '@/services/v3/data-group-service'
import DataGroupDataImportService from '@/services/v3/data-group-data-import-service'
import DataGroupDataImportRunLogService from '@/services/v3/data-group-data-import-run-log-service'

export default class ServiceManager {

  /**
   * This function provides a single place to initialize the auth instance on each service.
   * It would be ideal if the base service could pull it on startup, but that has proven
   * to be difficult to manage from a timing perspective.
   * @param auth VueAuth instance
   */
  public static initServices(auth: VueAuth) {
    AuthorizationService.$auth = auth
    ForecastEditService.$auth = auth
    WorksheetService.$auth = auth
    SalesRepService.$auth = auth
    SystemSettingsService.$auth = auth
    ProductAttributesService.$auth = auth
    ProductsService.$auth = auth
    BomsService.$auth = auth
    CeligoImportService.$auth = auth
    AWSService.$auth = auth
    DataExportService.$auth = auth
    ProductAttributeOptionsService.$auth = auth
    VendorService.$auth = auth
    ProductGroupService.$auth = auth
    VendorProductService.$auth = auth
    ShopifyService.$auth = auth
    ProductAttributeValuesService.$auth = auth
    VendorLocationService.$auth = auth
    ShippingMethodService.$auth = auth
    PurchaseOrderService.$auth = auth
    ImportService.$auth = auth
    DataImportService.$auth = auth
    AdminViewService.$auth = auth
    ViewService.$auth = auth
    PlannerService.$auth = auth
    AccountService.$auth = auth
    TableauImportService.$auth = auth
    TopAccountService.$auth = auth
    DataGroupService.$auth = auth
    DataGroupDataImportService.$auth = auth
    DataGroupDataImportRunLogService.$auth = auth
  }
}
