import { BaseHttpService } from '@/services/base-http-service'

class TableauImportService extends BaseHttpService {
    public async updateDataImportsFromTableau() {
        const uri = '/tableau-import/data-sources'
        const response = await this.doApiPut(uri)
        console.log(response);
        if (response.success) {
            return response.response!
        }else{
            
            //throw new Error(response.error!.message)
        }
    }
}

export default new TableauImportService()
