import { Vue, Component } from 'vue-property-decorator'
import createAuth0Client, { PopupLoginOptions, Auth0Client, RedirectLoginOptions, GetIdTokenClaimsOptions, GetTokenSilentlyOptions, GetTokenWithPopupOptions, LogoutOptions } from '@auth0/auth0-spa-js'
import { User } from './user'
import ApiService from '../services/api-service'
import { EventBus, SafioEvents } from '@/services/event-bus'
import ServiceManager from '@/services/service-manager'
import AuthorizationService from '@/services/authorization-service'

export type Auth0Options = {
  domain: string;
  clientId: string;
  audience?: string;
  [key: string]: string | undefined;
}

// eslint-disable-next-line
export type RedirectCallback = (appState: any) => void


@Component({})
export class VueAuth extends Vue {
  loading = true
  isAuthenticated? = false
  user?: User
  auth0Client?: Auth0Client
  popupOpen = false
  error?: Error

  beforeDestroy () {
    EventBus.$off(SafioEvents.tenantChanged, this.onTenantChanged)
  }

  async getUser () {
    this.user = new User(await this.auth0Client?.getUser())
    ApiService.setCookie('username', encodeURIComponent(this.user.email), ApiService.expiresIn)
    if (this.user.roles) {
      ApiService.setCookie('roles', encodeURIComponent(this.user.legacyRoles.join(',')), ApiService.expiresIn)
    }
    return this.user
  }

  /** Authenticates the user using a popup window */
  async loginWithPopup (o: PopupLoginOptions) {
    this.popupOpen = true

    try {
      await this.auth0Client?.loginWithPopup(o)
    } catch (e) {
      console.error(e)
      this.error = e
    } finally {
      this.popupOpen = false
    }

    await this.getUser()
    await this.initApiService()
    this.isAuthenticated = true
  }

  /** Authenticates the user using the redirect method */
  loginWithRedirect (o: RedirectLoginOptions) {
    return this.auth0Client?.loginWithRedirect(o)
  }

  /** Returns all the claims present in the ID token */
  getIdTokenClaims (o: GetIdTokenClaimsOptions) {
    return this.auth0Client?.getIdTokenClaims(o)
  }

  /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
  getTokenSilently (o: GetTokenSilentlyOptions) {
    return this.auth0Client?.getTokenSilently(o)
  }

  /** Gets the access token using a popup window */
  getTokenWithPopup (o: GetTokenWithPopupOptions) {
    return this.auth0Client?.getTokenWithPopup(o)
  }

  /** Logs the user out and removes their session on the authorization server */
  logout (o: LogoutOptions) {
    return this.auth0Client?.logout(o)
  }

  async onTenantChanged (newTenant: string, oldTenant: string) {
    if (newTenant !== oldTenant) {
      await AuthorizationService.updateAbilities(this)
    }
  }

  /** Use this lifecycle method to instantiate the SDK client */
  async init (onRedirectCallback: RedirectCallback, redirectUri: string, auth0Options: Auth0Options) {
    // Create a new instance of the SDK client using members of the given options object
    this.auth0Client = await createAuth0Client({
      domain: auth0Options.domain,
      client_id: auth0Options.clientId, // eslint-disable-line @typescript-eslint/camelcase
      audience: auth0Options.audience,
      redirect_uri: redirectUri, // eslint-disable-line @typescript-eslint/camelcase
      cacheLocation: "localstorage",
    })

    try {
      // If the user is returning to the app after authentication..
      if (
        window.location.search.includes('error=') ||
        (window.location.search.includes('code=') && window.location.search.includes('state='))
      ) {
        // handle the redirect and retrieve tokens
        const { appState } = await this.auth0Client?.handleRedirectCallback() ?? { appState: undefined }
        // Notify subscribers that the redirect callback has happened, passing the appState
        // (useful for retrieving any pre-authentication state)
        onRedirectCallback(appState)
      }
    } catch (e) {
      console.error(e)
      this.error = e
    } finally {
      // Initialize our internal authentication state when the page is reloaded
      try {
        this.isAuthenticated = await this.auth0Client?.isAuthenticated()
        console.log('authenticated?', this.isAuthenticated)
        if (!this.isAuthenticated) {
          this.loginWithRedirect({ appState: { targetUrl: window.location.href } })
        }
        const accessToken = await this.getTokenSilently({})
        const claims = await this.auth0Client?.getIdTokenClaims()
        if (claims) {
          ApiService.expiresIn = new Date(claims.exp as number).toISOString()
          ApiService.accessToken = accessToken
        }
        await this.getUser()
        console.log('user updated to', this.user)
        await this.initApiService()
        EventBus.$emit(SafioEvents.userLoaded, this.user)
        EventBus.$on(SafioEvents.tenantChanged, this.onTenantChanged)
      } catch (err) {
        console.log('error setting up tokens:', err)
      }
      this.loading = false
    }
  }

  private async initApiService() {
    if (this.isAuthenticated) {
      if (this.user?.tenants.length === 1) {
        ApiService.tenant = this.user!.tenants[0]
      } else {
        const tenant = ApiService.tenant
        if (tenant) {
          ApiService.tenant = tenant // This ensures any missing cookies are set.
        } else if (this.user?.tenants.length) {
          ApiService.tenant = this.user!.tenants[0]
        }
      }

      if (ApiService.accessToken) {
        ServiceManager.initServices(this)
        // init authorization service now that api is set up.
        await AuthorizationService.updateAbilities(this)
      }
    }
  }

  public async sync() {
    await this.getUser()
    console.log('user synced to', this.user)
    await this.initApiService()
  }
}
