import { BaseHttpService } from '@/services/base-http-service'
import { Planner } from '@/types'

class PlannerService extends BaseHttpService {
    public async getPlanners(): Promise<Planner[]> {
        const uri = '/planners'
        const response = await this.doApiGet<Planner[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new PlannerService()
