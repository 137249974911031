import {AdminViewDto, FilterSettings, UIForecastFilterDto, ViewDto} from '@/types'
import {
  AccountClassDtoV3,
  AccountDto,
  AccountForecastEdit,
  BillOfMaterialDto,
  DataGroupDataImportRunLogDto,
  DataGroupDto,
  DataImportDto,
  ForecastEditFilterData,
  ForecastLockRule,
  ForecastSummaryType,
  ForecastTotalDisplayDto,
  ImportDto,
  InventoryLocationDto,
  PaginationResult,
  PlannerForecastEdit,
  PlannerProposedOrdersDtoV3,
  ProductDto,
  ProductGroupDto,
  PurchaseOrderDto,
  PurchaseOrderLineItemDto,
  PurchaseOrderPriceAdjustmentDto,
  SalesRepDtoV3,
  ShippingMethodDto,
  SystemSettingDto,
  VendorDto,
  VendorProductDto
} from '@basic-code/shared'
import {ChildBillOfMaterialInfoDto, Forecast, ParentBillOfMaterialInfoDto} from "@basic-code/shared";

export interface RootState {
  settings: SystemSettingDto;
}

export enum ForecastDownloadTemplateType {
  Bulk = 'bulk',
  Single = 'single'
}

export interface BomState {
  assemblyCost: number;
  bom: BillOfMaterialDto[];
  initialSku: string;
  initialProduct: ProductDto;
  originalLoadComplete: boolean;
}

export interface SetupState {
  flatFileImports: ImportDto[];
  tableauDataImports: DataImportDto[];
  shopifyDataImports: DataImportDto[];
  awsDataImports: DataImportDto[];
  celigoDataImports: DataImportDto[];
  internalProcessDataImports: DataImportDto[];
  dataGroups: DataGroupDto[];
  loading: boolean;
  vendors: VendorDto[];
  newProductGroups: ProductGroupDto[];
  newProducts: ProductDto[];
  shippingMethods: ShippingMethodDto[];
  tabsLoaded: {
    products: boolean;
    attributes: boolean;
    vendors: boolean;
    data: boolean;
    settings: boolean;
    [index: string]: boolean;
  };
  originalSettings: SystemSettingDto;
  editingSettings: SystemSettingDto;
  loadingVendorDetails: boolean;
  adminViews: AdminViewDto[];
  customViews: ViewDto[];
  editingDataGroupId: number;
  dataGroupDirty: boolean;
  editingForecastLockRule: ForecastLockRule|null;
  originalForecastLockRule: ForecastLockRule|null;
  editingForecastLockRuleInvalid: boolean;
  targetedLockDirty: boolean;
  isNewTargetedLock: boolean;
}

export interface DataImportRunTimeErrorsState {
  paginatedDataGroupDataImportRunLogs: PaginationResult<DataGroupDataImportRunLogDto[]>;
  dataGroupId: number;
  dataImportId: number;
  page: number;
  pageSize: number;
}

export interface ForecastEditState {
  activeTabIndex: number;
  filterApplied: boolean;
  originalLoadComplete: boolean;
  filter: UIForecastFilterDto;
  mode: ForecastSummaryType;
  filterData: ForecastEditFilterData;
  salesRepAccountClasses: AccountClassDtoV3[];
  salesRepAccounts: AccountDto[];
  plannerForecasts: PlannerForecastEdit;
  accountForecasts: PaginationResult<AccountForecastEdit[]>;
  accountForecastTotals: ForecastTotalDisplayDto;
  loadingAccountForecasts: boolean;
  loadingAddAccountForecast: boolean;
  loadingAccountForecastTotals: boolean;
  loadingAllProducts: boolean;
  loadingPlannerForecasts: boolean;
  loadingAccountClasses: boolean;
  loadingChildForecastTotals: boolean;
  loadingChildForecasts: boolean;
  loadingParentForecastTotals: boolean;
  loadingParentForecasts: boolean;
  savingAccountForecast: boolean;
  activeAccountForecastIndex: number;
  originalAccountForecastEdit: AccountForecastEdit|null;
  originalAccountForecastTotals: ForecastTotalDisplayDto|null;
  originalPlannerForecasts: PlannerForecastEdit|null;
  activeAccountForecastDirty: boolean;
  plannerForecastDirty: boolean;
  savingPlannerForecast: boolean;
  initialSku: string;
  initialProduct: ProductDto|null;
  replaceProjectedForecast: boolean;
  initialTenantSalesRep: SalesRepDtoV3|null;
  paginatedChildForecastBoms: PaginationResult<ChildBillOfMaterialInfoDto[]>;
  paginatedParentForecastBoms: PaginationResult<ParentBillOfMaterialInfoDto[]>;
  childForecastBoms: ChildBillOfMaterialInfoDto[];
  parentForecastBoms: ParentBillOfMaterialInfoDto[];
  parentForecastBomTotals: PlannerForecastEdit|null;
  forecast: Forecast|null;
}

export interface PurchaseOrderState {
  shippingMethods: ShippingMethodDto[];
  purchaseOrder: PurchaseOrderDto;
  originalPurchaseOrder: PurchaseOrderDto|null;
  poLineItems: PurchaseOrderLineItemDto[];
  poPriceAdjs: PurchaseOrderPriceAdjustmentDto[];
  vendors: VendorDto[];
  inventoryLocations: InventoryLocationDto[];
  allProducts: ProductDto[];
  vendorProducts: VendorProductDto[];
  loadingPurchaseOrder: boolean;
  loadingVendors: boolean;
  loadingInventoryLocations: boolean;
  loadingProducts: boolean;
  loadingVendorProducts: boolean;
  loadingPdf: boolean;
  loadingProposedOrders: boolean;
  savingInventoryLocation: boolean;
  savingVendorLocation: boolean;
  savingPurchaseOrder: boolean;
  purchaseOrderLoadError: string;
  requestedShipDateSet: boolean;
  poDirty: boolean;
  proposedOrders: PlannerProposedOrdersDtoV3[];
  forcedEstimatedReceiptDate?: Date;
  initialProduct?: ProductDto;
  defaultShippingMethodId: number;
}

export interface WorksheetState {
  placeholder: string;
  proposedOrders: PlannerProposedOrdersDtoV3[];
  originalProposedOrders: PlannerProposedOrdersDtoV3[];
  loadingProposedOrders: boolean;
  skuFilters: FilterSettings;
}
