import { BaseHttpService } from '@/services/base-http-service'
import { FileType } from '@basic-code/shared'

class DataExportService extends BaseHttpService {
    public async exportData(exportName: string, fileType: string) {
        const uri = `/data-export/${exportName}/?fileType=${fileType}`
        let response

        if (fileType === FileType.csv) {
            response = await this.download(uri, 'text/csv')
        } else {
            response = await this.downloadBinary(uri, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        }

        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async exportImportErrors(errorNumber: number) {
        const uri = `/data-export/errors/${errorNumber}`
        const response = await this.download(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new DataExportService()
