import { BaseHttpService } from '@/services/base-http-service'
import { VendorDto, DropdownOption } from '@basic-code/shared'
import { FormAddEditVendorDto } from '@/types'

class VendorService extends BaseHttpService {
    public async getVendors(includeVendorLocations = false, includeVendorProducts = false): Promise<VendorDto[]> {
        const uri = `/vendors?includeVendorLocations=${includeVendorLocations}&includeVendorProducts=${includeVendorProducts}`
        const response = await this.doApiGet<VendorDto[]>(uri)
        if (response.success) {
          return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getVendor(vendorId: number): Promise<VendorDto> {
      const uri = `/vendors/${vendorId}`
      const response = await this.doApiGet<VendorDto>(uri)
        if (response.success) {
          return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async createVendor(vendor: FormAddEditVendorDto): Promise<VendorDto> {
        const uri = '/vendors/'
        const response = await this.doApiPost<VendorDto>(uri, JSON.stringify({ vendor: vendor }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateVendor(id: number, vendor: Partial<VendorDto>): Promise<VendorDto> {
        const uri = `/vendors/${id}`
        const response = await this.doApiPut<VendorDto>(uri, JSON.stringify({ vendor: vendor }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getPrimaryVendorsForForecastLock (salesReps: DropdownOption[], accounts: DropdownOption[], productAttributeId: number|null, productAttributeOptionId: number|null): Promise<VendorDto[]> {
        const uri = '/vendors-for-forecast-lock'
        const response = await this.doApiPost<VendorDto[]>(uri, JSON.stringify({
            salesReps: salesReps.map(sr => sr.value), accounts: accounts.map(a => a.value), productAttributeId: productAttributeId, productAttributeOptionId: productAttributeOptionId
        }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new VendorService()
