import { InventoryLocationDto, PlannerProposedOrdersDtoV3, PurchaseOrderDto, PurchaseOrderHistoryDto } from '@basic-code/shared'
import { ApiResponse } from '../api-service'
import { BaseHttpService } from '../base-http-service'
import { PurchaseOrderLineItemReceiptDto } from '@basic-code/shared'

class PurchaseOrderService extends BaseHttpService {

  public async getPurchaseOrder(id: number): Promise<PurchaseOrderDto|null> {
    const uri = `/purchase-orders/${id}`
    const response = await this.doApiGet<PurchaseOrderDto|null>(uri)
    if (response.success) {
      if (response.response?.id || 0 > 0) {
        return PurchaseOrderDto.create(response.response!)
      }
      return response.response!
    }
    throw new Error(response.error!.message)
  }

  public async getPlannerProposedOrders(vendorId: number, poMonth: number) {
    const today = new Date()
    const month = poMonth + 1 // sending in sql format.
    const monthStr = month < 10 ? `0${month}` : month.toString()
    const forecastDate = `${today.getFullYear()}-${monthStr}-01`

    const uri = `/vendors/${vendorId}/proposed-orders?po_month=${forecastDate}`
    const response = await this.doApiGet<PlannerProposedOrdersDtoV3[]>(uri)
    if (response.success) {
      return response.response!.map(ppo => PlannerProposedOrdersDtoV3.create(ppo))
    }
    throw new Error(response.error!.message)
  }

  public async savePurchaseOrder(purchaseOrder: PurchaseOrderDto, comment = ''): Promise<PurchaseOrderDto> {
    let response: ApiResponse<PurchaseOrderDto>
    if (purchaseOrder.id > 0) {
      response = await this.doApiPut<PurchaseOrderDto>(`/purchase-orders/${purchaseOrder.id}`, JSON.stringify({ purchaseOrder, comment }))
    } else {
      response = await this.doApiPost<PurchaseOrderDto>('/purchase-orders', JSON.stringify({ purchaseOrder, comment }))
    }
    if (response.success) {
      return PurchaseOrderDto.create(response.response!)
    }
    throw new Error(response.error!.message)

  }

  public async createPurchaseOrderLineItemReceipts(purchaseOrderId: number, purchaseOrderLineItemReceipts: Partial<PurchaseOrderLineItemReceiptDto>[]): Promise<boolean> {
    const uri = `/purchase-orders/${purchaseOrderId}/create-line-item-receipts`
    const response = await this.doApiPost<boolean>(uri, JSON.stringify({ purchaseOrderLineItemReceipts }))
    if (response.success) {
      return response.response! as boolean
    }
    throw new Error(response.error!.message)
  }

  public async getInventoryLocations(): Promise<InventoryLocationDto[]> {
    const uri = '/inventory-locations'
    const response = await this.doApiGet<InventoryLocationDto[]>(uri)
    if (response.success) {
      return response.response!
    }
    throw new Error(response.error!.message)
  }

  public async saveInventoryLocation(inventoryLocation: InventoryLocationDto): Promise<InventoryLocationDto> {
    const uri = `/inventory-locations/${inventoryLocation.id || ''}`
    let response: ApiResponse<InventoryLocationDto>
    if (inventoryLocation.id > 0) {
      response = await this.doApiPut<InventoryLocationDto>(uri, JSON.stringify(inventoryLocation))
    } else {
      response = await this.doApiPost<InventoryLocationDto>(uri, JSON.stringify(inventoryLocation))
    }
    if (response.success) {
      return response.response!
    }
    throw new Error(response.error!.message)
  }

  public async addComment(purchaseOrder: PurchaseOrderDto, comment: string): Promise<PurchaseOrderHistoryDto> {
    const response = await this.doApiPost<PurchaseOrderHistoryDto>(`/purchase-orders/${purchaseOrder.id}/comment`, JSON.stringify({ comment }))
    if (response.success) {
      return response.response!
    }
    throw new Error(response.error!.message)
  }

  public async downloadPurchaseOrderPdf(id: number): Promise<boolean> {
    const uri = `/purchase-orders/${id}/download`
    const response = await this.downloadBinary(uri, 'application/pdf')
    if (response.success) {
      response.response!
      return response.response!
    }
    throw new Error(response.error!.message)
  }

  public async savePlannerProposedOrders(proposedOrders: PlannerProposedOrdersDtoV3[]): Promise<PlannerProposedOrdersDtoV3[]> {
    const partialPlannerProposedOrders: Partial<PlannerProposedOrdersDtoV3>[] = []

    for (const proposedOrder of proposedOrders) {
      partialPlannerProposedOrders.push({
        id: proposedOrder.id,
        calendarYear: proposedOrder.calendarYear,
        createdAt: proposedOrder.createdAt,
        updatedAt: proposedOrder.updatedAt,
        forecastDate: proposedOrder.forecastDate,
        month: proposedOrder.month,
        forecastOrders: proposedOrder.forecastOrders,
        productId: proposedOrder.productId
      })
    }

    const response = await this.doApiPost<PlannerProposedOrdersDtoV3[]>('/planner-proposed-orders', JSON.stringify({ proposedOrders: partialPlannerProposedOrders }))
    if (response.success) {
      return response.response!
    }
    throw new Error(response.error!.message)
  }
}

export default new PurchaseOrderService()