import {
  AccountClassDto, AccountDto, AccountForecastEdit, AccountOriginalOrderData,
  BaseAccountOrderData, BaseSkuOrderData, CurveDto, CurveModelType, Forecast, ForecastEditorState, ForecastLastYear,
  ForecastTotalDisplayDto, ForecastYear, ForecastYearData,ForecastSummaryType, MonthlyValueDto, PaginationResult,
  PlannerOriginalOrderData, ProductAttributes, ProductAttributeDto, ProductDto, Safio, TrendScenario, VendorSkusDto,
  WorksheetMonthSummaryDto, BaseAggregateOrderData, FlowType, AccountRankingType, ImportType, SystemSettingDto,
  VendorProductDto, SalesRepDtoV3, AccountClassDtoV3
} from '@basic-code/shared'
import { Guid } from 'guid-typescript'
import { subject as authSubject } from '@casl/ability'
import { NewForecast } from '@/types'

export interface SkuFlowOptions {
  name: string;
  enabled: boolean;
}

export default class DtoFactory {
  public static blankVendorSku(): VendorSkusDto {
    return {
      contractedPrice: 0,
      description: '',
      extendedCost: 0,
      fileUpdate: new Date(0),
      minOrderQty: 0,
      orderMultiple: 1,
      packed: '',
      promoted: false,
      purchaseLeadTime: 0,
      sku: '',
      vendorId: '',
      vendorName: '',
      vendorSku: '',
      vendorStatus: '',
    }
  }

  public static blankSettings(): SystemSettingDto {
    return {
      id: 0,
      analyticsUrl: '',
      attributesChecksum: 0,
      curveAttr1: '',
      curveAttr2: '',
      curveAttr3: '',
      dataProcessor: '',
      evaluationMode: false,
      fiscalMonth: 0,
      numberOfYears: 0,
      shopifyAdminUrl: '',
      tableauViewsBaseUrl: '',
      trendWeeksA: 0,
      trendWeeksB: 0,
      replaceProjectedForecast: false,
      replaceProjectedForecastForNewForecasts: false,
      defaultFlowType: FlowType.Default,
      poEnabled: false,
      importType: ImportType.Manual,
      defaultRanking: AccountRankingType.ForecastTop10,
      allowAttributeUpdates: false,
      dataServices: {
        isAmazon: false,
        isShopify: false,
        isNetSuite: false,
      },
      poPrefix: '',
      poSuffix: '',
      nextPONumber: 1,
      ftpFolder: '',
      currency: 'USD',
      poLogoUrl: '',
      forecastLockRules: [],
      forecastLockOn: false,
      lockingType: '',
    }
  }

  public static blankProductDto(): ProductDto {
    return ProductDto.create()
  }

  public static blankProductAttributes(): ProductAttributes {
    return {
      attr10Name: '',
      attr10Value: '',
      attr11Name: '',
      attr11Value: '',
      attr12Name: '',
      attr12Value: '',
      attr1Name: '',
      attr1Value: '',
      attr2Name: '',
      attr2Value: '',
      attr3Name: '',
      attr3Value: '',
      attr4Name: '',
      attr4Value: '',
      attr5Name: '',
      attr5Value: '',
      attr6Name: '',
      attr6Value: '',
      attr7Name: '',
      attr7Value: '',
      attr8Name: '',
      attr8Value: '',
      attr9Name: '',
      attr9Value: '',
    }
  }

  public static blankProductAttributeDto(): ProductAttributeDto {
    return {
      createdAt: new Date(),
      id: 0,
      isExternal: false,
      name: '',
      sortOrder: 0,
      updatedAt: new Date(),
    }
  }

  public static blankForecastYearData(): ForecastYearData {
    return {
      fiscalYear: '',
      items: [],
      total: 0,
      forecast: 0,
      ytd: 0
    }
  }

  // public static blankTotalFilteredForecastYear(): TotalFilteredForecastYear {
  //   return {
  //     accountAdjustments: DtoFactory.blankForecastYearData(),
  //     projectedTotal: DtoFactory.blankForecastYearData(),
  //     flow: DtoFactory.blankForecastYearData(),
  //   }
  // }

  // public static blankTotalFilteredForecast(): TotalFilteredForecast {
  //   return {
  //     thisYear: DtoFactory.blankTotalFilteredForecastYear(),
  //     year1: DtoFactory.blankTotalFilteredForecastYear(),
  //     year2: DtoFactory.blankTotalFilteredForecastYear(),
  //     year3: DtoFactory.blankTotalFilteredForecastYear(),
  //   }
  // }

  public static blankForecastTotalDisplayDto(): ForecastTotalDisplayDto {
    return {
      forecast: [],
      shipments: [],
    }
  }

  public static addGuid(forecast: AccountForecastEdit, ogForecast: BaseAccountOrderData|BaseSkuOrderData) {
    forecast.guid = ogForecast.guid
  }

  public static baseSystemSettingsData(systemSettings: SystemSettingDto): SystemSettingDto {
    return {
      id: systemSettings.id,
      analyticsUrl: systemSettings.analyticsUrl,
      attributesChecksum: systemSettings.attributesChecksum,
      curveAttr1: systemSettings.curveAttr1,
      curveAttr2: systemSettings.curveAttr2,
      curveAttr3: systemSettings.curveAttr3,
      dataProcessor: systemSettings.dataProcessor,
      defaultFlowType: systemSettings.defaultFlowType,
      defaultRanking: systemSettings.defaultRanking,
      evaluationMode: systemSettings.evaluationMode,
      fiscalMonth: systemSettings.fiscalMonth,
      importType: systemSettings.importType,
      numberOfYears: systemSettings.numberOfYears,
      poEnabled: systemSettings.poEnabled,
      replaceProjectedForecast: systemSettings.replaceProjectedForecast,
      replaceProjectedForecastForNewForecasts: systemSettings.replaceProjectedForecastForNewForecasts,
      tableauViewsBaseUrl: systemSettings.tableauViewsBaseUrl,
      trendWeeksA: systemSettings.trendWeeksA,
      trendWeeksB: systemSettings.trendWeeksB,
      allowAttributeUpdates: systemSettings.allowAttributeUpdates,
      shopifyApiCredentials: systemSettings.shopifyApiCredentials ? systemSettings.shopifyApiCredentials : [],
      dataServices: systemSettings.dataServices,
      poPrefix: systemSettings.poPrefix,
      poSuffix: systemSettings.poSuffix,
      nextPONumber: systemSettings.nextPONumber,
      ftpFolder: systemSettings.ftpFolder,
      currency: systemSettings.currency,
      poLogoUrl: systemSettings.poLogoUrl,
      forecastLockRules: systemSettings.forecastLockRules,
      forecastLockOn: systemSettings.forecastLockOn,
      lockingType: systemSettings.lockingType,
    }
  }

  /**
   * Used for creating a new forecast or editing an existing one.
   * @param state
   * @param existing
   * @returns
   */
  public static blankAccountForecastEditData(state: ForecastEditorState, existing?: AccountForecastEdit): AccountForecastEdit {
    return authSubject('forecast', {
      id: existing?.id || undefined, // product_account_forecasts.id
      productId: existing?.productId || 0,
      productSku: existing?.productSku || '',
      productDesc: existing?.productSku || '',
      account: existing?.account || AccountDto.create(),
      accountClass: existing?.accountClass || AccountClassDtoV3.create(),
      editorState : state,
      guid: Guid.create(),
      name: existing?.name || '',
      skuStatus: existing?.skuStatus || '',
      skuSalesReps: existing?.skuSalesReps || [],
      accountSalesReps: existing?.accountSalesReps ||[],
      salesRep: existing?.salesRep || DtoFactory.blankSalesRepData(),
      curve: existing?.curve || DtoFactory.blankCurveDto(),
      salesHistory: existing?.salesHistory || DtoFactory.blankWorksheetMonthSummaryDto(),
      costPrice: existing?.costPrice || 0,
      sellPrice: existing?.sellPrice || 0,
      flowType: existing?.flowType || FlowType.Default,
      addedFromNoForecast: false,
      accountPlannerComments: existing?.accountPlannerComments || '',
      accountLastUpdatedBy: existing?.accountLastUpdatedBy || 'system',
      accountLastUpdatedAt: existing?.accountLastUpdatedAt || new Date(),
      forecast: [], // stays empty even when editing an existing so we only send over changed items.
    })
  }

  public static blankNewForecast(): NewForecast {
    return {
      accountId: 0,
      productId: 0,
      skuStatus: '',
      accountClassId: 0,
      salesRep: DtoFactory.blankSalesRepData()
    }
  }

  public static blankWorksheetMonthSummaryDto(): WorksheetMonthSummaryDto {
    return {
      sku: '',
    }
  }

  public static blankCurveDto(): CurveDto {
    const dto: any = {
      createdAt: new Date(0),
      curveModelType: CurveModelType.TimeSeries,
      id: 0,
      isDefault: false,
      monthApr: 0,
      monthAug: 0,
      monthDec: 0,
      monthFeb: 0,
      monthJan: 0,
      monthJul: 0,
      monthJun: 0,
      monthMar: 0,
      monthMay: 0,
      monthNov: 0,
      monthOct: 0,
      monthSep: 0,
      monthlyTotal: 0,
      name: '',
      updatedAt: new Date(0),
    }
    for (let i = 1; i <= 52; i++) {
      dto[`week${i}`] = 0
    }
    return dto as CurveDto
  }

  public static blankMonthlyValueDtoData(): MonthlyValueDto {
    return {
      jan: 0,
      feb: 0,
      mar: 0,
      apr: 0,
      may: 0,
      jun: 0,
      jul: 0,
      aug: 0,
      sep: 0,
      oct: 0,
      nov: 0,
      dec: 0,
      total: 0,
      forecast: 0,
    }
  }

  public static blankAccountData(): Safio.Account {
    return {
      account: '',
      accountClass: '',
    }
  }

  public static blankAccountClassData(): AccountClassDto {
    return {
      accountNames: [],
      accountClasses: [],
      accounts: [],
    }
  }

  public static blankSalesRepData(): SalesRepDtoV3 {
    return {
      id: 0,
      name: '',
      email: '',
      accountProducts: [],
      createdAt: new Date(),
      updatedAt: new Date(),
      externalUserId: '',
      fileUpdate: null,
    }
  }

  public static blankBaseAggregateOrderData(type: ForecastSummaryType): BaseAggregateOrderData {
    const data: BaseAggregateOrderData = {
      originalEditorData: this.blankAccountOriginalOrderData()
    }
    if (type === ForecastSummaryType.Account) {
      data.account = ''
    } else if (type === ForecastSummaryType.Sku) {
      data.sku = ''
    }

    return data
  }

  public static blankBaseAccountOrderData(): BaseAccountOrderData {
    return {
      guid: Guid.create(),
      sku: '',
      originalEditorData: this.blankAccountOriginalOrderData()
    }
  }

  public static blankBaseSkuOrderData(): BaseSkuOrderData {
    return {
      guid: Guid.create(),
      sku: '',
      originalEditorData: this.blankAccountOriginalOrderData(),
      accountSkus: this.blankAccountSkus(),
    }
  }

  public static blankAccountSkus() {
    return [{
      account: '',
      sku: '',
    }]
  }

  public static blankAccountOriginalOrderData(): AccountOriginalOrderData {
    return {
      accountAdjustments: [], // not to be used when directly creating an AccountOriginalOrderData object (only used in PlannerOriginalOrderData object)
      adjustments: [],
      totals: [],
      flows: [],
      loaded: false,
      accountName: '',
      className: '',
      sku: '',
      flowType: FlowType.Default,
    }
  }

  public static blankPlannerOriginalOrderData(): PlannerOriginalOrderData {
    return {
      adjustments: [],
      accountAdjustments: [],
      totals: [],
      loaded: false,
      accountName: '',
      sku: '',
    }
  }

  public static blankForecastYear(): ForecastYear {
    return {
      fiscalYear: '',
      shipments: DtoFactory.blankForecastYearData(),
      beginningOnHand: DtoFactory.blankForecastYearData(),
      beginningOnHandWithUsage: DtoFactory.blankForecastYearData(),
      projectedForecast: DtoFactory.blankForecastYearData(),
      projectedForecastWithUsage: DtoFactory.blankForecastYearData(),
      originalForecast: DtoFactory.blankForecastYearData(),
      origFcVsLyActShip: DtoFactory.blankForecastYearData(),
      actualShipVsOrigFc: DtoFactory.blankForecastYearData(),
      tyActShipVsLyActShip: DtoFactory.blankForecastYearData(),
      tyActShipVsTyProjFc: DtoFactory.blankForecastYearData(),
      tyProjFcVsLyActShip: DtoFactory.blankForecastYearData(),
      endingOnHand: DtoFactory.blankForecastYearData(),
      endingOnHandWithUsage: DtoFactory.blankForecastYearData(),
      inventoryOnHand: DtoFactory.blankForecastYearData(),
      purchaseOrders: DtoFactory.blankForecastYearData(),
      salesOrders: DtoFactory.blankForecastYearData(),
      bomUsage: DtoFactory.blankForecastYearData(),
    }
  }

  public static blankForecastLastYear(): ForecastLastYear {
    return {
      fiscalYear: '',
      shipments: DtoFactory.blankForecastYearData(),
      beginningOnHand: DtoFactory.blankForecastYearData(),
      beginningOnHandWithUsage: DtoFactory.blankForecastYearData(),
      projectedForecast: DtoFactory.blankForecastYearData(),
      projectedForecastWithUsage: DtoFactory.blankForecastYearData(),
      originalForecast: DtoFactory.blankForecastYearData(),
      origFcVsLyActShip: DtoFactory.blankForecastYearData(),
      actualShipVsOrigFc: DtoFactory.blankForecastYearData(),
      tyActShipVsLyActShip: DtoFactory.blankForecastYearData(),
      tyActShipVsTyProjFc: DtoFactory.blankForecastYearData(),
      tyProjFcVsLyActShip: DtoFactory.blankForecastYearData(),
      bomUsage: DtoFactory.blankForecastYearData(),
    }
  }

  public static blankTrendScenario(): TrendScenario {
    return {
      selectedWeeks: 0,
      shipments: 0,
      remainingForecast: 0,
      estEoyOh: 0,
      estAnnualProjForecast: 0,
      projFcEoyOh: 0
    }
  }

  public static blankForecast(): Forecast {
    return {
      lastYear: DtoFactory.blankForecastLastYear(),
      thisYear: DtoFactory.blankForecastYear(),
      year1: DtoFactory.blankForecastYear(),
      year2: DtoFactory.blankForecastYear(),
      year3: DtoFactory.blankForecastYear(),

    }
  }

  public static placeholderPaginationResult<T> (existing?: PaginationResult<T>): PaginationResult<T> {
    return {
      currentPage: existing?.currentPage || 1,
      data: [] as unknown as T,
      perPage: existing?.perPage || 10,
      totalPages: existing?.totalPages || 0,
      totalRecords: existing?.totalRecords || 0,
    }
  }

  public static blankVendorProductDto(): VendorProductDto {
    return {
      contractedPrice: 0,
      createdAt: new Date(0),
      id: 0,
      orderIncrement: 0,
      packedIncrement: 0,
      poLineItemMaxOrderAmt: 0,
      poLineItemMaxOrderQty: 0,
      poLineItemMinOrderAmt: 0,
      poLineItemMinOrderQty: 0,
      priority: 0,
      productId: 0,
      purchaseLeadTime: 0,
      updatedAt: new Date(0),
      vendorId: 0,
      vendorSku: '',
      poOverride: false,
      fileUpdate: null,
    }
  }
}
