/* eslint-disable @typescript-eslint/ban-ts-ignore */
import { VueAuth } from '@/auth/vue-auth'
import { EventBus, SafioEvents } from '@/services/event-bus'
import { ForecastDownloadTemplateType } from '@/store/types'
import {
  FormAddEditVendorProductDto,
  MonthMap,
  ProductAttributeValueInformation,
  WorksheetWarningMessage
} from '@/types'
import {
  AccountForecastEdit,
  AccountForecastYear,
  AccountRankingType,
  DropdownOption,
  FlowType,
  Forecast,
  ForecastLockRule,
  ForecastLockRuleAccountType,
  ForecastLockRuleSalesRepType,
  ForecastLockRuleSkuType,
  ForecastLockRuleType,
  ForecastLockRuleVendorType,
  ForecastLockType,
  ForecastMonthData,
  ImportType,
  LockingType,
  PlannerForecastEdit,
  PlannerForecastYear,
  ProductAttributeDto,
  ProductAttributeValueDto,
  ProductDto,
  SalesRepDtoV3,
  SettingsDto,
  SystemSettingDto,
  VendorProductDto
} from '@basic-code/shared'
import { Guid } from "guid-typescript"
import _ from 'lodash'
import XLSX from 'xlsx'

export default class Common {
  public static monthAbbrs = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']
  public static monthAbbrsCap = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  public static monthsMap: MonthMap = { 'jan': 0, 'feb': 1, 'mar': 2, 'apr': 3, 'may': 4, 'jun': 5, 'jul': 6, 'aug': 7, 'sep': 8, 'oct': 9, 'nov': 10, 'dec': 11 }

  public static positiveNumber(value: string | number): number {
    if (!value || (typeof value === 'string' && Number(value) < 0) || Number(value) < 0) {
      return 0
    }

    return Number(value)
  }

  public static getNumber(value: string | number | undefined | Guid): number {
    if (!value || isNaN(Number(value))) {
      return 0
    }

    return Number(value)
  }

  public static formatTwoDigitYear(year: string | number): string {
    return year ? `'` + year.toString().substring(2) : year.toString()
  }

  public static assignFlowFromLYShipments(previousYear: AccountForecastYear, currentYear: AccountForecastYear) {
    currentYear.flowTotal = previousYear.shipmentsTotal
    let currFlow: number
    for (let monthIndex = 0; monthIndex < previousYear.forecastMonths.length; monthIndex++) {
      currFlow = currentYear.forecastMonths[monthIndex].flow
      currentYear.forecastMonths[monthIndex].flow = previousYear.forecastMonths[monthIndex].shipments
      EventBus.$emit(SafioEvents.accountFlowUpdated, currentYear.fiscalYear, currFlow - previousYear.forecastMonths[monthIndex].shipments)
    }
  }




  public static createSkuCurveAccountForecastSpreadsheetData(
    acctFcEdits: AccountForecastEdit[],
    settings: SystemSettingDto
  ) {
    const data: any[] = [];
    const headerData = ['sku', 'account', 'accountClass', 'sellPrice', 'forecastType'];


    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const fiscalStartMonth = settings.fiscalMonth;
    let acctFcEditNum = 0;
    for (const acctFcEdit of acctFcEdits) {
      const skuData: any[] = []

      skuData.push(acctFcEdit.productSku)
      skuData.push(acctFcEdit.account.name)
      skuData.push(acctFcEdit.accountClass.name)
      skuData.push(acctFcEdit.sellPrice)
      skuData.push('SKU Curve Flow')

      for (const afy of acctFcEdit.forecast) {
        let monthNum = 0
        let flowTotalAdded = false;



        for (const afm of afy.forecastMonths) {
          const isSameMonthAsStartMonth = fiscalStartMonth === currentMonth;
          if (isSameMonthAsStartMonth) {
            // Check if the month is within the current fiscal year range.
            const isWithinFiscalYearRange = afy.fiscalYear === currentYear &&
              ((afm.month >= fiscalStartMonth && afm.month <= 12) || // Months after start of the fiscal year until end of the year
                (fiscalStartMonth > currentMonth && afm.month < fiscalStartMonth)); // Months before the fiscal year when the fiscal year starts later in the calendar year
            const isSameMonth = afy.fiscalYear === currentYear && afm.month === currentMonth && fiscalStartMonth === currentYear; // For the current month, check if the month is the same as the fiscal start month.
            // For subsequent years, check if the year is within the next four years.
            const isInSubsequentYears = afy.fiscalYear > currentYear && afy.fiscalYear <= currentYear + 4;


            if (isWithinFiscalYearRange && isSameMonth || isInSubsequentYears) {
              if (!flowTotalAdded) {
                skuData.push(afy.flowTotal); // Ensure flow total is added only once per edit.
                flowTotalAdded = true;
              }
              if (acctFcEditNum === 0) {

                if (monthNum++ === 0) {
                  headerData.push(`TTL ${Common.formatTwoDigitYear(afm.calendarYear)}`)
                }
                headerData.push(`${afm.monthName.toUpperCase()} ${Common.formatTwoDigitYear(afm.calendarYear)}`)
              }
              skuData.push(afm.adjustments)
            }
          } else {
            // Check if the month is within the current fiscal year range.
            const isWithinFiscalYearRange = afy.fiscalYear === currentYear &&
              ((afm.month >= fiscalStartMonth && afm.month <= 12) || // Months after start of the fiscal year until end of the year
                (fiscalStartMonth > currentMonth && afm.month < fiscalStartMonth)); // Months before the fiscal year when the fiscal year starts later in the calendar year

            // For subsequent years, check if the year is within the next four years.
            const isInSubsequentYears = afy.fiscalYear > currentYear && afy.fiscalYear <= currentYear + 4;

            // Include the month if it is within the current fiscal year range or in subsequent years.
            if (isWithinFiscalYearRange || isInSubsequentYears) {
              if (!flowTotalAdded) {
                skuData.push(afy.flowTotal); // Ensure flow total is added only once per edit.
                flowTotalAdded = true;
              }
              if (acctFcEditNum === 0) {

                if (monthNum++ === 0) {
                  headerData.push(`TTL ${Common.formatTwoDigitYear(afm.calendarYear)}`)
                }
                headerData.push(`${afm.monthName.toUpperCase()} ${Common.formatTwoDigitYear(afm.calendarYear)}`)
              }
              skuData.push(afm.adjustments)


            }
          }
        }
      }


      if (acctFcEditNum++ === 0) {
        data.push(headerData)
      }

      data.push(skuData)
    }

    return data
  }



  public static createAccountForecastSpreadsheetData(
    acctFcEdits: AccountForecastEdit[],
    forecastDownloadTemplateType: ForecastDownloadTemplateType,
    settings: SystemSettingDto
    // Include system settings to know the fiscalMonth
  ) {

    const data: any[] = [];

    let headerData: string[] = [];


    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const fiscalStartMonth = settings.fiscalMonth

    if (forecastDownloadTemplateType === ForecastDownloadTemplateType.Bulk) {
      headerData = ["sku", "account", "accountClass", "sellPrice", "forecastType"];
    } else {
      headerData = [];
    }

    for (const acctFcEdit of acctFcEdits) {
      const flowData: any[] = [];
      const adjData: any[] = [];

      if (forecastDownloadTemplateType === ForecastDownloadTemplateType.Bulk) {
        flowData.push(acctFcEdit.productSku);
        flowData.push(acctFcEdit.account.name);
        flowData.push(acctFcEdit.accountClass.name);
        flowData.push(acctFcEdit.sellPrice);

        adjData.push(acctFcEdit.productSku);
        adjData.push(acctFcEdit.account.name);
        adjData.push(acctFcEdit.accountClass.name);
        adjData.push(acctFcEdit.sellPrice);
      }

      flowData.push(forecastDownloadTemplateType === ForecastDownloadTemplateType.Bulk ? "Manual Flow" : "Flow");
      adjData.push("Adjustments");

      for (const afy of acctFcEdit.forecast) {



        for (const afm of afy.forecastMonths) {
          const isSameMonthAsStartMonth = fiscalStartMonth === currentMonth;
          if (isSameMonthAsStartMonth) {
            // Check if the month is within the current fiscal year range.
            const isWithinFiscalYearRange = afy.fiscalYear === currentYear &&
              ((afm.month >= fiscalStartMonth && afm.month <= 12) || // Months after start of the fiscal year until end of the year
                (fiscalStartMonth > currentMonth && afm.month < fiscalStartMonth)); // Months before the fiscal year when the fiscal year starts later in the calendar year
            const isSameMonth = afy.fiscalYear === currentYear && afm.month === currentMonth && fiscalStartMonth === currentYear; // For the current month, check if the month is the same as the fiscal start month.
            // For subsequent years, check if the year is within the next four years.
            const isInSubsequentYears = afy.fiscalYear > currentYear && afy.fiscalYear <= currentYear + 4;

            // Include the month if it is within the current fiscal year range or in subsequent years.
            if (isWithinFiscalYearRange && isSameMonth || isInSubsequentYears) {
              const columnHeader = `${afm.monthName.toUpperCase()} ${Common.formatTwoDigitYear(afm.calendarYear)}`;


              if (data.length === 0) {
                headerData.push(columnHeader);
              }


              flowData.push(afm.flow);
              adjData.push(afm.adjustments);
            }
          } else {
            // Check if the month is within the current fiscal year range.
            const isWithinFiscalYearRange = afy.fiscalYear === currentYear &&
              ((afm.month >= fiscalStartMonth && afm.month <= 12) || // Months after start of the fiscal year until end of the year
                (fiscalStartMonth > currentMonth && afm.month < fiscalStartMonth)); // Months before the fiscal year when the fiscal year starts later in the calendar year

            const isInSubsequentYears = afy.fiscalYear > currentYear && afy.fiscalYear <= currentYear + 4;


            if (isWithinFiscalYearRange || isInSubsequentYears) {
              const columnHeader = `${afm.monthName.toUpperCase()} ${Common.formatTwoDigitYear(afm.calendarYear)}`;


              if (data.length === 0) {
                headerData.push(columnHeader);
              }


              flowData.push(afm.flow);
              adjData.push(afm.adjustments);
            }
          }

        }
      }

      if (forecastDownloadTemplateType === ForecastDownloadTemplateType.Single) {
        headerData.push("Last Updated By");
        flowData.push(acctFcEdit.accountLastUpdatedBy || "N/A");
        adjData.push(acctFcEdit.accountLastUpdatedBy || "N/A");
      }


      if (data.length === 0) {
        data.push(headerData);
      }


      data.push(flowData);
      data.push(adjData);
    }

    return data;
  }




  public static createPlannerForecastSpreadsheetData(plannerFcEdits: PlannerForecastEdit[], forecastDownloadTemplateType: ForecastDownloadTemplateType) {
    const data: any[] = []

    let headerData: string[]

    if (forecastDownloadTemplateType === ForecastDownloadTemplateType.Bulk) {
      headerData = ['sku', 'forecastType']
    } else {
      headerData = []
    }

    let plnFcEditNum = 1

    for (const plannerFcEdit of plannerFcEdits) {
      const adjData: any[] = []

      if (forecastDownloadTemplateType === ForecastDownloadTemplateType.Bulk) {
        adjData.push(plannerFcEdit.product.sku)
        adjData.push('Projected Forecast')
      }

      for (const pfYear of plannerFcEdit.forecast) {
        let monthNum = 1

        if (forecastDownloadTemplateType === ForecastDownloadTemplateType.Bulk) {
          adjData.push(pfYear.flowTotal)
        }

        for (const pfMonth of pfYear.forecastMonths) {
          if (plnFcEditNum === 1) {
            if (forecastDownloadTemplateType === ForecastDownloadTemplateType.Bulk && monthNum++ === 1) {
              headerData.push(`TTL ${Common.formatTwoDigitYear(pfMonth.calendarYear)}`)
            }
            headerData.push(`${pfMonth.monthAbbr.toUpperCase()} ${Common.formatTwoDigitYear(pfMonth.calendarYear)}`)
          }
          adjData.push(pfMonth.adjustmentAmount)
        }
      }

      if (forecastDownloadTemplateType === ForecastDownloadTemplateType.Single) {
        headerData.push('Last Updated By')
        if (plannerFcEdit.forecast.length && plannerFcEdit.forecast[0].forecastMonths.length) {
          adjData.push(plannerFcEdit.forecast[0].forecastMonths[0].updatedBy)
        } else {
          adjData.push('System')
        }
      }

      if (plnFcEditNum++ === 1) {
        data.push(headerData)
      }

      data.push(adjData)
    }

    return data
  }

  public static updatePlannerForecastFromSpreadsheetData(planFcEdit: PlannerForecastEdit, settings: SystemSettingDto,
    headers: string[], values: number[], vueAuth: VueAuth, initialTenantSalesRep: SalesRepDtoV3 | null) {
    let header: string[]
    let month: number
    let year: number
    let fiscalYear: number

    for (const pfYear of planFcEdit.forecast) {
      pfYear.adjustmentTotal = 0
    }

    for (let index = 0; index < headers.length; index++) {
      header = headers[index].split(' \'')
      month = Common.monthAbbrs.indexOf(header[0].toLowerCase())
      year = Number(header[1])

      if (month < 0 || isNaN(year)) {
        continue // Non month column.  skip it.
      }
      year += 2000
      fiscalYear = Common.getFiscalYear(year, month, settings.fiscalMonth)

      const pfYear = planFcEdit.forecast.find(pfy => pfy.fiscalYear === fiscalYear)
      if (pfYear) {
        const pfMonth = pfYear.forecastMonths.find(pfMonth => pfMonth.calendarYear === year && pfMonth.month === month)
        if (pfMonth && (!this.areAnyForecastMonthLockRulesMet(month, year, settings, vueAuth, initialTenantSalesRep, planFcEdit.product, planFcEdit.product.accountForecasts!) || this.areAllForecastOverrideRulesMet(settings, vueAuth, initialTenantSalesRep, planFcEdit.product, planFcEdit.product.accountForecasts!))) {
          pfMonth.adjustmentAmount = values[index]
        }
      }
    }
  }

  public static fillForecastYearData(yearData: any, uploadData: any, type: string, includeFlow = true) {
    if (type === 'accountAdjustments' && includeFlow) {
      uploadData.splice(0, 1)
      let i = 0
      for (; i < yearData[0].length; i++) {
        yearData[0][i].value = uploadData[i]
      }
      i += 2
      for (let j = 0; j < yearData[1].length; j++) {
        if (i < uploadData.length) {
          yearData[1][j].value = uploadData[i]
          i++
        } else {
          return
        }
      }
    } else {
      if (type === 'accountAdjustments' && !includeFlow) { uploadData.splice(0, 1) }
      for (let i = 0; i < yearData.length; i++) {
        if (i < uploadData.length) {
          yearData[i].value = uploadData[i]
        } else {
          return
        }
      }
    }
  }

  public static createForecastTemplateWorkbook(wbData: any, title: string) {
    const wb = XLSX.utils.book_new()
    wb.Props = {
      Title: title
    }
    wb.SheetNames.push('Template')
    const ws = XLSX.utils.aoa_to_sheet(wbData)
    wb.Sheets['Template'] = ws

    return wb
  }

  public static getForecastUploadHeaderRow(sheet: any) {
    const headers = [], range = XLSX.utils.decode_range(sheet['!ref'])
    const r = range.s.r /* start in the first row */
    for (let c = range.s.c; c <= range.e.c; ++c) { /* walk every column in the range */
      const cell = sheet[XLSX.utils.encode_cell({ c: c, r: r })] /* find the cell in the first row */
      let hdr = "UNKNOWN " + c // <-- replace with your desired default
      if (cell && cell.t) { hdr = XLSX.utils.format_cell(cell) }
      headers.push(hdr)
    }

    return headers
  }

  public static fixUploadData(data: any) {
    let o = "", l = 0
    const w = 10240
    for (; l < data.byteLength / w; ++l) {
      o += String.fromCharCode.apply(null, Array.from(new Uint8Array(data.slice(l * w, l * w + w))))
    }
    o += String.fromCharCode.apply(null, Array.from(new Uint8Array(data.slice(l * w))))

    return o
  }

  public static getWorksheetLayout(settings: SettingsDto, sku: string, $can: Function, warningMessageArray: WorksheetWarningMessage[] = [], worksheetHasFilters = false) {
    const isForecastOverrideAndAddBomTotals = !!warningMessageArray.find(m => m === WorksheetWarningMessage.projectedForecastAndAddBomTotals)
    const isForecastOverride = !!warningMessageArray.find(m => m === WorksheetWarningMessage.projectedForecast)
    const isAddBomTotals = !!warningMessageArray.find(m => m === WorksheetWarningMessage.addBomTotals)

    return [
      {
        collection: isAddBomTotals ? 'beginningOnHandWithUsage' : 'beginningOnHand',
        id: 'beginning-on-hand-row',
        title: 'Act/Est BOH',
        hideTotal: true,
        hideYtd: true,
        warningMessage: warningMessageArray.find(m => m === WorksheetWarningMessage.beginningOnHand),
      },
      {
        collection: 'inventoryOnHand',
        id: 'inventory-on-hand-row',
        title: 'Inventory OH',
        warningMessage: warningMessageArray.find(m => m === WorksheetWarningMessage.inventoryOnHand),
        url: `${settings.tableauViewsBaseUrl}InventorybyLocation/InventorybySKU?SKU=${sku}`,
        hideZeros: true,
        hideYtd: true,
      }, {
        collection: 'purchaseOrders',
        id: 'purchase-orders-row',
        title: 'Purchase Orders',
        warningMessage: warningMessageArray.find(m => m === WorksheetWarningMessage.purchaseOrders),
        hideYtd: true,
        url: `${settings.tableauViewsBaseUrl}PurchaseOrders/PurchaseOrders?SKU=${encodeURIComponent(sku)}`,
      }, {
        collection: 'salesOrders',
        id: 'sales-orders-row',
        title: 'Sales Orders',
        warningMessage: warningMessageArray.find(m => m === WorksheetWarningMessage.salesOrders),
        url: `${settings.tableauViewsBaseUrl}SalesOrdersThisYear/SalesOrdersThisYear?SKU=${encodeURIComponent(sku)}`,
        hideYtd: true,
      }, {
        collection: 'shipments',
        id: 'shipments-row',
        title: 'TY Shipments',
        hideYtd: true,
        url: `${settings.tableauViewsBaseUrl}TYShipments/TYShipments?SKU=${encodeURIComponent(sku)}`,
        warningMessage: warningMessageArray.find(m => m === WorksheetWarningMessage.shipments),
      }, {
        collection: 'spacer',
        id: 'spacer1'
      }, {
        collection: isForecastOverride ? 'accountTotalsWithUsage' : 'projectedForecastWithUsage',
        id: 'projected-forecasts-row',
        title: isForecastOverride || isForecastOverrideAndAddBomTotals ? 'Account FC' : 'Projected FC',
        url: $can('read', 'forecast') ? `/forecast.html?sku=${encodeURIComponent(sku)}&worksheetHasFilters=${worksheetHasFilters}` : undefined,
        target: 'projfc',
        hideYtd: true,
        warningMessage: isForecastOverrideAndAddBomTotals ?
          warningMessageArray.find(m => m === WorksheetWarningMessage.projectedForecastAndAddBomTotals) :
          isAddBomTotals ?
            warningMessageArray.find(m => m === WorksheetWarningMessage.addBomTotals) :
            warningMessageArray.find(m => m === WorksheetWarningMessage.projectedForecast)
      }, {
        collection: isForecastOverride ? 'accountTotals' : 'projectedForecast',
        id: 'projected-forecast-only-row',
        title: 'Forecast',
        hideYtd: true,
        warningMessage: warningMessageArray.find(m => m === WorksheetWarningMessage.projectedForecastOnly),
      }, {
        collection: 'bomUsage',
        id: 'bom-usage-row',
        title: 'BOM Usage Forecast',
        hideYtd: true,
        warningMessage: warningMessageArray.find(m => m === WorksheetWarningMessage.bomUsage),
      }, {
        collection: isAddBomTotals ? 'endingOnHandWithUsage' : 'endingOnHand',
        id: 'estimated-ending-on-hand-row',
        title: 'Estimated EOH',
        hideZeros: true,
        hideYtd: true,
        hideTotal: true,
        hidePast: true,
        flagNegatives: true,
        warningMessage: warningMessageArray.find(m => m === WorksheetWarningMessage.endingOnHand)
      }, {
        collection: 'spacer',
        id: 'spacer2'
      }
    ]
  }

  /**
   * Gets a value from the query string
   * @param key the key to look for
   * @param defaultValue the value to return if the key is not provided in the query string
   */
  public static getQueryStringValue(key: string, defaultValue: string): string {
    const params = new URLSearchParams(window.location.search)
    if (params.has(key)) {
      return params.get(key) as string
    }
    return defaultValue
  }

  public static addQueryStringValue(key: string, value: string) {
    const params = new URLSearchParams(window.location.search)
    params.append(key, value)
    const url = new URL(window.location.href)
    url.search = params.toString()
    window.history.replaceState({ path: url.href }, '', url.href)
  }

  /**
   * Updates the query string with the given key and value without reloading the page.
   * @param key
   * @param value
   */
  public static updateQueryStringValue(key: string, value: string) {
    const params = new URLSearchParams(window.location.search)
    params.set(key, value)
    const url = new URL(window.location.href)
    url.search = params.toString()
    window.history.replaceState({ path: url.href }, '', url.href)
  }

  /**
   * Remove the key from the query string entirely.
   * @param key
   */
  public static removeQueryStringValue(key: string) {
    const params = new URLSearchParams(window.location.search)
    params.delete(key)
    const url = new URL(window.location.href)
    url.search = params.toString()
    window.history.replaceState({ path: url.href }, '', url.href)
  }

  public static getNegativeIndexableArray(target: any[]) {
    const handler = {
      get: function (target: any[], prop: string) {
        let numProp = 0
        if (!isNaN(Number(prop))) {
          numProp = parseInt(prop, 10);
          if (numProp < 0) {
            numProp += target.length;
          }
          return target[numProp]
        }
        return target[prop as any]
      }
    }
    return new Proxy(target, handler)
  }

  public static getForecastEditorYearMapping(numberOfYears: number) {
    // Get an array of all possible property names, then slice off only what the tenant needs.
    const arr: string[] = [
      'thisYear',
      'year1',
      'year2',
      'year3'
    ].slice(0, numberOfYears)

    const yearMapping = {
      yearIterator: ([] as string[]).concat(arr),
      yearNames: [] as any[]
    }
    // we only want to iterate tables via yearIterator.
    arr.push('lastYear') // push on lastYear because some
    yearMapping.yearNames = Common.getNegativeIndexableArray(arr)
    return yearMapping
  }



  public static getFiscalMonthDropdownOptions() {
    return [
      { text: 'Jan', value: 0 },
      { text: 'Feb', value: 1 },
      { text: 'Mar', value: 2 },
      { text: 'Apr', value: 3 },
      { text: 'May', value: 4 },
      { text: 'Jun', value: 5 },
      { text: 'Jul', value: 6 },
      { text: 'Aug', value: 7 },
      { text: 'Sep', value: 8 },
      { text: 'Oct', value: 9 },
      { text: 'Nov', value: 10 },
      { text: 'Dec', value: 11 },
    ]
  }

  public static getYearNumberDropdownOptions() {
    return [
      { text: '1', value: 1 },
      { text: '2', value: 2 },
      { text: '3', value: 3 },
      { text: '4', value: 4 },
    ]
  }

  public static getTrendWeeksDropdownOptions() {
    return [{ text: '1', value: 1 },
    { text: '2', value: 2 },
    { text: '3', value: 3 },
    { text: '4', value: 4 },
    { text: '5', value: 5 },
    { text: '6', value: 6 },
    { text: '7', value: 7 },
    { text: '8', value: 8 },
    { text: '9', value: 9 },
    { text: '10', value: 10 },
    { text: '11', value: 11 },
    { text: '12', value: 12 },
    { text: '13', value: 13 },
    ]
  }

  public static getDropdownOptionsFromEnum(type: string, includeBlankString = false, forecastLockType: string | null = null) {
    let typeValues: any
    const dropdownValues: DropdownOption[] = []

    switch (type) {
      case 'ImportTypeOptions':
        typeValues = Object.values(ImportType)
        break
      case 'FlowType':
        typeValues = Object.values(FlowType)
        break
      case 'ForecastLockType':
        typeValues = Object.values(ForecastLockType)
        break
      case 'AccountRankingType':
        typeValues = Object.values(AccountRankingType)
        break
      case 'LockingType':
        typeValues = Object.values(LockingType)
        break
      case 'ForecastLockRuleSalesRepType':
        typeValues = Object.values(ForecastLockRuleSalesRepType)
        break
      case 'ForecastLockRuleAccountType':
        typeValues = Object.values(ForecastLockRuleAccountType)
        break
      case 'ForecastLockRuleVendorType':
        typeValues = Object.values(ForecastLockRuleVendorType)
        break
      case 'ForecastLockRuleSkuType':
        typeValues = Object.values(ForecastLockRuleSkuType)
        break
      case 'ForecastLockRuleType':
        typeValues = Object.values(ForecastLockRuleType)
        break
      default:
        typeValues = []
        break
    }

    if (includeBlankString) {
      dropdownValues.push({ text: '', value: '' })
    }

    typeValues.forEach((value: any) => {
      if (type === 'ForecastLockType' && forecastLockType) {
        switch (forecastLockType) {
          case ForecastLockType.All:
            if (value !== ForecastLockType.All) {
              dropdownValues.push({ text: _.startCase(value), value: value })
            }
            break
          case ForecastLockType.PastAndCurrentMonths:
            if (value !== ForecastLockType.PastMonthsOnly && value !== ForecastLockType.PastAndCurrentMonths) {
              dropdownValues.push({ text: _.startCase(value), value: value })
            }
            break
          case ForecastLockType.PastMonthsOnly:
            if (value !== ForecastLockType.PastMonthsOnly) {
              dropdownValues.push({ text: _.startCase(value), value: value })
            }
            break
          case ForecastLockType.CurrentAndFutureMonths:
            if (value !== ForecastLockType.CurrentAndFutureMonths) {
              dropdownValues.push({ text: _.startCase(value), value: value })
            }
            break
          default:
            break
        }
      } else if (type !== 'FlowType' || (type === 'FlowType' && value !== FlowType.Default)) {
        dropdownValues.push({ text: _.startCase(value), value: value })
      }
    })

    return dropdownValues
  }

  public static getDropdownOptionsFromObjects(objectValues: any[], textFields: string[], valueField: string, concatString = ' - ') {
    const dropdownOptions: DropdownOption[] = []

    for (const objectValue of objectValues) {
      let text = objectValue[textFields[0]]
      for (let i = 1; i < textFields.length; i++) {
        text = text.concat(concatString, objectValue[textFields[i]])
      }
      dropdownOptions.push({ text: text, value: objectValue[valueField] })
    }

    return dropdownOptions
  }

  public static getLargestAttributeSortOrder(productAttributes: ProductAttributeDto[]) {
    const tempProductAttributes: ProductAttributeDto[] = JSON.parse(JSON.stringify(productAttributes))
    tempProductAttributes.sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1)
    return tempProductAttributes[tempProductAttributes.length - 1].sortOrder
  }

  public static validateAttributeSortOrder(sortOrder: number, productAttributes: ProductAttributeDto[]) {
    if (productAttributes.length > 0) {
      const largestSortOrder = this.getLargestAttributeSortOrder(productAttributes)

      if (sortOrder > largestSortOrder + 1) {
        return largestSortOrder + 1
      } else {
        return Math.ceil(sortOrder)
      }
    }

    return Math.ceil(sortOrder)
  }

  public static getProductAttributeValuesAttributeOptionPairs(productAttributeValues: ProductAttributeValueDto[]): ProductAttributeValueInformation[] {
    const attributeValuesAttributeOptionPairs: ProductAttributeValueInformation[] = []
    productAttributeValues = productAttributeValues.sort((a, b) => a.productAttribute!.sortOrder > b.productAttribute!.sortOrder ? 1 : -1)

    for (const attributeValue of productAttributeValues) {
      attributeValuesAttributeOptionPairs.push({
        attributeValueId: attributeValue.id,
        attributeId: attributeValue.productAttribute!.id,
        attributeName: attributeValue.productAttribute!.name,
        attributeOption: attributeValue.productAttributeOption ? attributeValue.productAttributeOption!.optionValue : '',
        isExternal: attributeValue.productAttribute!.isExternal,
      })
    }

    return attributeValuesAttributeOptionPairs
  }

  public static isValidVendorProduct(vendorProduct: VendorProductDto | FormAddEditVendorProductDto, adding = false): { isValid: boolean; invalidMessage: string } {
    let isValid = true
    let invalidMessage = ''

    if (!adding && (!vendorProduct.priority || vendorProduct.priority < 1)) {
      isValid = false
      invalidMessage += `${invalidMessage === '' ? '' : '; '}priority must be greater than or equal to 1`
    }

    if (!vendorProduct.vendorSku || vendorProduct.vendorSku === '') {
      isValid = false
      invalidMessage += `${invalidMessage === '' ? '' : '; '}vendor sku cannot be empty`
    }

    if (!vendorProduct.contractedPrice || vendorProduct.contractedPrice <= 0) {
      isValid = false
      invalidMessage += `${invalidMessage === '' ? '' : '; '}contracted price must be greater than 0`
    }

    if (vendorProduct.purchaseLeadTime === null) {
      isValid = false
      invalidMessage += `${invalidMessage === '' ? '' : '; '}purchase lead time cannot be empty`
    }

    if (vendorProduct.poLineItemMinOrderQty !== null && Number(vendorProduct.poLineItemMinOrderQty) < 0) {
      isValid = false
      invalidMessage += `${invalidMessage === '' ? '' : '; '}min order qty must be greater than or equal to 0`
    }

    if (vendorProduct.poLineItemMaxOrderQty !== null && Number(vendorProduct.poLineItemMaxOrderQty) <= 0) {
      isValid = false
      invalidMessage += `${invalidMessage === '' ? '' : '; '}max order qty must be greater than 0`
    }

    if (vendorProduct.poLineItemMinOrderAmt !== null && vendorProduct.poLineItemMinOrderAmt! < 0) {
      isValid = false
      invalidMessage += `${invalidMessage === '' ? '' : '; '}min order amt must be greater than or equal to 0`
    }

    if (vendorProduct.poLineItemMaxOrderAmt !== null && vendorProduct.poLineItemMaxOrderAmt! <= 0) {
      isValid = false
      invalidMessage += `${invalidMessage === '' ? '' : '; '}max order amt must be greater than 0`
    }

    return { isValid, invalidMessage }
  }

  public static getCurrentFiscalYear(fiscalStartMonth: number): number {
    const now = new Date()
    const year = now.getFullYear()
    if (fiscalStartMonth === 0) {
      return year
    }
    return (now.getMonth() >= fiscalStartMonth) ? year + 1 : year
  }

  public static getFiscalYear(calendarYear: number, monthIndex: number, fiscalMonth: number): number {
    if (fiscalMonth === 0) {
      return calendarYear
    }
    return monthIndex >= fiscalMonth ? calendarYear + 1 : calendarYear
  }

  public static getMonthAbbr(index: number, capitalize = false) {
    if (index < 0 || index > 11) {
      return ''
    }
    return capitalize ? Common.monthAbbrsCap[index] : Common.monthAbbrs[index]
  }

  public static getLockRules(settings: SystemSettingDto, enabledOnly = true) {
    return settings.forecastLockRules?.length ?
      settings.forecastLockRules.filter(flr => flr.ruleType === ForecastLockRuleType.Lock && (enabledOnly ? flr.enabled : (flr.enabled || !flr.enabled))) :
      []
  }

  public static getOverrideRules(settings: SystemSettingDto, enabledOnly = true) {
    return settings.forecastLockRules?.length ?
      settings.forecastLockRules.filter(flr => flr.ruleType === ForecastLockRuleType.Override && (enabledOnly ? flr.enabled : (flr.enabled || !flr.enabled))) :
      []
  }

  public static getForecastLockOverrideCurrentSalesRepIncluded(lockRule: ForecastLockRule, initialTenantSalesRep: SalesRepDtoV3): boolean {
    const salesRep = lockRule.salesReps?.find(sr => sr.value === initialTenantSalesRep.id)
    return !!salesRep
  }

  public static getForecastLockOverrideAccountIncluded(lockRule: ForecastLockRule, accountForecasts: AccountForecastEdit[], accountIds: number[] = []): boolean {
    accountIds = accountIds.length ? accountIds : accountForecasts.length ? accountForecasts.map(af => af.account.id) : []

    for (const accountId of accountIds) {
      const account = lockRule.accounts?.find(a => a.value === accountId)
      if (account) {
        return true
      }
    }
    return false
  }

  public static getForecastLockOverrideVendorIncluded(lockRule: ForecastLockRule, product: ProductDto | null): boolean {
    if (product) {
      const productPrimaryVendor = product.getPrimaryVendorProduct()
      if (productPrimaryVendor) {
        const vendor = lockRule.vendors?.find(v => v.value === productPrimaryVendor.vendorId)
        return !!vendor
      }
    }
    return false
  }

  public static getForecastLockOverrideProductAttributeOptionIncluded(lockRule: ForecastLockRule, product: ProductDto | null): boolean {
    if (product) {
      const productAttributeValue = product.attributeValues
        ? product.attributeValues.find(av => {
          const lockMatchesProductAttribute = av.productAttributeId === (lockRule.productAttribute ? (lockRule.productAttribute as DropdownOption).value : '');
          const lockMatchesProductAttributeOption = av.productAttributeOptionId === (lockRule.productAttributeOption ? (lockRule.productAttributeOption as DropdownOption).value : '')
          return lockMatchesProductAttribute && lockMatchesProductAttributeOption;
        })
        : null
      return !!productAttributeValue
    }
    return false
  }

  /**
   * This method checks a specific ForecastLockRule to make sure that the conditions are met for the configured
   * sales rep, account type, vendor type, and SKU are met.
   */
  public static isForecastRuleMet(rule: ForecastLockRule, initialTenantSalesRep: SalesRepDtoV3 | null, product: ProductDto | null, accountForecasts: AccountForecastEdit[], accountIds: number[] = []): boolean {
    // don't apply the lock if the lock somehow has no properties set
    if (rule.salesRepType === ForecastLockRuleSalesRepType.NoSalesReps
      && rule.accountType === ForecastLockRuleAccountType.NoAccounts
      && rule.skuType === ForecastLockRuleSkuType.NoSkuTypes) {
      return false;
    }

    return this.isCurrentSalesRepRuleMet(rule, initialTenantSalesRep)
      && this.isCurrentAccountTypeRuleMet(rule, accountForecasts, accountIds)
      && this.isCurrentSkuRuleMet(rule, product);
  }

  /**
   * This method returns true if the LockRuleType is All or Any.
   * This method returns true if the specific conditions for a specific Sales Rep type is met
   */
  public static isCurrentSalesRepRuleMet(rule: ForecastLockRule, initialTenantSalesRep: SalesRepDtoV3 | null): boolean {
    return !!(rule.salesRepType === ForecastLockRuleSalesRepType.NoSalesReps
      || (rule.salesRepType === ForecastLockRuleSalesRepType.AllSalesReps && initialTenantSalesRep)
      || (rule.salesRepType === ForecastLockRuleSalesRepType.SpecificSalesReps
        && initialTenantSalesRep
        && rule.salesReps?.length
        && this.getForecastLockOverrideCurrentSalesRepIncluded(rule, initialTenantSalesRep)));
  }

  /**
   * This method returns true if the LockRuleType is All or Any.
   * This method returns true if the specific conditions for a specific Account type is met
   */
  public static isCurrentAccountTypeRuleMet(rule: ForecastLockRule, accountForecasts: AccountForecastEdit[], accountIds: number[] = []): boolean {
    return rule.accountType === ForecastLockRuleAccountType.SpecificAccounts
      ? !!(rule.accounts?.length && this.getForecastLockOverrideAccountIncluded(rule, accountForecasts, accountIds))
      : true;
  }

  /**
   * This method returns true if the LockRuleType is All or Any.
   * This method returns true if the specific conditions for a specific SKU type is met
   */
  public static isCurrentSkuRuleMet(rule: ForecastLockRule, product: ProductDto | null): boolean {
    return rule.skuType === ForecastLockRuleSkuType.SpecificSkuTypes
      ? !!(rule.productAttribute
        && rule.productAttributeOption
        && this.getForecastLockOverrideProductAttributeOptionIncluded(rule, product))
      : true;
  }

  public static areAllForecastOverrideRulesMet(settings: SystemSettingDto, vueAuth: VueAuth, initialTenantSalesRep: SalesRepDtoV3 | null, product: ProductDto | null, accountForecasts: AccountForecastEdit[], accountIds: number[] = []): boolean {
    if (this.doesUserPermissionsIgnoreLocks(vueAuth)) {
      return true
    }

    const overrideRules = this.getOverrideRules(settings)

    for (const overrideRule of overrideRules) {
      if (this.isForecastRuleMet(overrideRule, initialTenantSalesRep, product, accountForecasts, accountIds)) {
        return true
      }
    }

    return false
  }

  private static isForecastedMonthDateLockMet(lockRule: ForecastLockRule, monthNum: number, yearNum: number): boolean {
    const today = new Date()
    const lockStartDate = new Date(lockRule.forecastLockStartDate)
    const beginningOfMonthNow = new Date(today.getFullYear(), today.getMonth(), 1)

    if (today < lockStartDate) {
      return false;
    }

    let dateRuleMet = false

    const forecastedMonthDate = new Date(yearNum, monthNum, typeof lockRule.forecastLockDayOfMonth === 'string' ?
      parseInt(lockRule.forecastLockDayOfMonth) :
      lockRule.forecastLockDayOfMonth)
    switch (lockRule.forecastLockType) {
      case ForecastLockType.All:
        dateRuleMet = true
        break
      case ForecastLockType.PastAndCurrentMonths: {
        if (forecastedMonthDate <= today) {
          dateRuleMet = true
        }
        break
      }
      case ForecastLockType.PastMonthsOnly: {
        if (forecastedMonthDate <= today && forecastedMonthDate.getMonth() !== today.getMonth()) {
          dateRuleMet = true
        }
        break
      }
      case ForecastLockType.CurrentAndFutureMonths: {
        let endDate: Date | null = null
        const numMonths = lockRule.numMonths
          ? (typeof lockRule.numMonths === 'string'
            ? parseInt(lockRule.numMonths) :
            lockRule.numMonths)
          : null
        if (numMonths) {
          endDate = new Date()
          endDate.setMonth(endDate.getMonth() + numMonths)
        }

        if (numMonths !== null && endDate && forecastedMonthDate >= beginningOfMonthNow && forecastedMonthDate <= endDate) {
          dateRuleMet = true
        }
        break
      }
    }
    return dateRuleMet;
  }

  /**
   * This method returns true if the month should be locked by at least one rule and false if it should be unlocked
   */
  public static areAnyForecastMonthLockRulesMet(monthNum: number, yearNum: number, settings: SystemSettingDto, vueAuth: VueAuth, initialTenantSalesRep: SalesRepDtoV3 | null, product: ProductDto | null, accountForecasts: AccountForecastEdit[]): boolean {
    if (this.doesUserPermissionsIgnoreLocks(vueAuth)) {
      return false
    }

    const lockRules = this.getLockRules(settings)

    for (const lockRule of lockRules) {
      if (lockRule.forecastLockStartDate && lockRule.forecastLockDayOfMonth && lockRule.forecastLockType) {
        const dateLockMet = this.isForecastedMonthDateLockMet(lockRule, monthNum, yearNum)
        const additionalLockRulesMet = this.isForecastRuleMet(lockRule, initialTenantSalesRep, product, accountForecasts);

        if (dateLockMet && (lockRule.isMainLock || additionalLockRulesMet)) {
          return true
        }
      }
    }

    return false
  }

  public static getAllForecastLockButtonRulesMet(settings: SystemSettingDto, vueAuth: VueAuth, initialTenantSalesRep: SalesRepDtoV3 | null, product: ProductDto | null, accountForecasts: AccountForecastEdit[], accountIds: number[] = []): boolean {
    if (this.doesUserPermissionsIgnoreLocks(vueAuth)) {
      return false
    }

    const lockRules = this.getLockRules(settings)

    for (const lockRule of lockRules) {
      if (lockRule.forecastLockStartDate && lockRule.forecastLockDayOfMonth && lockRule.forecastLockType) {
        const today = new Date()
        const lockStartDate = new Date(lockRule.forecastLockStartDate)

        if (today >= lockStartDate) {
          const dateRuleMet = lockRule.forecastLockType === 'all'

          if (dateRuleMet && (lockRule.isMainLock || this.isForecastRuleMet(lockRule, initialTenantSalesRep, product, accountForecasts, accountIds))) {
            return true
          }
        }
      }
    }

    return false
  }

  public static areAllForecastFlowTotalLockRulesMet(settings: SystemSettingDto, vueAuth: VueAuth, initialTenantSalesRep: SalesRepDtoV3 | null, product: ProductDto, accountForecasts: AccountForecastEdit[]): boolean {
    if (this.doesUserPermissionsIgnoreLocks(vueAuth)) {
      return false
    }

    const lockRulesMet = false
    const lockRules = this.getLockRules(settings)

    for (const lockRule of lockRules) {
      if (lockRule.forecastLockStartDate && lockRule.forecastLockDayOfMonth && lockRule.forecastLockType) {
        const today = new Date()
        const lockStartDate = new Date(lockRule.forecastLockStartDate)

        if (today >= lockStartDate) {
          let dateRuleMet = false

          switch (lockRule.forecastLockType) {
            case ForecastLockType.All:
              dateRuleMet = true
              break
          }

          if (dateRuleMet && (lockRule.isMainLock || this.isForecastRuleMet(lockRule, initialTenantSalesRep, product, accountForecasts))) {
            return true
          }
        }
      }
    }

    return lockRulesMet
  }

  public static doesUserPermissionsIgnoreLocks(vueAuth: VueAuth): boolean {
    return !!(vueAuth.user?.isAdmin() || vueAuth.user?.isTenantSuperAdmin() || vueAuth.user?.isTenantAdmin());
  }

  public static isEditableFlowMonth(monthNum: number, yearNum: number, selectedFlowType: FlowType, isEditing: boolean, settings: SystemSettingDto, vueAuth: VueAuth, initialTenantSalesRep: SalesRepDtoV3 | null, product: ProductDto, accountForecasts: AccountForecastEdit[]): boolean {
    const forecastMonthLockRulesMet = this.areAnyForecastMonthLockRulesMet(monthNum, yearNum, settings, vueAuth, initialTenantSalesRep, product, accountForecasts)
    const overrideMonthLockRulesMet = this.areAllForecastOverrideRulesMet(settings, vueAuth, initialTenantSalesRep, product, accountForecasts)

    return isEditing && (!forecastMonthLockRulesMet || overrideMonthLockRulesMet) && (selectedFlowType === FlowType.Manual || selectedFlowType === FlowType.LyShipments)
  }

  public static isEditableFlowTotal(settings: SystemSettingDto, forecastType: string, vueAuth: VueAuth, initialTenantSalesRep: SalesRepDtoV3 | null, product: ProductDto, accountForecasts: AccountForecastEdit[], selectedFlowType: FlowType | null = null, isEditing: boolean | null = null): boolean {
    if (this.areAllForecastFlowTotalLockRulesMet(settings, vueAuth, initialTenantSalesRep, product, accountForecasts) && !this.areAllForecastOverrideRulesMet(settings, vueAuth, initialTenantSalesRep, product, accountForecasts)) {
      return false
    }

    if (forecastType === 'planner') {
      return true
    } else if (isEditing && selectedFlowType) {
      return isEditing && selectedFlowType === FlowType.SkuCurveFlow
    }
    return false
  }

  public static isForecastMonthLocked(month: number, year: number, settings: SystemSettingDto, vueAuth: VueAuth, initialTenantSalesRep: SalesRepDtoV3 | null, product: ProductDto | null, accountForecasts: AccountForecastEdit[]): boolean {
    return Common.areAnyForecastMonthLockRulesMet(month, year, settings, vueAuth, initialTenantSalesRep, product, accountForecasts)
      && !Common.areAllForecastOverrideRulesMet(settings, vueAuth, initialTenantSalesRep, product, accountForecasts)
  }

  /**
   * This method takes in an array for forecasted years, loops through each month in each year to determine there is at least one lock across all the forecasts
   * Returns true if there is at least one lock applied. False if no locks are applied or user permissions ignore locks.
   */
  public static doesForecastYearHaveLockedMonths(forecastedYears: PlannerForecastYear[] | AccountForecastYear[], settings: SystemSettingDto, vueAuth: VueAuth, initialTenantSalesRep: SalesRepDtoV3 | null, product: ProductDto | null, accountForecasts: AccountForecastEdit[]): boolean {
    if (this.doesUserPermissionsIgnoreLocks(vueAuth)) {
      return false
    }

    for (const forecast of forecastedYears) {
      for (const forecastedMonth of forecast.forecastMonths) {
        if (this.isForecastMonthLocked(forecastedMonth.month, forecastedMonth.calendarYear, settings, vueAuth, initialTenantSalesRep, product, accountForecasts)) {
          return true
        }
      }
    }

    return false
  }


  public static getDayOfMonthSuffix(dayNum: number) {
    switch (dayNum) {
      case 1:
      case 21:
      case 31:
        return 'st'
      case 2:
      case 22:
        return 'nd'
      case 3:
      case 23:
        return 'rd'
      default:
        return 'th'
    }
  }

  public static getDateOnly(date: Date | string | null): string {
    if (date) {
      let dateValue: Date

      if (typeof date === 'string') {
        dateValue = new Date(date)
      } else {
        dateValue = date
      }

      return `${dateValue.getMonth() + 1}/${dateValue.getDate()}/${dateValue.getFullYear()}`
    }

    return ''
  }

  public static getYears(forecast: Forecast | null): string[] {
    if (forecast) {
      const years = [
        'thisYear',
      ]
      for (let i = 1; i <= Object.keys(forecast).length - 2; i++) {
        years.push(`year${i}`)
      }

      return years
    }

    return []
  }

  public static isNegativeOnHand(item: ForecastMonthData, activeNavYear: number, fiscalYear: number) {
    const today = new Date()
    const year = today.getFullYear()
    const month = today.getMonth()
    const itemMonth = Common.monthsMap[item.month]

    return fiscalYear === activeNavYear && item.value < 0 && (activeNavYear > year || (activeNavYear === year && itemMonth >= month))
  }

  public static getEndingOnHandValue(item: ForecastMonthData) {
    const months: MonthMap = Common.monthsMap
    const today = new Date()
    const itemYear = parseInt(item.calendarYear)

    if (itemYear < today.getFullYear() || (itemYear === today.getFullYear() && months[item.month] < today.getMonth()) || item.value === 0) {
      return 0
    } else {
      return item.value
    }
  }
}