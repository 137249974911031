




























































import { ProductAttributeDto, ProductFilter, ProductFilterField, ProductFilterOperation, ProductFieldType, TopAccountDto, ProductFilterOption } from '@basic-code/shared'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import WorksheetService from '../services/worksheet-service'
import { EventBus, SafioEvents } from '@/services/event-bus'
import { formatISO, parseISO } from 'date-fns'

@Component({})
export default class SkuFilterRow extends Vue {

  @Prop()
  initialFilter: ProductFilter|undefined

  @Prop({ required: true, default: () => [] })
  topAccounts: Partial<TopAccountDto>[]

  @Prop({ required: true, default: () => [] })
  productAttributes: ProductAttributeDto[]

  @Prop({ required: true, default: 0 })
  index: number

  filter: ProductFilter = {} as ProductFilter
  searchText = ''
  autocompleteOptions: any[] = []
  selectedFilterProperty = ''
  private searchTimeout: any
  private searching = false

  generalProductFilters: ProductFilterOption[] = [
    { value: ProductFilterField.sku, text: ProductFilterField.sku },
    { value: ProductFilterField.description, text: ProductFilterField.description },
    { value: ProductFilterField.comments, text: ProductFilterField.comments },
    { value: ProductFilterField.vendorSku, text: ProductFilterField.vendorSku },
    { value: ProductFilterField.outlook, text: ProductFilterField.outlook },
    { value: ProductFilterField.purchaseLeadTime, text: ProductFilterField.purchaseLeadTime },
    { value: ProductFilterField.curve, text: ProductFilterField.curve },
    { value: ProductFilterField.productGroupId, text: ProductFilterField.productGroupId },
    { value: ProductFilterField.vendor, text: ProductFilterField.vendor },
    { value: ProductFilterField.dateReviewed, text: ProductFilterField.dateReviewed },
    { value: ProductFilterField.topCustomer, text: ProductFilterField.topCustomer }
  ]
  attributeFilterProperties: ProductFilterOption[] = []
  allFilterProperties: ProductFilter[] = []

  set filterValue(v: string|number|Date) {
    if (this.filter.fieldType === ProductFieldType.Date) {
      if (typeof v === 'string') {
        this.filter.value = parseISO(v as string)
      } else {
        this.filter.value = v as Date
      }
    } else {
      this.filter.value = v
    }
  }

  get filterValue(): string|number|Date {
    return this.formatValue(this.filter.value || '')
  }

  set filterValue2(v: string|number|Date) {
    if (this.filter.fieldType === ProductFieldType.Date) {
      if (typeof v === 'string') {
        this.filter.value2 = parseISO(v as string)
      } else {
        this.filter.value2 = v as Date
      }
    } else {
      this.filter.value2 = v as number|Date
    }
  }

  get filterValue2 (): string|number|Date {
    return this.formatValue(this.filter.value2 || '')
  }

  private formatValue(value: string|number|Date): string|number {
    if (this.filter.fieldType === ProductFieldType.Date && value !== '') {
      if (typeof value === 'string') {
        value = parseISO(value)
      }
      return formatISO(value as Date, { representation: 'date' })
    }
    return value as string|number
  }

  created() {
    this.filter = this.initialFilter || {
      field: ProductFilterField.noSelection,
      fieldType: ProductFieldType.Any,
      operation: ProductFilterOperation.equal,
      value: '',
    }

    this.searchText = this.filter.displayValue || this.filter.value?.toString() || ''
    this.selectedFilterProperty = this.filter.field
    if (this.filter.field === ProductFilterField.productAttribute) {
      this.selectedFilterProperty += `:${this.filter.fieldIdentifier}`
    }
  }

  mounted() {
    const typeahead = this.$refs.typeahead as any
    if (typeahead) {
      typeahead.inputValue = this.searchText
    }
  }

  onPropertyChange(selection: string) {
    if (this.filter) {
      this.selectedFilterProperty = selection
      this.filter.options = []
      this.filter.operation = ProductFilterOperation.equal
      if (selection.indexOf(ProductFilterField.productAttribute) === 0) {
        const fieldId = selection.split(':')[1]
        this.filter.field = ProductFilterField.productAttribute
        this.filter.fieldType = ProductFieldType.Text
        this.filter.fieldIdentifier = Number(fieldId)
        this.filter.fieldName = this.productAttributes.find(pa => pa.id === this.filter.fieldIdentifier)?.name
        delete this.filter.value
        delete this.filter.value2
        delete this.filter.displayValue
        delete this.filter.displayValue2
      } else {
        if (Object.values(ProductFilterField).includes(selection as ProductFilterField)) {
          this.filter.field = selection as ProductFilterField
        }
        this.filter.operation = ProductFilterOperation.equal
        switch (selection) {
          case ProductFilterField.sku:
          case ProductFilterField.description:
          case ProductFilterField.comments:
          case ProductFilterField.vendorSku:
          case ProductFilterField.vendor:
          case ProductFilterField.outlook:
            this.filter.fieldType = ProductFieldType.Text
            break
          case ProductFilterField.purchaseLeadTime:
            this.filter.fieldType = ProductFieldType.Number
            break
          case ProductFilterField.curve:
          case ProductFilterField.productGroupId:
            this.filter.fieldType = ProductFieldType.ForeignKey
            break
          case ProductFilterField.dateReviewed:
            this.filter.fieldType = ProductFieldType.Date
            break
          case ProductFilterField.topCustomer:
            this.filter.fieldType = ProductFieldType.TopCustomer
            break
          default:
            break // do nothing.
        }
      }
      this.filterRowUpdated()
    }
  }

  onTopCustomerValueChange(accountId: number) {
    this.filter.displayValue = this.topAccounts.find(ta => ta.accountId === accountId)!.name
    this.filterRowUpdated()
    EventBus.$emit(SafioEvents.topCustomerFilterChange, this.filter)
  }

  @Watch('searchText')
  watchFilterValue(newVal: string) {
    this.autocomplete(newVal, this.filter)
  }

  public async autocomplete(query: string, filter: ProductFilter) {
    if (!query || query.length < 1) { return }
    if (this.searchTimeout) { clearTimeout(this.searchTimeout) }
    this.searchTimeout = setTimeout(async () => {
      this.searching = true
      const results = await WorksheetService.searchFilterValues(query, filter.field, filter.fieldIdentifier)
      Vue.set(this.filter, 'options', results)
      this.searching = false
    }, 500)
  }

  private removeFilter() {
    this.$emit(SafioEvents.filterRemove, this.index)
  }

  private filterRowUpdated() {
    this.$emit(SafioEvents.filterRowUpdated, this.index, this.filter)
  }

  private selectedTypeahead(selection: string|ProductFilterOption) {
    if (typeof selection === 'string') {
      this.filter.value = selection
    } else {
      this.filter.value = selection.value
      this.filter.displayValue = selection.text
    }
    if (this.filter.fieldType === ProductFieldType.Number) {
      this.filter.value = Number(this.filter.value)
    }
    this.filterRowUpdated()
  }

  private isDateFilter(filter: ProductFilter): boolean {
    return filter.fieldType === ProductFieldType.Date
  }

  private isTopCustomerFilter(filter: ProductFilter): boolean {
    return filter.field === ProductFilterField.topCustomer
  }

  private getFilterOperations (filter: ProductFilter) {
    const eq = { value: ProductFilterOperation.equal, text: 'equals'}
    const neq = { value: ProductFilterOperation.notEqual, text: 'does not equal'}
    const contains = { value: ProductFilterOperation.contains, text: 'contains'}
    const between = { value: ProductFilterOperation.between, text: 'between'}
    const notBetween = { value: ProductFilterOperation.notBetween, text: 'not between'}
    switch (filter.fieldType) {
      case ProductFieldType.Text: return [eq, neq, contains]
      case ProductFieldType.Date: return [eq, neq, between, notBetween]
      case ProductFieldType.Number: return [eq, neq, between, notBetween]
      case ProductFieldType.ForeignKey: return [eq, neq]
      case ProductFieldType.TopCustomer: return [eq, neq]
      default: return [eq, neq]
    }
  }

  @Watch('productAttributes', { immediate: true })
  private onProductAttributesChange() {
    this.attributeFilterProperties = []
    for (const pa of this.productAttributes) {
      this.attributeFilterProperties.push({
        value: `${ProductFilterField.productAttribute}:${pa.id}`,
        text: pa.name
      })
    }
  }
}
