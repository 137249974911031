import { BaseHttpService } from '@/services/base-http-service'

class ShopifyService extends BaseHttpService {
    // Known as products in Shopify
    public async getProductGroups(shopName: string) {
        const uri = `/shopify/productGroups?shopName=${shopName}`
        const response = await this.doApiGet(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    // Known as product in Shopify
    public async getProductGroup(shopName: string, id: number) {
        const uri = `/shopify/productGroups/${id}?shopName=${shopName}`
        const response = await this.doApiGet(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    // Known as product variants in Shopify
    public async getProducts(shopName: string, id: number) {
        const uri = `/shopify/productGroups/${id}/products?shopName=${shopName}`
        const response = await this.doApiGet(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateVariantSku(shopName: string, id: number, sku: string) {
        const uri = `/shopify/variant/${id}`
        const response = await this.doApiPut(uri, JSON.stringify({ shopName: shopName, sku: encodeURIComponent(sku) }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new ShopifyService()
