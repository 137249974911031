import { BomState, RootState } from '@/store/types'
import DtoFactory from '@/util/dto-factory'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import ProductService from '@/services/v3/product-service'
import { BillOfMaterialDto, ProductDto } from '@basic-code/shared'

export const state: BomState = {
    initialSku: '',
    assemblyCost: 0,
    bom: [],
    initialProduct: DtoFactory.blankProductDto(),
    originalLoadComplete: false,
}

const getters: GetterTree<BomState, RootState> = {
    initialProduct(state) {
        return state.initialProduct
    }
}

const mutations: MutationTree<BomState> = {
    setInitialProduct(state, payload: ProductDto) {
        state.initialProduct = payload
        state.assemblyCost = payload ? payload.assemblyCost : 0
    },

    setInitialSku(state, payload: string) {
        state.initialSku = payload
    },

    setAssemblyCost (state, assemblyCost) {
        state.assemblyCost = assemblyCost
    },

    updateAssemblyCost (state, assemblyCost: number) {
        state.assemblyCost = assemblyCost
        state.initialProduct.assemblyCost = assemblyCost
        if (state.initialProduct.billOfMaterials && state.initialProduct.billOfMaterials.length) {
            for (const bom of state.initialProduct.billOfMaterials) {
                if (bom.product) {
                    bom.product['assemblyCost'] = assemblyCost
                }
            }
        }
    },

    setBom (state, bom: BillOfMaterialDto[]) {
        state.bom = bom
    },

    addToBom (state, bom: BillOfMaterialDto) {
        state.bom.push(bom)
    },

    editBom (state, { index, bom }: { index: number; bom: BillOfMaterialDto }) {
        state.bom[index] = bom
    },

    updateProductBom (state) {
        state.initialProduct.billOfMaterials = state.bom
    }
}

const actions: ActionTree<BomState, RootState> = {
    async productBySku({ state }, payload: string) {
        return await ProductService.getBySku(payload)
    },
}

const namespaced = true

export const bomStore: Module<BomState, RootState> = {
    namespaced: namespaced,
    state,
    mutations,
    actions,
    getters,
}
