import { BaseHttpService } from '@/services/base-http-service'

class CeligoImportService extends BaseHttpService {
    public async updateDataImportsFromCeligo() {
        const uri = '/celigo-import/flows'
        const response = await this.doApiPut(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new CeligoImportService()
