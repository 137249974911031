import { BaseHttpService } from '@/services/base-http-service'
import { ProductAttributeDto, DropdownOption } from '@basic-code/shared'

class ProductAttributesService extends BaseHttpService {
    public async getProductAttributes(includeOptions = true, sortColumn?: string): Promise<ProductAttributeDto[]> {
        const uri = `/product-attributes?includeOptions=${includeOptions}${sortColumn ? `&sortColumn=${sortColumn}` : ''}`
        const response = await this.doApiGet<ProductAttributeDto[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateProductAttribute(id: number, productAttribute: Partial<ProductAttributeDto>, oldSortOrder = -1): Promise<ProductAttributeDto> {
        const uri = `/product-attributes/${id}`
        const response = await this.doApiPut<ProductAttributeDto>(uri, JSON.stringify({ productAttribute: productAttribute, oldSortOrder: oldSortOrder }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async createProductAttribute(name: string, isExternal: boolean, sortOrder: number): Promise<ProductAttributeDto> {
        const productAttribute = {
            name: name,
            isExternal: isExternal,
            sortOrder: sortOrder,
        }

        const uri = '/product-attributes/'
        const response = await this.doApiPost<ProductAttributeDto>(uri, JSON.stringify({ productAttribute: productAttribute }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getProductAttributesForForecastLock (salesReps: DropdownOption[], accounts: DropdownOption[], vendors: DropdownOption[]): Promise<ProductAttributeDto[]> {
        const uri = '/product-attributes-for-forecast-lock'
        const response = await this.doApiPost<ProductAttributeDto[]>(uri, JSON.stringify({
            salesReps: salesReps.map(sr => sr.value),
            accounts: accounts.map(a => a.value),
            vendors: vendors.map(v => v.value),
        }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new ProductAttributesService()
