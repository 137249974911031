import { abilitiesPlugin } from '@casl/vue'
import Vue from 'vue'
import { BaseHttpService } from '@/services/base-http-service'
import { Ability } from '@casl/ability'
import { Can } from '@casl/vue'
import ApiService from './api-service'

class AuthorizationService extends BaseHttpService {

  public registerAbility() {
    Vue.use(abilitiesPlugin, new Ability([])) // this gets updated with rules after login.
    Vue.component('Can', Can)
  }

  public async updateAbilities (vueInstance: Vue) {
    console.log('Updating abilities for', ApiService.tenant)
    try {
      const rules = await this.getPermissionRules()
      vueInstance.$ability.update(rules)
    } catch (err) {
      console.error(err)
      vueInstance.$bvToast.toast('An error occurred while communicating with the server.  Please check your network connection.', { autoHideDelay: 2500 })
    }
  }
}

export default new AuthorizationService()