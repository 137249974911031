import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { SetupState, RootState } from '@/store/types'
import ImportService from '@/services/v3/import-service'
import CeligoImportService from '@/services/v3/celigo-import-service'
import VendorService from '@/services/v3/vendor-service'
import ProductGroupService from '@/services/v3/product-group-service'
import ProductService from '@/services/v3/product-service'
import { ProductDto, ProductGroupDto, ShippingMethodDto, SystemSettingDto, VendorDto, DataGroupDto, DataImportDto,
  DataGroupDataImportDto, ForecastLockRule, ImportDto } from '@basic-code/shared'
import shippingMethodService from '@/services/v3/shipping-method-service'
import { AdminViewDto, VendorChangeEvent, ChangeType, ViewDto } from '@/types'
import AdminViewService from '@/services/v3/admin-view-service.ts'
import ViewService from '@/services/v3/view-service.ts'
import DtoFactory from '@/util/dto-factory'
import _ from 'lodash'
import { subject as authSubject } from '@casl/ability'
import TableauImportService from '@/services/v3/tableau-import-service'
import DataImportService from '@/services/v3/data-import-service'
import DataGroupService from '@/services/v3/data-group-service'
import DataGroupDataImportService from '@/services/v3/data-group-data-import-service'
import { EventBus, SafioEvents } from '@/services/event-bus'

export const state: SetupState = {
  flatFileImports: [],
  tableauDataImports: [],
  shopifyDataImports: [],
  awsDataImports: [],
  celigoDataImports: [],
  internalProcessDataImports: [],
  dataGroups: [],
  loading: false,
  vendors: [],
  newProductGroups: [],
  newProducts: [],
  shippingMethods: [],
  tabsLoaded: {
    products: false,
    attributes: false,
    data: false,
    vendors: false,
    settings: false,
  },
  loadingVendorDetails: false,
  originalSettings: DtoFactory.blankSettings(),
  editingSettings: DtoFactory.blankSettings(),
  adminViews: [],
  customViews: [],
  editingDataGroupId: -1,
  dataGroupDirty: false,
  editingForecastLockRule: null,
  originalForecastLockRule: null,
  editingForecastLockRuleInvalid: false,
  isNewTargetedLock: false,
  targetedLockDirty: false,
}

const getters: GetterTree<SetupState, RootState> = {}

const mutations: MutationTree<SetupState> = {
  setEditingForecastLockRuleInvalid (state, isInvalid: boolean) {
    state.editingForecastLockRuleInvalid = isInvalid
  },

  setIsNewTargetedLock (state, isNew: boolean) {
    state.isNewTargetedLock = isNew
  },

  setTargetedLockDirty (state, isDirty: boolean) {
    state.targetedLockDirty = isDirty
  },

  setOriginalForecastLockRule (state, originalForecastLockRule: ForecastLockRule|null) {
    state.originalForecastLockRule = _.cloneDeep(originalForecastLockRule)
  },

  setEditingForecastLockRule (state, editingForecastLockRule: ForecastLockRule|null) {
    state.editingForecastLockRule = _.cloneDeep(editingForecastLockRule)
  },

  deleteForecastLockRule (state, payload: number) {
    const index = state.editingSettings.forecastLockRules.findIndex(flr => flr.id === payload)
    if (index !== -1) {
      state.editingSettings.forecastLockRules.splice(index, 1)
    }
  },

  updateForecastLockRule (state) {
    const forecastLockRuleIndex = state.editingForecastLockRule ?
        state.editingSettings.forecastLockRules.findIndex(flr => flr.id === state.editingForecastLockRule!.id) :
        -1
    if (forecastLockRuleIndex !== -1) {
      state.editingSettings.forecastLockRules[forecastLockRuleIndex] = state.editingForecastLockRule!
    }
  },

  setFlatFileImports (state, newFlatFileImports: ImportDto[]) {
    state.flatFileImports = newFlatFileImports
  },

  setCeligoDataImports (state, dataImports: DataImportDto[]) {
    state.celigoDataImports = dataImports
  },

  setTableauDataImports (state, dataImports: DataImportDto[]) {
    state.tableauDataImports = dataImports
  },

  setShopifyDataImports (state, dataImports: DataImportDto[]) {
    state.shopifyDataImports = dataImports
  },

  setAWSDataImports (state, dataImports: DataImportDto[]) {
    state.awsDataImports = dataImports
  },

  setInternalProcessDataImports (state, dataImports: DataImportDto[]) {
    state.internalProcessDataImports = dataImports
  },

  setDataGroups (state, dataGroups: DataGroupDto[]) {
    state.dataGroups = dataGroups
  },

  removeDataGroup (state, payload: number) {
    const index = state.dataGroups.findIndex(dg => dg.id === payload)
    if (index !== -1) {
      state.dataGroups.splice(index, 1)
    }
  },

  insertDataGroup (state) {
    state.dataGroups.push({
      id: 0,
      createdAt: new Date(),
      updatedAt: new Date(),
      name: '',
      dataGroupDataImports: [] as DataGroupDataImportDto[],
      dataImports: [] as DataImportDto[],
    })
  },

  addDataGroup (state, payload: DataGroupDto) {
    const dataGroup = state.dataGroups.find(dg => dg.id === 0)
    if (dataGroup) {
      dataGroup.id = payload.id
      dataGroup.name = payload.name
      dataGroup.dataGroupDataImports = []
    }
  },

  updateDataGroupName (state, payload: string) {
    const dataGroup = state.dataGroups.find(dg => dg.id === state.editingDataGroupId)
    if (dataGroup) {
      dataGroup.name = payload
    }
  },

  updateDataGroupDataImports (state, payload: { dataGroupId: number; dataGroupDataImports: DataGroupDataImportDto[] }) {
    const dataGroup = state.dataGroups.find(dg => dg.id === payload.dataGroupId)
    if (dataGroup) {
      dataGroup.dataGroupDataImports = _.cloneDeep(payload.dataGroupDataImports)
    }
  },

  setEditingDataGroupId (state, id: number) {
    state.editingDataGroupId = id
  },

  updateFlatFileImports(state, { errorNumber, importName }: { errorNumber: number; importName: string }) {
    const flatFileImport = state.flatFileImports.find(ffi => ffi.importName === importName)
    if (flatFileImport) {
      flatFileImport.lastErrorNumber = errorNumber ? errorNumber.toString() : ''
    }
  },

  updateDataGroupRunStatus(state, payload: { dataImport: DataImportDto; isRunning: boolean; dataGroupId: number|null }) {
    for (const dataGroup of state.dataGroups) {
      if (dataGroup.dataGroupDataImports) {
        const dataGroupDataImport = dataGroup.dataGroupDataImports.find(dgdi => dgdi.dataImport!.id === payload.dataImport.id)
        if (dataGroupDataImport) {
          if (payload.dataGroupId && dataGroupDataImport.dataGroupId === payload.dataGroupId) {
            dataGroupDataImport.currentlyRunning = payload.isRunning
          }
          dataGroupDataImport.dataImport!.currentlyRunning = payload.isRunning
        }
      }
    }

    EventBus.$emit(SafioEvents.dataImportRunStatusChange, null)
  },

  updateDataImportRunStatus(state, payload: { dataImport: DataImportDto; isRunning: boolean }) {
    switch (payload.dataImport.importType) {
      case 'celigo': {
        const celigoDataImport = state.celigoDataImports.find(di => di.id === payload.dataImport.id)
        if (celigoDataImport) {
          celigoDataImport.currentlyRunning = payload.isRunning
        }
        break
      }
      case 'exavault': {
        const awsDataImport = state.awsDataImports.find(di => di.id === payload.dataImport.id)
        if (awsDataImport) {
          awsDataImport.currentlyRunning = payload.isRunning
        }
        break
      }
      case 'internal_process': {
        const internalProcessDataImport = state.internalProcessDataImports.find(di => di.id === payload.dataImport.id)
        if (internalProcessDataImport) {
          internalProcessDataImport.currentlyRunning = payload.isRunning
        }
        break
      }
      case 'tableau': {
        const tableauImport = state.tableauDataImports.find(di => di.id === payload.dataImport.id)
        if (tableauImport) {
          tableauImport.currentlyRunning = payload.isRunning
        }
        break
      }
    }
  },

  toggleLoading(state) {
    state.loading = !state.loading
  },

  setDataGroupDirty(state, payload: boolean) {
    state.dataGroupDirty = payload
  },

  setTabLoaded(state, payload: string) {
    state.tabsLoaded[payload] = true
  },

  setVendors(state, payload: VendorDto[]) {
    state.vendors = payload
  },

  setNewProductGroups(state, payload: ProductGroupDto[]) {
    state.newProductGroups = payload
  },

  setNewProducts(state, payload: ProductDto[]) {
    state.newProducts = payload
  },

  setShippingMethods(state, payload: ShippingMethodDto[]) {
    state.shippingMethods = payload
  },

  setAdminViews(state, payload: AdminViewDto[]) {
    state.adminViews = payload
  },

  setCustomViews(state, payload: ViewDto[]) {
    state.customViews = payload
  },

  changeVendor(state, event: VendorChangeEvent) {
    let vendorIndex = -1
    switch (event.changeType) {
      case ChangeType.Create:
        state.vendors.push(JSON.parse(JSON.stringify(event.vendor)))
        state.vendors.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
        break
      case ChangeType.Update:
        vendorIndex = state.vendors.findIndex(vendor => vendor.id === event.vendor.id)

        if (vendorIndex !== -1) {
          state.vendors.splice(vendorIndex, 1, JSON.parse(JSON.stringify(event.vendor)))
          state.vendors.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
        }
        break
      case ChangeType.Delete:
        vendorIndex = state.vendors.findIndex(vendor => vendor.id === event.vendor.id)

        if (vendorIndex !== -1) {
          state.vendors.splice(vendorIndex, 1)
        }
        break
      default:
        break
    }
  },

  replaceVendorWithDetailedVersion(state, { vendor, vendorIndex }: { vendor: VendorDto; vendorIndex: number }) {
    console.log('splicing vendor to', vendorIndex)
    state.vendors.splice(vendorIndex, 1, vendor)
    let i = 0
    for (const v of state.vendors) {
      if (v.vendorCode === 'HUGFUN') { console.log('hugfun at', i)}
      i+= 1
    }
  },

  setEditingSettings(state, payload: SystemSettingDto) {
    state.originalSettings = _.cloneDeep(payload)
    state.editingSettings = payload
  },

  revertEditingSettings(state) {
    state.editingSettings = authSubject('settings', _.cloneDeep(state.originalSettings))
  },

  toggleLoadingVendorDetails(state) {
    state.loadingVendorDetails = !state.loadingVendorDetails
  },
}

const actions: ActionTree<SetupState, RootState> = {
  async getFlatFileImports ({ commit }) {
    try {
      const flatFileImports = await ImportService.getImports(false, true)
      commit('setFlatFileImports', flatFileImports)
    } catch (err) {
      console.error(err)
    }
  },

  async getCeligoDataImports ({ commit }) {
    try {
      await CeligoImportService.updateDataImportsFromCeligo()
      const dataImports = await DataImportService.getDataImports('celigo')
      commit('setCeligoDataImports', dataImports)
    } catch (err) {
      console.error(err)
    }
  },

  async getTableauDataImports ({ commit }) {
    try {
      await TableauImportService.updateDataImportsFromTableau()
      const dataImports = await DataImportService.getDataImports('tableau')
      commit('setTableauDataImports', dataImports)
    } catch (err) {
      console.error(err)
    }
  },

  async getShopifyDataImports ({ commit }) {
    try {
      const dataImports = await DataImportService.getDataImports('shopify')
      commit('setShopifyDataImports', dataImports)
    } catch (err) {
      console.error(err)
    }
  },

  async getAWSDataImports ({ commit }) {
    try {
      const dataImports = await DataImportService.getDataImports('exavault')
      commit('setAWSDataImports', dataImports)
    } catch (err) {
      console.log(err)
    }
  },

  async getInternalProcessDataImports ({ commit }) {
    try {
      const dataImports = await DataImportService.getDataImports('internal_process')
      commit('setInternalProcessDataImports', dataImports)
    } catch (err) {
      console.log(err)
    }
  },

  async getDataGroups ({ commit }) {
    try {
      const dataGroups = await DataGroupService.getDataGroups()
      commit('setDataGroups', dataGroups)
    } catch (err) {
      console.log(err)
    }
  },

  async createDataGroup ({ commit }, payload: Partial<DataGroupDto>): Promise<number> {
    try {
      const newDataGroup = await DataGroupService.createDataGroup(payload)
      commit('addDataGroup', newDataGroup)
      return newDataGroup.id
    } catch (err) {
      throw new Error(err)
    }
  },

  async updateDataGroup ({ commit, state }, payload: string) {
    try {
      await DataGroupService.updateDataGroup(state.editingDataGroupId, { name: payload })
      commit('updateDataGroupName', payload)
    } catch (err) {
      console.log(err)
    }
  },

  async getDataImportErrorMessage ({ commit }, payload: { err: any; dataImport: DataImportDto }): Promise<string> {
    switch (payload.dataImport.importType) {
      case 'celigo': {
        if (payload.err.toString().indexOf('422') !== -1) {
          return 'Celigo flow is disabled; please enable flow from within Celigo before running again.'
        } else {
          return `Error running Data Import. Error: ${payload.err}`
        }
      }
      case 'exavault': {
        if (payload.err.toString().indexOf('File not found') !== -1) {
          return payload.err.toString() //'File not found in AWS; please confirm the file exists and is in the correct AWS directory.'
        } else {
          return `Error running Data Import. Error: ${payload.err}`
        }
      }
      case 'tableau': {
        if (payload.err.toString().indexOf('409') !== -1) {
          return 'Tableau extract is already in queue.'
        } else {
          return `Error running Data Import. Error: ${payload.err}`
        }
      }
      default:
        return `Error running Data Import. Error: ${payload.err}`
    }
  },

  async addUpdateDataGroupDataImports ({ commit }, payload: { dataGroupId: number; dataGroupDataImports: DataGroupDataImportDto[] }) {
    try {
      await DataGroupDataImportService.updateOrCreateDataGroupDataImports(payload.dataGroupId, payload.dataGroupDataImports)
      commit('updateDataGroupDataImports', { dataGroupId: payload.dataGroupId, dataGroupDataImports: payload.dataGroupDataImports })
      commit('setEditingDataGroupId', payload.dataGroupId)
    } catch (err) {
      console.log(err)
    }
  },

  async lazyLoadProductTabData({ commit, state, rootState }, force = false) {
    if (!state.tabsLoaded.products || force) {
      try {
        const promises: any[] = []
        promises.push(VendorService.getVendors(false, false))
        const isShopify = rootState.settings && rootState.settings.dataServices.isShopify
        if (isShopify) {
          promises.push(ProductGroupService.getByProductNewStatus(true))
          promises.push(Promise.resolve([])) // placeholder
        } else {
          promises.push(Promise.resolve([])) // placeholder
          promises.push(ProductService.getByNewStatus(true))
        }
        const results = await Promise.all(promises)
        commit('setVendors', results[0])
        commit('setNewProductGroups', results[1])
        commit('setNewProducts', results[2])
        commit('setTabLoaded', 'products')
      } catch (err) {
        console.error(err)
      } finally {
        // toggle loading?
      }
    }
  },

  async lazyLoadVendorTabData({ commit, state }, force = false) {
    if (!state.tabsLoaded.vendors || force) {
      try {
        const promises: any[] = [] // Run the loads in parallel
        promises.push(shippingMethodService.getShippingMethods())

        // Odds are the products tab already loaded these.  If so, no need to get them again.
        if (state.vendors.length === 0) {
          promises.push(VendorService.getVendors(false, false))
        }
        const results = await Promise.all(promises)
        commit('setShippingMethods', results[0])
        if (results.length > 1) {
          commit('setVendors', results[1])
        }
        commit('setTabLoaded', 'vendors')
      } catch (err) {
        console.error(err)
      } finally {
        //
      }
    }
  },

  async loadShippingMethods({ commit }) {
    try {
      const shippingMethods = await shippingMethodService.getShippingMethods()
      commit('setShippingMethods', shippingMethods)
    } catch (err) {
      console.error(err)
    }
  },

  async loadVendorDetails({ commit, state }, vendorId: number) {
    try {
      commit('toggleLoadingVendorDetails')
      const vendorIndex = state.vendors.findIndex(v => v.id === vendorId)
      if (vendorIndex >= 0) {
        const vendor = state.vendors[vendorIndex]
        if (!vendor?.vendorLocations?.length || !vendor?.vendorProducts?.length) {
          const vendorDto = await VendorService.getVendor(vendorId)
          commit('replaceVendorWithDetailedVersion', { vendor: vendorDto, vendorIndex })
        }
      }
    } catch (err) {
      console.error(err)
    } finally {
      commit('toggleLoadingVendorDetails')
    }
  },

  async getAdminViews ({ commit }) {
    try {
      const adminViews = await AdminViewService.getAdminViews()
      commit('setAdminViews', adminViews)
    } catch (err) {
      console.error(err)
    }
  },

  async updateAdminView ({ commit }, { payload, oldCreateOrder }: { payload: AdminViewDto; oldCreateOrder: number }) {
    try {
      if (!payload.id) {
        await AdminViewService.createAdminView(payload)
      } else {
        await AdminViewService.updateAdminView(payload.id, payload, oldCreateOrder)
      }
    } catch (err) {
      console.error(err)
    }
  },

  async deleteAdminView ({ commit }, payload: AdminViewDto) {
    try {
      await AdminViewService.deleteAdminView(payload.id)
    } catch (err) {
      console.error(err)
    }
  },

  async deleteDataGroup ({ commit }, payload: number) {
    try {
      await DataGroupService.deleteDataGroup(payload)
      commit('removeDataGroup', payload)
    } catch (err) {
      console.log(err)
    }
  },

  async getCustomViews ({ commit }) {
    try {
      const customViews = await ViewService.getViews()
      commit('setCustomViews', customViews)
    } catch (err) {
      console.error(err)
    }
  },

  async updateCustomView ({ commit }, { payload, oldCreateOrder }: { payload: ViewDto; oldCreateOrder: number }) {
    try {
      let customView: ViewDto

      if (!payload.id) {
        customView = await ViewService.createView(payload)
      } else {
        customView = await ViewService.updateView(payload.id, payload, oldCreateOrder)
      }
    } catch (err) {
      console.error(err)
    }
  },

  async deleteCustomView ({ commit }, payload: ViewDto) {
    try {
      await ViewService.deleteView(payload.id)
    } catch (err) {
      console.error(err)
    }
  },
}

const namespaced = true

export const setupStore: Module<SetupState, RootState> = {
  namespaced: namespaced,
  state,
  mutations,
  actions,
  getters,
}
