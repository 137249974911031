import { PurchaseOrderState, RootState } from '../types'
import { InventoryLocationDto, PlannerProposedOrdersDtoV3, ProductDto, PurchaseOrderDto, PurchaseOrderHistoryDto,
  PurchaseOrderLineItemDto, PurchaseOrderPriceAdjustmentDto, PurchaseOrderStatus, ShippingMethodDto, VendorDto,
  VendorLocationDto, VendorProductDto, POLIReceiptType, PurchaseOrderLineItemReceiptDto } from '@basic-code/shared'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import VendorService from '@/services/v3/vendor-service'
import { Vue } from 'vue-property-decorator'
import PurchaseOrderService from '@/services/v3/purchase-order-service'
import _ from 'lodash'
import { add as dateAdd } from 'date-fns'
import VendorProductService from '@/services/v3/vendor-product-service'
import shippingMethodService from '@/services/v3/shipping-method-service'
import ProductService from '@/services/v3/product-service'
import { InterWindowEvents, WindowBus } from '@/services/event-bus'

const state: PurchaseOrderState = {
  shippingMethods: [],
  purchaseOrder: PurchaseOrderDto.create(),
  originalPurchaseOrder: null,
  poLineItems: [],
  poPriceAdjs: [],
  vendors: [],
  inventoryLocations: [],
  allProducts: [],
  vendorProducts: [],
  loadingPurchaseOrder: false,
  loadingVendors: false,
  loadingInventoryLocations: false,
  loadingProducts: false,
  loadingVendorProducts: false,
  loadingPdf: false,
  loadingProposedOrders: false,
  savingInventoryLocation: false,
  savingVendorLocation: false,
  savingPurchaseOrder: false,
  purchaseOrderLoadError: '',
  requestedShipDateSet: false,
  poDirty: false,
  proposedOrders: [],
  defaultShippingMethodId: -1,
}

const getters: GetterTree<PurchaseOrderState, RootState> = {

  poVendor(state) {
    const vendor = state.purchaseOrder.vendor
    if (vendor) {
      return vendor
    }
    return VendorDto.create()
  },

  poLoadError(state) {
    return state.purchaseOrderLoadError
  },

  shipToLocations(state): InventoryLocationDto[] {
    if (state.poLineItems.length) {
      const locations = state.poLineItems.filter(poli => poli.shipToLocation !== undefined && poli.shipToLocation.id !== 0).map(poli => poli.shipToLocation!)
      if (locations) {
        return _.uniqBy(locations, 'id')
      }
    }
    return []
  },

  defaultEstimatedReceiptDate(state): Date|undefined {
    if (state.purchaseOrder.orderDate && state.poLineItems.length) {
      return state.purchaseOrder.estimatedReceiptDate
    }
    return
  },

  productsForSelectedVendor(state): ProductDto[] {
    return state.vendorProducts.map(vp => vp.product!)
  },

  history(state): PurchaseOrderHistoryDto[] {
    return state.purchaseOrder.purchaseOrderHistories || []
  },

  adding(state): boolean {
    return state.purchaseOrder.id === 0
  },

  isDraft(state): boolean {
    return state.purchaseOrder.poStatus === PurchaseOrderStatus.draft
  },

  isReview(state): boolean {
    return state.purchaseOrder.poStatus === PurchaseOrderStatus.review
  },

  isOpen(state): boolean {
    return state.purchaseOrder.poStatus === PurchaseOrderStatus.open
  },

  isClosed(state): boolean {
    return state.purchaseOrder.poStatus === PurchaseOrderStatus.closed
  },

  isDiscarded(state): boolean {
    return state.purchaseOrder.poStatus === PurchaseOrderStatus.discarded
  },

  isCanceled(state): boolean {
    return state.purchaseOrder.poStatus === PurchaseOrderStatus.canceled
  },

  isDownloadable(state): boolean {
    return state.purchaseOrder.poStatus !== PurchaseOrderStatus.discarded && !(state.purchaseOrder.poStatus == PurchaseOrderStatus.draft && state.purchaseOrder.id === 0)
  },

  isEditable(state): boolean {
    const s = state.purchaseOrder.poStatus
    return s === PurchaseOrderStatus.draft || s === PurchaseOrderStatus.review || s === PurchaseOrderStatus.open
  },

  proposedOrders(state): PlannerProposedOrdersDtoV3[] {
    return state.proposedOrders.filter(ppo => {
      return !state.poLineItems.map(poli => poli.vendorProduct?.productId).includes(ppo.productId)
    })
  },

  availableVendorProducts(state): VendorProductDto[] {
    const usedVpIds = state.poLineItems.map(poli => poli.vendorProductId)
    return state.vendorProducts.filter(vp => !usedVpIds.includes(vp.id))
  },
}

const actions: ActionTree<PurchaseOrderState, RootState> = {

  async loadPurchaseOrder({ commit }, purchaseOrderId: number) {
    try {
      commit('toggleLoadingPurchaseOrder')
      const po = await PurchaseOrderService.getPurchaseOrder(purchaseOrderId)
      if (po) {
        if (po.purchaseOrderLineItems) {
          for (const purchaseOrderLineItem of po.purchaseOrderLineItems) {
            purchaseOrderLineItem.receivedReceiptDate = new Date()
            purchaseOrderLineItem.returnedReceiptDate = new Date()
            purchaseOrderLineItem.canceledReceiptDate = new Date()
          }
        }
        commit('setPurchaseOrder', po)
      } else {
        commit('setPurchaseOrderLoadError', `No purchase order found for id ${purchaseOrderId}`)
      }
    } catch (err) {
      console.error(err)
      commit('setPurchaseOrderLoadError', err.message)
    } finally {
      commit('toggleLoadingPurchaseOrder')
    }
  },

  async loadVendors({ commit }) {
    try {
      commit('toggleLoadingVendors')
      const vendors = await VendorService.getVendors(true, false)
      commit('setVendors', vendors)
    } catch (err) {
      console.error(err)
    } finally {
      commit('toggleLoadingVendors')
    }
  },

  async loadInventoryLocations({ commit }) {
    try {
      commit('toggleLoadingInventoryLocations')
      const inventoryLocations = await PurchaseOrderService.getInventoryLocations()
      commit('setInventoryLocations', inventoryLocations)
    } catch (err) {
      console.error(err)
    } finally {
      commit('toggleLoadingInventoryLocations')
    }
  },

  /**
   * Gets all products in the system.  Note that since this can be a lot of records, only id, sku, and description are populated.
   * @param context
   * @returns
   */
   async loadAllProductsForFilter({ commit }) {
    try {
      commit('toggleLoadingAllProducts')
      const products = await ProductService.getAllProductsForFilter(true)
      commit('setAllProducts', products)
    } catch (err) {
      console.error(err)
      return false
    } finally {
      commit('toggleLoadingAllProducts')
    }
  },

  async loadVendorProducts({ commit, state }) {
    try {
      commit('toggleLoadingVendorProducts')
      const vendorId = state.purchaseOrder.vendor?.id
      if (vendorId) {
        const vps = await VendorProductService.getVendorProducts({ includeChildren: false, vendorId })
        commit('setVendorProducts', vps)
      } else {
        commit('setVendorProducts', [])
      }
    } catch (err) {
      console.error(err)
      commit('setVendorProducts', [])
    } finally {
      commit('toggleLoadingVendorProducts')
    }
  },

  async loadShippingMethods({ commit }) {
    try {
      const methods = await shippingMethodService.getShippingMethods()
      commit('setShippingMethods', methods)
    } catch(err) {
      console.error(err)
    }
  },

  async loadProposedOrders({ commit, state }) {
    try {
      commit('toggleLoadingProposedOrders')
      if (state.poLineItems.length) {
        const vendorId = state.poLineItems[0].vendorProduct?.vendorId
        if (vendorId) {
          const orders = await PurchaseOrderService.getPlannerProposedOrders(vendorId, new Date().getMonth())
          commit('setProposedOrders', orders)
        }
      }
    } catch (err) {
      console.error(err)
      throw err
    } finally {
      commit('toggleLoadingProposedOrders')
    }
  },

  async savePurchaseOrder({ commit, state }, comment?: string) {
    console.log(state.purchaseOrder);
    try {
      commit('toggleSavingPurchaseOrder')
      const poLineItemReceipts: Partial<PurchaseOrderLineItemReceiptDto>[] = []

      for (const lineItem of state.poLineItems) {
        const purchaseOrderLineItem = state.purchaseOrder.purchaseOrderLineItems.find(poli => poli.id === lineItem.id)

        if (purchaseOrderLineItem) {
          if (purchaseOrderLineItem.qtyReceived !== lineItem.qtyReceived) {
            poLineItemReceipts.push({
              purchaseOrderLineItemId: purchaseOrderLineItem.id,
              receiptType: POLIReceiptType.received,
              receiptQuantity: lineItem.qtyReceived - purchaseOrderLineItem.qtyReceived,
              receiptDate: lineItem.receivedReceiptDate ? lineItem.receivedReceiptDate : new Date(),
            })
          }
          if (purchaseOrderLineItem.qtyCanceled !== lineItem.qtyCanceled) {
            poLineItemReceipts.push({
              purchaseOrderLineItemId: purchaseOrderLineItem.id,
              receiptType: POLIReceiptType.canceled,
              receiptQuantity: lineItem.qtyCanceled - purchaseOrderLineItem.qtyCanceled,
              receiptDate: lineItem.canceledReceiptDate ? lineItem.canceledReceiptDate : new Date(),
            })
          }
          if (purchaseOrderLineItem.qtyReturned !== lineItem.qtyReturned) {
            poLineItemReceipts.push({
              purchaseOrderLineItemId: purchaseOrderLineItem.id,
              receiptType: POLIReceiptType.returned,
              receiptQuantity: lineItem.qtyReturned - purchaseOrderLineItem.qtyReturned,
              receiptDate: new Date(),
            })
          }
        }
      }

      if (poLineItemReceipts.length) {
        await PurchaseOrderService.createPurchaseOrderLineItemReceipts(state.purchaseOrder.id, poLineItemReceipts)
      }
      state.purchaseOrder.purchaseOrderLineItems = state.poLineItems
      state.purchaseOrder.purchaseOrderPriceAdjustments = state.poPriceAdjs
      const po = await PurchaseOrderService.savePurchaseOrder(state.purchaseOrder, comment)
      commit('setPurchaseOrder', po)
      if (state.initialProduct) {
        WindowBus.$emit(InterWindowEvents.reloadSku, null)
      }
    } catch (err) {
      console.error(err)
      throw err
    } finally {
      commit('toggleSavingPurchaseOrder')
    }
  },

  async addComment({ commit, state }, comment: string) {
    try {
      commit('toggleSavingPurchaseOrder')
      const history = await PurchaseOrderService.addComment(state.purchaseOrder, comment)
      commit('prependHistoryRecord', history)
    } catch (err) {
      console.error(err)
      throw err
    } finally {
      commit('toggleSavingPurchaseOrder')
    }
  },

  async moveToStatus({ commit, state }, payload: { comment: string; newStatus: PurchaseOrderStatus}) {
    try {
      commit('toggleSavingPurchaseOrder')
      state.purchaseOrder.poStatus = payload.newStatus
      const po = await PurchaseOrderService.savePurchaseOrder(state.purchaseOrder, payload.comment)
      commit('setPurchaseOrder', po)
    } catch (err) {
      console.error(err)
      throw err
    } finally {
      commit('toggleSavingPurchaseOrder')
    }
  },
  
  async cancelRemainingAndClose({ commit, state, dispatch }, payload: { comment: string }) {
    try {
      commit('toggleSavingPurchaseOrder')
      if (state.poLineItems.length) {
        for (let i = 0; i < state.poLineItems.length; i++ ) {
          state.poLineItems[i].qtyCanceled += state.poLineItems[i].qtyOpen
          state.poLineItems[i].qtyOpen = 0;
        }
      }
      state.purchaseOrder.poStatus = PurchaseOrderStatus.closed
      dispatch('savePurchaseOrder', payload)
    } catch (err) {
      console.error(err)
      throw err
    } finally {
      commit('toggleSavingPurchaseOrder')
    }
  },

  async fulfillRemainingAndClose({ commit, state, dispatch }, payload: { comment: string }) {
    try {
      commit('toggleSavingPurchaseOrder')
      if (state.poLineItems.length) {
        for (let i = 0; i < state.poLineItems.length; i++ ) {
          state.poLineItems[i].qtyReceived += state.poLineItems[i].qtyOpen
          state.poLineItems[i].qtyOpen = 0;
        }
      }
      state.purchaseOrder.poStatus = PurchaseOrderStatus.closed
      dispatch('savePurchaseOrder', payload)
    } catch (err) {
      console.error(err)
      throw err
    } finally {
      commit('toggleSavingPurchaseOrder')
    }
  },

  async startNewPurchaseOrder({ commit, state }, {productId, qty, month, year, ppoid}: {productId: number; qty: number; month: number; year: number; ppoid: number}) {
    try {
      commit('setDefaultShippingMethodId')
      const product = await ProductService.getProduct(productId)
      commit('startNewPurchaseOrder', { product, month, year, ppoid })
      if (!product) {
        commit('setPurchaseOrderLoadError', `No product found for id ${productId}`)
      } else if (! product.vendorProducts?.length) {
        commit('setPurchaseOrderLoadError', 'Cannot set up product on purchase order.  No vendors assigned to product.')
      } else {
        commit('addLineItem')
        const lineItem = state.poLineItems[0]
        let selectedVp = product.vendorProducts?.find(vp => {
          return vp.productId === productId && vp.poOverride === true
        })
        if (!selectedVp) {
          selectedVp = product.vendorProducts?.find(vp => {
            return vp.productId === productId && vp.priority === 1
          })
        }
        lineItem.vendorProduct = selectedVp

        commit('assignVendorProduct', {lineItem, vendorProduct: lineItem.vendorProduct, qty})
        commit('updateVendor', lineItem.vendorProduct!.vendor!)
        commit('updateLineItemTotals')
        commit('setRevertState')
      }
    } catch (err) {
      console.error(err)
      commit('setSetupError', 'Error looking up product.  Cannot assign to purchase order.')
    }
  },

  async addProposedOrderAsLineItem({ commit, state }, order: PlannerProposedOrdersDtoV3) {
    const lastLineItem = state.poLineItems.length ? state.poLineItems[state.poLineItems.length - 1] : null
    const orderProduct = order.product!
    const vendorProduct = orderProduct.vendorProducts!.find(vp => vp.vendorId === state.purchaseOrder.vendorId)
    const lineItem = PurchaseOrderLineItemDto.create({
      shipToLocation: lastLineItem?.shipToLocation,
      qtyOrdered: order.forecastOrders,
      vendorProduct: vendorProduct,
      vendorProductId: vendorProduct?.id,
      unitPrice: orderProduct.unitCost,
      vendorSku: vendorProduct?.vendorSku,
      productDescription: orderProduct.description,

    })
    commit('addLineItem', lineItem)
    commit('assignVendorProduct', { lineItem, vendorProduct, qty: order.forecastOrders })
    commit('updateLineItemTotals')
  },

  updateOrderDate({ commit }, date: Date) {
    commit('setOrderDate', date)
  },
}

const updateEstimatedReceiptDate = function(state: PurchaseOrderState) {
  if (state.poLineItems.length) {
    if (! state.forcedEstimatedReceiptDate) {
      const maxLeadTime = Math.max(...state.poLineItems.map(poli => poli.vendorProduct?.purchaseLeadTime || 0))
      state.purchaseOrder.estimatedReceiptDate = dateAdd(state.purchaseOrder.orderDate!, { days: maxLeadTime + 10 })
    } else {
      state.purchaseOrder.estimatedReceiptDate = state.forcedEstimatedReceiptDate
    }
    if (!state.requestedShipDateSet) {
      state.purchaseOrder.requestedShipDate = state.purchaseOrder.estimatedReceiptDate
    }
  }
}

const recalculateTotals = function(state: PurchaseOrderState) {
  const totalAdjustments = state.poPriceAdjs.reduce((prev, curr) => prev + curr.adjustmentAmount, 0)
    state.purchaseOrder.totalQuantity = state.poLineItems.reduce((prev, curr) => prev + (curr.qtyOrdered || 0), 0)
    state.purchaseOrder.totalAmount = state.poLineItems.reduce((prev, curr) => prev + (curr.qtyOrdered || 0) * curr.unitPrice, 0) + state.purchaseOrder.shippingAmount + totalAdjustments
    Vue.set(state, 'purchaseOrder', {...state.purchaseOrder})
}

const mutations: MutationTree<PurchaseOrderState> = {

  setShippingMethods(state, payload: ShippingMethodDto[]) {
    state.shippingMethods = payload
  },

  startNewPurchaseOrder(state, payload?: {product: ProductDto; month: number; year: number; ppoid: number}) {
    if (payload && payload.month >= 0 && payload.year >= 0) {
      state.forcedEstimatedReceiptDate = new Date(payload.year, payload.month, 1)
    }
    state.initialProduct = payload?.product
    const vendorProduct = payload?.product.getPrimaryVendorProduct()
    const vendor = state.vendors.find(v => v.id === vendorProduct?.vendorId)
    if (vendorProduct) {
      vendorProduct.vendor = vendor
    }
    const vendorLocationsLength = vendorProduct && vendorProduct.vendor && vendorProduct.vendor.vendorLocations ? vendorProduct.vendor.vendorLocations.length : 0

    state.purchaseOrder = PurchaseOrderDto.create({
      orderDate: new Date(),
      vendor: vendorProduct?.vendor,
      vendorId: vendorProduct?.vendorId,
      vendorNotes: vendorProduct?.vendor?.notes,
      shipFromLocation: vendorLocationsLength > 0 ? vendorProduct!.vendor!.vendorLocations![0] : undefined,
      shipFromLocationId: vendorLocationsLength > 0 ? vendorProduct!.vendor!.vendorLocations![0].id : undefined,
      proposedOrderDate: state.forcedEstimatedReceiptDate,
      shippingMethodId: state.defaultShippingMethodId,
    })
    state.purchaseOrder.plannerProposedOrderId = payload ? payload.ppoid : 0;
    state.poLineItems = []
    state.originalPurchaseOrder = _.cloneDeep(state.purchaseOrder)
    state.requestedShipDateSet = false
    state.poDirty = false
  },

  setRevertState(state) {
    state.originalPurchaseOrder = _.cloneDeep(state.purchaseOrder)
  },

  async setDefaultShippingMethodId(state) {
    const tbdShippingMethod = state.shippingMethods.find(sm => sm.name.toLowerCase() === 'tbd')
    if (tbdShippingMethod) {
      state.defaultShippingMethodId = tbdShippingMethod.id
    } else {
      const priorityShippingMethod = state.shippingMethods.find(sm => sm.priority === 1)
      if (priorityShippingMethod) {
        state.defaultShippingMethodId = priorityShippingMethod.id
      } else {
        const migratedShippingMethod = state.shippingMethods.find(sm => sm.name.toLowerCase() === 'migrated')
        if (migratedShippingMethod) {
          state.defaultShippingMethodId = migratedShippingMethod.id
        }
      }
    }
  },

  setPurchaseOrder(state, payload: PurchaseOrderDto) {
    state.purchaseOrder = payload
    state.poLineItems = _.cloneDeep(payload.purchaseOrderLineItems)
    state.poPriceAdjs = _.cloneDeep(payload.purchaseOrderPriceAdjustments)
    state.originalPurchaseOrder = _.cloneDeep(payload)
    state.purchaseOrderLoadError = ''
    state.requestedShipDateSet = !!payload.requestedShipDate
    state.poDirty = false
  },

  setPurchaseOrderLoadError(state, error: string) {
    state.purchaseOrderLoadError = error
  },

  clearPurchaseOrderLoadError(state) {
    state.purchaseOrderLoadError = ''
  },

  revertPurchaseOrder(state) {
    if (state.originalPurchaseOrder) {
      state.purchaseOrder = state.originalPurchaseOrder
      state.poLineItems = _.cloneDeep(state.originalPurchaseOrder.purchaseOrderLineItems)
      state.poPriceAdjs = _.cloneDeep(state.originalPurchaseOrder.purchaseOrderPriceAdjustments)
      state.originalPurchaseOrder = _.cloneDeep(state.purchaseOrder)
      state.poDirty = false
    }
  },

  setVendors(state, payload: VendorDto[]) {
    state.vendors = payload
  },

  updateVendor(state, payload: VendorDto) {
    state.purchaseOrder.vendor = payload
    state.purchaseOrder.vendorId = payload.id
    state.poDirty = true
    Vue.set(state, 'purchaseOrder', {...state.purchaseOrder})
  },

  updateVendorLocation(state, payload: VendorLocationDto) {
    if (payload) {
      state.purchaseOrder.shipFromLocation = payload
      state.purchaseOrder.shipFromLocationId = payload.id
    } else {
      state.purchaseOrder.shipFromLocation = undefined
      state.purchaseOrder.shipFromLocationId = -1
    }
    state.poDirty = true
    Vue.set(state, 'purchaseOrder', {...state.purchaseOrder})
  },

  updateLineItemTotals(state) {
    recalculateTotals(state)
  },

  setInventoryLocations(state, payload: InventoryLocationDto[]) {
    state.inventoryLocations = payload
  },

  toggleLoadingPurchaseOrder(state) {
    state.loadingPurchaseOrder = !state.loadingPurchaseOrder
  },

  toggleLoadingVendors(state) {
    state.loadingVendors = !state.loadingVendors
  },

  toggleLoadingInventoryLocations(state) {
    state.loadingInventoryLocations = !state.loadingInventoryLocations
  },

  toggleSavingInventoryLocation(state) {
    state.savingInventoryLocation = !state.savingInventoryLocation
  },

  toggleLoadingAllProducts(state) {
    state.loadingProducts = !state.loadingProducts
  },

  toggleLoadingVendorProducts(state) {
    state.loadingVendorProducts = !state.loadingVendorProducts
  },

  toggleSavingPurchaseOrder(state) {
    state.savingPurchaseOrder = !state.savingPurchaseOrder
  },

  toggleLoadingPdf(state) {
    state.loadingPdf = !state.loadingPdf
  },

  toggleLoadingProposedOrders(state) {
    state.loadingProposedOrders = !state.loadingProposedOrders
  },

  toggleSavingVendorLocation(state) {
    state.savingVendorLocation = !state.savingVendorLocation
  },

  updateBillToLocation(state, payload: InventoryLocationDto) {
    state.purchaseOrder.billToLocation = payload
    state.purchaseOrder.billToLocationId = payload.id
    state.poDirty = true
    Vue.set(state, 'purchaseOrder', {...state.purchaseOrder})
  },

  setAllProducts(state, payload: ProductDto[]) {
    state.allProducts = payload
  },

  setVendorProducts(state, payload: VendorProductDto[]) {
    state.vendorProducts = payload
  },

  setOrderDate(state, payload: Date) {
    state.purchaseOrder.orderDate = payload
    updateEstimatedReceiptDate(state)
    state.poDirty = true
  },

  updateEstimatedReceiptDate() {
    updateEstimatedReceiptDate(state)
  },

  assignVendorProduct(state, payload: {lineItem: PurchaseOrderLineItemDto; vendorProduct: VendorProductDto; qty: number}) {
    const index = state.poLineItems.findIndex(poli => poli === payload.lineItem)
    if (index >= 0) {
      const lineItem = state.poLineItems[index]
      lineItem.vendorProduct = payload.vendorProduct
      lineItem.vendorProductId = payload.vendorProduct.id
      lineItem.qtyOrdered = Math.max(lineItem.vendorProduct.poLineItemMinOrderQty, payload.qty)
      lineItem.unitPrice = lineItem.vendorProduct.contractedPrice
      state.poLineItems.splice(index, 1, lineItem) // trigger vue update
      updateEstimatedReceiptDate(state)
      state.poDirty = true
      Vue.set(state, 'purchaseOrder', {...state.purchaseOrder}) // updates est receipt date.
    }
  },

  addLineItem(state, payload?: PurchaseOrderLineItemDto) {
    const lastLineItem = state.poLineItems.length ? state.poLineItems[state.poLineItems.length - 1] : null
    //const lineItem = payload || PurchaseOrderLineItemDto.create()
    const lineItem = PurchaseOrderLineItemDto.create()
    lineItem.shipToLocation = lastLineItem?.shipToLocation
    state.poLineItems.push(lineItem)
    state.poDirty = true
  },

  deleteLineItem(state, index: number) {
    state.poLineItems.splice(index, 1)
    state.poDirty = true
    recalculateTotals(state)
  },

  assignShipToLocationToLineItem(state, payload: {lineItem: PurchaseOrderLineItemDto; location: InventoryLocationDto}) {
    const index = state.poLineItems.findIndex(poli => poli === payload.lineItem)
    if (index >= 0) {
      const item = state.poLineItems[index]
      item.shipToLocation = payload.location
      state.poLineItems.splice(index, 1, item)
      state.poDirty = true
    }
  },

  addPriceAdjustment(state) {
    state.poPriceAdjs.push(PurchaseOrderPriceAdjustmentDto.create({
      purchaseOrderId: state.purchaseOrder.id,
    }))
    recalculateTotals(state)
    state.poDirty = true
  },

  deletePriceAdjustment(state, index: number) {
    state.poPriceAdjs.splice(index, 1)
    recalculateTotals(state)
    state.poDirty = true
  },

  setRequestedShipDateModified(state, payload: boolean) {
    state.requestedShipDateSet = payload
    state.poDirty = true
  },

  setPoDirty(state) {
    state.poDirty = true
  },

  setPoClean(state) {
    state.poDirty = false
  },

  prependHistoryRecord(state, payload: PurchaseOrderHistoryDto) {
    state.purchaseOrder.purchaseOrderHistories.unshift(payload)
  },

  setProposedOrders(state, payload: PlannerProposedOrdersDtoV3[]) {
    state.proposedOrders = payload
  },

  setForcedEstimatedReceiptDate(state, payload: Date) {
    state.forcedEstimatedReceiptDate = payload
  }
}

const namespaced = true

export const purchaseOrderStore: Module<PurchaseOrderState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
