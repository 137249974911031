import { BaseHttpService } from '@/services/base-http-service'
import { ImportDto } from '@basic-code/shared'

class ImportService extends BaseHttpService {
    public async getImport(id: number): Promise<ImportDto> {
        const uri = `/imports/${id}`
        const response = await this.doApiGet<ImportDto>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getImports(includeDisabled = false, updateDataImports = false): Promise<ImportDto[]> {
        const uri = `/imports?includeDisabled=${includeDisabled}&updateDataImports=${updateDataImports}`
        const response = await this.doApiGet<ImportDto[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new ImportService()
