import { BaseHttpService } from '@/services/base-http-service'
import { DataGroupDataImportRunLogDto, PaginationResult } from '@basic-code/shared'

class DataGroupDataImportRunLogService extends BaseHttpService {
    public async getErrorLogs(dataImportId: number, dataGroupId: number, page = 1, pageSize = 25): Promise<PaginationResult<DataGroupDataImportRunLogDto[]>> {
        const queryString = dataImportId !== -1 && dataGroupId !== -1 ?
            `?dataImportId=${dataImportId}&dataGroupId=${dataGroupId}` :
            dataImportId !== -1 ?
                `?dataImportId=${dataImportId}` :
                `?dataGroupId=${dataGroupId}`
        const uri = `/data-import-run-logs${queryString}&page=${page}&pageSize=${pageSize}`
        const response = await this.doApiGet<PaginationResult<DataGroupDataImportRunLogDto[]>>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new DataGroupDataImportRunLogService()
