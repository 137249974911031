


























import { Vue, Component, Prop } from 'vue-property-decorator'
import { EventBus, SafioEvents } from '@/services/event-bus'
import { ProductFieldType, ProductFilterField, ProductFilterOperation } from '@basic-code/shared'
import { FilterChangeEventData } from '@/types'

@Component
export default class HeaderStatus extends Vue {
  currentDate: Date = new Date()

  @Prop({ default: '' })
  reviewedAt: Date|string

  @Prop({ default: '' })
  dateCompleted: Date|string

  isDateReviewedFiltered  = false

  addAttributeFilter() {
    if (this.reviewedAt !== 'N/A') {
      const datePartReviewedAt = (typeof this.reviewedAt === 'string' ? new Date(this.reviewedAt) : this.reviewedAt) as Date
      this.isDateReviewedFiltered = true
      EventBus.$emit(SafioEvents.filterAdd, {
        field: ProductFilterField.dateReviewed,
        operation: ProductFilterOperation.equal,
        options: [],
        fieldType: ProductFieldType.Date,
        value: datePartReviewedAt
      })
    }
  }

  created() {
    EventBus.$on(SafioEvents.filterChanged, this.onFilterChanged)
  }

  beforeDestroy() {
    EventBus.$off(SafioEvents.filterChanged, this.onFilterChanged)
  }

  onFilterChanged(filterChangeData: FilterChangeEventData) {
    const prop = filterChangeData.filterProperties.find(f => f === ProductFilterField.dateReviewed)
    if(prop === undefined) {
      this.isDateReviewedFiltered = false
    } else {
      this.isDateReviewedFiltered = true
    }
  }
}
