import SystemSettingsService from '@/services/v3/system-settings-service'
import DtoFactory from '@/util/dto-factory'
import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { forecastEdit } from './modules/forecast-edit'
import { purchaseOrderStore } from './modules/purchase-order'
import { setupStore } from '@/store/modules/setup'
import { worksheetStore } from './modules/worksheet'
import { bomStore } from '@/store/modules/bom'
import { errorsStore } from '@/store/modules/data-import-run-time-errors'
import { RootState } from './types'

Vue.use(Vuex)

const store: StoreOptions<RootState> = {
  modules: {
    forecastEdit,
    purchaseOrderStore,
    setupStore,
    worksheetStore,
    bomStore,
    errorsStore,
  },
  state: {
    settings: DtoFactory.blankSettings(),
  },
  getters: {
    settings(state) {
      return state.settings
    },
  },
  mutations: {
    setSettings (state, newSettings) {
      state.settings = newSettings
    }
  },
  actions: {
    async getSettings ({ commit }) {
      try {
        const settings = await SystemSettingsService.getSystemSettings()
        commit('setSettings', settings)
      } catch (err) {
        console.error(err)
      }

    }
  }
}

export default new Vuex.Store(store)