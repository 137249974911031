import { BaseHttpService } from '@/services/base-http-service'
import { BillOfMaterialDto } from '@basic-code/shared'

class BomService extends BaseHttpService {
    public async addBom(productId: number, componentProductId: number, componentQuantity: number): Promise<BillOfMaterialDto> {
        const uri = `/products/${productId}/bill-of-materials/${componentProductId}`
        const response = await this.doApiPost<BillOfMaterialDto>(uri, JSON.stringify({ componentQuantity: componentQuantity }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateBom(productId: number, bomId: number, componentQuantity: number): Promise<BillOfMaterialDto> {
        const uri = `/products/${productId}/bill-of-materials/${bomId}`
        const response = await this.doApiPut<BillOfMaterialDto>(uri, JSON.stringify({ componentQuantity: componentQuantity }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async deleteBom(productId: number, bomId?: number): Promise<boolean> {
        let uri = ''

        if (!bomId) {
            uri = `/products/${productId}/bill-of-materials`
        } else {
            uri = `/products/${productId}/bill-of-materials/${bomId}`
        }

        const response = await this.doApiDelete(uri)
        return !!response
    }
}

export default new BomService()
