import { BaseHttpService } from '@/services/base-http-service'
import { ProductAttributeValueDto } from '@basic-code/shared'

class ProductAttributeValuesService extends BaseHttpService {
    public async updateProductAttributeValue(id: number, productAttributeValue: Partial<ProductAttributeValueDto>, productAttributeId: number|null = null): Promise<ProductAttributeValueDto> {
        const uri = `/product-attribute-values/${id}`
        const response = await this.doApiPut<ProductAttributeValueDto>(uri, JSON.stringify({ productAttributeValue: productAttributeValue, productAttributeId: productAttributeId }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new ProductAttributeValuesService()
