import { VueAuth } from '@/auth/vue-auth'
import ApiService, { ApiResponse } from './api-service'
import { RawRule } from '@/types'
import { Ability } from '@casl/ability'
import downloadjs from 'downloadjs'

export class BaseHttpService {

  private _auth?: VueAuth


  public set $auth(value: VueAuth) {
    this._auth = value
  }

  public async isAuthenticated(): Promise<boolean> {
    if (!this._auth?.isAuthenticated) {
      return false
    } else if (! ApiService.accessToken) {
      await this._auth!.sync()
    }
    return ApiService.accessToken !== undefined
  }

  protected buildQueryStringFromObject(params: {[index: string]: any}): string {
    const values: string[] = []
    for (const key of Object.keys(params)) {
      values.push(`${key}=${params[key].toString()}`)
    }
    return values.join('&')
  }

  protected async handleResponse<T>(response: Response): Promise<ApiResponse<T>> {
    const result: ApiResponse<T> = {
      success: true
    }
    if (response.status === 204) {
      result.response = undefined
      return result
    }
    const json = await response.json()
    if (response.status >= 400) {
      result.success = false
      json.status = response.status
      if (response.status === 404 && json.code === 'E_MODEL_NOT_FOUND') {
        json.message = json.message.replace('E_MODEL_NOT_FOUND: ', '') + ' not found'
      }
      result.error = json
    } else {
      result.response = json
    }
    return result
  }

  protected async doApiGet<T>(uri: string): Promise<ApiResponse<T>> {
    return this.doApiCall('GET', uri)
  }

  protected async doApiPut<T>(uri: string, body?: string | Blob | ArrayBufferView | ArrayBuffer | FormData | URLSearchParams | ReadableStream<Uint8Array> | null | undefined): Promise<ApiResponse<T>> {
    return this.doApiCall('PUT', uri, body)
  }

  protected async doApiPost<T>(uri: string, body?: string | Blob | ArrayBufferView | ArrayBuffer | FormData | URLSearchParams | ReadableStream<Uint8Array> | null | undefined): Promise<ApiResponse<T>> {
    return this.doApiCall('POST', uri, body)
  }

  protected async doApiDelete<T>(uri: string): Promise<ApiResponse<T>> {
    return this.doApiCall('DELETE', uri)
  }

  protected async doApiCall<T>(method: string, uri: string, body?: string | Blob | ArrayBufferView | ArrayBuffer | FormData | URLSearchParams | ReadableStream<Uint8Array> | null | undefined): Promise<ApiResponse<T>> {
    console.log('api', method, 'call', uri)
    const isAuthenticated = await this.isAuthenticated()

    if (! isAuthenticated) {
      return { success: false, error: { code: 'unauthorized', message: 'unathorized', status: 401 }}
    } else if (! ApiService.tenant) {
      return { success: false, error: { code: 'no-tenant', message: 'no tenant is specified', status: 400 }}
    }
    const headers = new Headers()
    headers.append('Authorization', ApiService.accessToken || '')
    headers.append('Content-type', 'application/json')
    const request = new Request(`${ApiService.tenantApiUrl}${uri}`, {
      method: method,
      headers,
      body
    })

    const response = await fetch(request)
    return await this.handleResponse(response)
  }

  protected async doFileUpload<T>(method: string, uri: string, body?: string | Blob | ArrayBufferView | ArrayBuffer | FormData | URLSearchParams | ReadableStream<Uint8Array> | null | undefined): Promise<ApiResponse<T>> {
    console.log('api', method, 'call', uri)
    const isAuthenticated = await this.isAuthenticated()
    if (! isAuthenticated) {
      return { success: false, error: { code: 'unauthorized', message: 'unathorized', status: 401 }}
    } else if (! ApiService.tenant) {
      return { success: false, error: { code: 'no-tenant', message: 'no tenant is specified', status: 400 }}
    }
    const headers = new Headers()
    headers.append('Authorization', ApiService.accessToken || '')
    const request = new Request(`${ApiService.tenantApiUrl}${uri}`, {
      method: method,
      headers,
      body
    })
    const response = await fetch(request)
    return await this.handleResponse(response)
  }

  public async download(uri: string, mimeType?: string): Promise<ApiResponse<boolean>> {
    const isAuthenticated = await this.isAuthenticated()
    if (! isAuthenticated) {
      return { success: false, error: { code: 'unauthorized', message: 'unathorized', status: 401 }}
    } else if (! ApiService.tenant) {
      return { success: false, error: { code: 'no-tenant', message: 'no tenant is specified', status: 400 }}
    }
    const headers = new Headers()
    headers.append('Authorization', ApiService.accessToken || '')
    headers.append('Content-type', mimeType || 'application/json')
    const request = new Request(`${ApiService.tenantApiUrl}${uri}`, {
      method: 'GET',
      headers,
    })
    const response = await fetch(request)
    if (response.status >= 400) {
      return {
        success: false,
        error: {
          code: '',
          message: await response.text(),
          status: response.status
        }
      }
    } else {
      const csv = await response.text()
      const disposition = response.headers.get('content-disposition')
      const filename = disposition ? disposition.split('=')[1].replace(/"/g, '') : 'download.csv'
      downloadjs(csv, filename, response.headers.get('content-type')?.split(';')[0])
      return {
        success: true,
      }
    }
  }

  public async downloadBinary(uri: string, mimeType: string): Promise<ApiResponse<boolean>> {
    const isAuthenticated = await this.isAuthenticated()
    if (! isAuthenticated) {
      return { success: false, error: { code: 'unauthorized', message: 'unathorized', status: 401 }}
    } else if (! ApiService.tenant) {
      return { success: false, error: { code: 'no-tenant', message: 'no tenant is specified', status: 400 }}
    }
    const headers = new Headers()
    headers.append('Authorization', ApiService.accessToken || '')
    headers.append('Content-type', mimeType)
    const request = new Request(`${ApiService.tenantApiUrl}${uri}`, {
      method: 'GET',
      headers,
    })
    const response = await fetch(request)
    if (response.status >= 400) {
      return {
        success: false,
        error: {
          code: '',
          message: await response.text(),
          status: response.status
        }
      }
    } else {
      const buffer = await response.arrayBuffer()
      const blob = new Blob([buffer], { type: mimeType})
      const disposition = response.headers.get('content-disposition')
      const filename = disposition ? disposition.split('=')[1].replace(/"/g, '') : 'download.csv'
      downloadjs(blob, filename, response.headers.get('content-type')?.split(';')[0])
      return {
        success: true,
      }
    }
  }

  public async getPermissionRules(): Promise<RawRule[]> {
    const uri = '/auth/rules'
    const response = await this.doApiGet<RawRule[]>(uri)
    if (response.success) {
      return response.response!
    }
    throw new Error(response.error!.message)
  }

  public async getAbilities(): Promise<Ability> {
    const rules = await this.getPermissionRules()
    return new Ability(rules)
  }
}
