import { BaseHttpService } from '@/services/base-http-service'
import { ShippingMethodDto } from '@basic-code/shared'
import { FormAddEditShippingMethodDto } from '@/types'

class ShippingMethodService extends BaseHttpService {
    public async getShippingMethod(id: number): Promise<ShippingMethodDto> {
        const uri = `/shipping-methods/${id}`
        const response = await this.doApiGet<ShippingMethodDto>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getShippingMethods(): Promise<ShippingMethodDto[]> {
        const uri = '/shipping-methods'
        const response = await this.doApiGet<ShippingMethodDto[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async createShippingMethod(shippingMethod: FormAddEditShippingMethodDto): Promise<ShippingMethodDto> {
        const uri = '/shipping-methods'
        const response = await this.doApiPost<ShippingMethodDto>(uri, JSON.stringify({ shippingMethod: shippingMethod }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateShippingMethod(id: number, shippingMethod: Partial<ShippingMethodDto>, oldPriority = -1): Promise<ShippingMethodDto> {
        const uri = `/shipping-methods/${id}`
        const response = await this.doApiPut<ShippingMethodDto>(uri, JSON.stringify({ shippingMethod: shippingMethod, oldPriority: oldPriority }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async deleteShippingMethod(id: number): Promise<{ response: any; success: boolean; message: string }> {
        const uri = `/shipping-methods/${id}`
        const response = await this.doApiDelete(uri)
        if (response.success) {
            return response.response! as any
        }
        throw new Error(response.error!.message)
    }
}

export default new ShippingMethodService()
