import { ForecastEditFilterData, PlannerForecastEdit, ForecastTotalDisplayDto, AccountForecastEdit,
  PaginationResult, ForecastFilter, ProductDto, CurveDto, SalesRepDtoV3, FlowType } from '@basic-code/shared'
import { BaseHttpService } from './base-http-service'
import { ApiResponse } from './api-service'

class ForecastEditService extends BaseHttpService {

  public async getForecastEditFilterData() {
    const uri = `/search/forecasts`
    const response = await this.doApiGet<ForecastEditFilterData>(uri)
    if (response.success) {
      return response.response!
    }
    throw new Error(response.error!.message)
  }

  /**
   *
   * @param editor - contains additional configuration data for the editor such as the id is a sku, product, etc
   * @param filter
   */
  public async getEditorData(editorType: string, filter: ForecastFilter, isPlannerWithPagination = false, flowType: FlowType|null = null): Promise<PlannerForecastEdit|PaginationResult<PlannerForecastEdit[]>|PaginationResult<AccountForecastEdit[]>> {
    let uri = ''
    uri = `/editors/${encodeURIComponent(editorType)}?isPlannerWithPagination=${isPlannerWithPagination}&flowType=${flowType}`
    const response: ApiResponse<PlannerForecastEdit|PaginationResult<AccountForecastEdit[]>> = await this.doApiPost(uri, JSON.stringify({ filter }))
    
    if (!response.success) {
      throw new Error(response.error!.message)
    } else {
      let result: PlannerForecastEdit | PaginationResult<PlannerForecastEdit[]> | PaginationResult<AccountForecastEdit[]>
      if (editorType === 'planner') {
        if (isPlannerWithPagination) {
          result = response.response! as unknown as PaginationResult<PlannerForecastEdit[]>
          for (const res of result.data) {
            res.product = ProductDto.create(res.product)
          }
        } else {
          result = response.response! as unknown as PlannerForecastEdit
          result.product = ProductDto.create(result.product)
        }
      } else {
        result = response.response! as unknown as PaginationResult<AccountForecastEdit[]>
        for (const afe of result.data) {
          afe.curve = CurveDto.create(afe.curve)
        }
      }
      return result
    }
  }

  public async getProductsForProductGroup(productGroupId: number): Promise<ProductDto[]> {
    const uri = `/product-groups/${productGroupId}/products?autocomplete=true`
    const response = await this.doApiGet<ProductDto[]>(uri)
    if (response.success) {
      return response.response!
    }
    throw new Error(response.error!.message)
  }

  public async getAggregateRollupData(type: string, filter: ForecastFilter): Promise<ForecastTotalDisplayDto> {
    const uri = `/rollup/${encodeURIComponent(type)}`
    const response = await this.doApiPost<ForecastTotalDisplayDto>(uri, JSON.stringify({filter}))
    if (response.success) {
      return response.response!
    }
    throw new Error(response.error!.message)
  }

  public async createAccountForecast(accountForecastEdit: AccountForecastEdit, replaceProjectedForecastForNewForecasts: boolean): Promise<object|{ id: number; updatedReplaceProjectedForecast: boolean }> {
    const uri = '/forecasts'
    const response = await this.doApiPost<{ id: number; updatedReplaceProjectedForecast: boolean }>(uri, JSON.stringify({ accountForecastEdit: accountForecastEdit, replaceProjectedForecastForNewForecasts: replaceProjectedForecastForNewForecasts }))
    if (response.success) {
      return response.response!
    }
    throw new Error(response.error?.message || 'error saving forecast')
  }

  public async updateAccountForecast(accountForecastEdit: AccountForecastEdit, replaceProjectedForecastForNewForecasts: boolean): Promise<object|{ success: boolean; updatedReplaceProjectedForecast: boolean }> {
    const uri = `/forecasts/${accountForecastEdit.id!}`
    const response = await this.doApiPut<{ success: boolean; updatedReplaceProjectedForecast: boolean }>(uri, JSON.stringify({ accountForecastEdit: accountForecastEdit, replaceProjectedForecastForNewForecasts: replaceProjectedForecastForNewForecasts }))
    if (response.success) {
      return response.response!
    }
    throw new Error(response.error?.message || 'error saving forecast')
  }

  public async updatePlannerForecast(plannerForecast: PlannerForecastEdit): Promise<boolean> {
    const uri = `/planner-forecasts`
    const response = await this.doApiPut<boolean>(uri, JSON.stringify(plannerForecast))
    if (response.success) {
      return response.response!
    }
    throw new Error(response.error!.message)
  }

  public async deleteProductAccountForecast(productAccountForecastId: number) {
    const uri = `/forecasts/${productAccountForecastId}`
    const response = await this.doApiDelete<boolean>(uri)
    if (response.success) {
      return response.response!
    }
    throw new Error(response.error!.message)
  }

  public async getSalesRepsForProductAndAccountClass (productId: number, accountClassId: number): Promise<SalesRepDtoV3[]|undefined> {
    const uri = `/sales-reps?product_id=${productId}&account_class_id=${accountClassId}`
    const response = await this.doApiGet<SalesRepDtoV3[]|undefined>(uri)
    if (response.success) {
      return response.response!
    }
    throw new Error(response.error!.message)
  }

  // public async getSkuAccountsWithoutForecasts(sku: string, salesRepId: number|null, fiscalStartMonth: number, page = 1, perPage = 10): Promise<PaginationResult<SkuAccounts[]>> {
  //   const pagination: PaginationRequest = {
  //     perPage: perPage,
  //     page: page,
  //   }
  //   const uri = `/filtering/sku-accounts-no-forecasts?pagination=${JSON.stringify(pagination)}&fiscalStartMonth=${fiscalStartMonth}${sku ? `&sku=${sku}` : ''}${salesRepId ? `&salesRepId=${salesRepId}` : ''}`
  //   const response = await this.doApiGet<PaginationResult<SkuAccounts[]>>(uri)
  //   if (response.success) {
  //     return response.response!
  //   }
  //   throw new Error(response.error!.message)
  // }

  public async getNewAccountForecast(productId: number): Promise<AccountForecastEdit> {
    const uri = `/forecasts/account/${productId}/new-forecast`
    const response = await this.doApiGet<AccountForecastEdit>(uri)
    if (response.success) {
      response.response!.curve = CurveDto.create(response.response!.curve)
      return response.response!
    }
    throw new Error(response.error!.message)
  }

  public async getNewSkuForecast(accountId: number): Promise<AccountForecastEdit> {
    const uri = `/forecasts/product/${accountId}/new-forecast`
    const response = await this.doApiGet<AccountForecastEdit>(uri)
    if (response.success) {
      response.response!.curve = CurveDto.create(response.response!.curve)
      return response.response!
    }
    throw new Error(response.error?.message || 'unknown error')
  }

  public async isNewAccountForecast(productId: number, accountId: number, accountClassId: number): Promise<boolean> {
    const uri = `/forecasts/is-new?productId=${productId}&accountId=${accountId}&accountClassId=${accountClassId}`
    const response = await this.doApiGet<boolean>(uri)
    if (response.success) {
      return response.response!
    }
    throw new Error(response.error!.message)
  }
}

export default new ForecastEditService()
