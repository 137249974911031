import {ItemTotalType, MonthMap} from "@/types";
import {
    ForecastSummaryDto,
    ParentBillOfMaterialInfoDto, PlannerForecastDto,
    PlannerForecastEdit,
    PlannerForecastYear
} from "@basic-code/shared";

export default class CommonForecastHelper {

    public static hasReachedYtdTotal(totalType: ItemTotalType, month: number): boolean {
        return totalType === ItemTotalType.ytd && month === new Date().getUTCMonth();
    }

    public static isMonthInThePast(calendarYear: string | number, month: number | string): boolean {
        const currentMonth = new Date().getUTCMonth();
        const currentYear = new Date().getFullYear();
        return Number(calendarYear) === currentYear && Number(month) < currentMonth
    }

    public static getParentBomForecastTotalObjects(parentForecastBoms: ParentBillOfMaterialInfoDto[]): PlannerForecastEdit {
        const parentForecastTotals = PlannerForecastEdit.create()


        // ly shipments
        parentForecastTotals.lastYearForecast.adjustmentTotal = 0
        parentForecastTotals.lastYearForecast.flowTotal = 0
        parentForecastTotals.lastYearForecast.shipmentTotal = 0
        parentForecastTotals.lastYearForecast.usageTotal = 0
        parentForecastTotals.lastYearForecast.forecastMonths = []

        parentForecastBoms.forEach(function (parentForecastBom, index) {
            const plannerForecast = parentForecastBom.plannerForecast

            plannerForecast.lastYearForecast.forecastMonths.forEach(function (pfMonth, pfIndex) {
                if (index === 0) {
                    const forecastMonth = {
                        id: pfIndex,
                        productId: pfIndex,
                        calendarYear: pfMonth.calendarYear,
                        fiscalYear: pfMonth.fiscalYear,
                        month: pfMonth.month,
                        monthAbbr: pfMonth.monthAbbr,
                        flowAmount: pfMonth.flowAmount * parentForecastBom.componentQuantity,
                        adjustmentAmount: pfMonth.adjustmentAmount * parentForecastBom.componentQuantity,
                        usageAmount: pfMonth.usageAmount | 0,
                        shipmentAmount: parentForecastBom.product!.replaceProjectedForecast
                            ? parentForecastBom.accountShipments[parentForecastBom.accountShipments.length-1].items[pfMonth.month].value  * parentForecastBom.componentQuantity
                            : pfMonth.shipmentAmount!  * parentForecastBom.componentQuantity,
                        updatedBy: pfMonth.updatedBy,
                        forecastDate: pfMonth.forecastDate,
                        curveAmount: pfMonth.curveAmount,
                    }

                    parentForecastTotals.lastYearForecast.forecastMonths.push(forecastMonth)
                } else {
                    parentForecastTotals.lastYearForecast.forecastMonths[pfIndex].adjustmentAmount += pfMonth.adjustmentAmount * parentForecastBom.componentQuantity
                    parentForecastTotals.lastYearForecast.forecastMonths[pfIndex].flowAmount += pfMonth.flowAmount * parentForecastBom.componentQuantity
                    parentForecastTotals.lastYearForecast.forecastMonths[pfIndex].shipmentAmount! += parentForecastBom.product!.replaceProjectedForecast ? parentForecastBom.accountShipments[parentForecastBom.accountShipments.length-1].items[pfMonth.month].value * parentForecastBom.componentQuantity : pfMonth.shipmentAmount! * parentForecastBom.componentQuantity
                    parentForecastTotals.lastYearForecast.forecastMonths[pfIndex].usageAmount += pfMonth.usageAmount | 0
                }

                parentForecastTotals.lastYearForecast.adjustmentTotal += pfMonth.adjustmentAmount * parentForecastBom.componentQuantity
                parentForecastTotals.lastYearForecast.flowTotal += pfMonth.flowAmount * parentForecastBom.componentQuantity
                parentForecastTotals.lastYearForecast.shipmentTotal += parentForecastBom.product!.replaceProjectedForecast ? parentForecastBom.accountShipments[parentForecastBom.accountShipments.length-1].items[pfMonth.month].value * parentForecastBom.componentQuantity : pfMonth.shipmentAmount! * parentForecastBom.componentQuantity
                parentForecastTotals.lastYearForecast.usageTotal += pfMonth.usageAmount | 0
            })

            plannerForecast.forecast.forEach(function (pfYear, yearIndex) {
                if (index === 0) {
                    const forecast: PlannerForecastYear = {
                        fiscalYear: pfYear.fiscalYear,
                        flowTotal: parentForecastBom.product!.replaceProjectedForecast ? pfYear.accountForecast.flowTotal * parentForecastBom.componentQuantity : pfYear.flowTotal * parentForecastBom.componentQuantity,
                        shipmentTotal: parentForecastBom.product!.replaceProjectedForecast ? parentForecastBom.accountShipments[yearIndex].total * parentForecastBom.componentQuantity : pfYear.shipmentTotal * parentForecastBom.componentQuantity,
                        adjustmentTotal: parentForecastBom.product!.replaceProjectedForecast ? pfYear.accountForecast.adjustmentTotal * parentForecastBom.componentQuantity : pfYear.adjustmentTotal * parentForecastBom.componentQuantity,
                        usageTotal: pfYear.usageTotal | 0,
                        originalForecast: ForecastSummaryDto.create(),
                        accountForecast: ForecastSummaryDto.create(),
                        forecastMonths: [],
                    }

                    if (parentForecastBom.product!.replaceProjectedForecast) {
                        pfYear.accountForecast.monthTotals.forEach(function (pfMonth, index) {
                            const forecastMonth: PlannerForecastDto = {
                                id: index,
                                productId: index,
                                calendarYear: pfMonth.calendarYear,
                                fiscalYear: pfYear.forecastMonths[index].fiscalYear,
                                month: pfYear.forecastMonths[index].month,
                                monthAbbr: pfYear.forecastMonths[index].monthAbbr,
                                flowAmount: pfMonth.flowTotal * parentForecastBom.componentQuantity,
                                adjustmentAmount: pfMonth.adjustmentTotal * parentForecastBom.componentQuantity,
                                usageAmount: pfYear.forecastMonths[index].usageAmount | 0,
                                shipmentAmount: parentForecastBom.accountShipments[yearIndex].items[pfMonth.month].value * parentForecastBom.componentQuantity,
                                updatedBy: pfYear.forecastMonths[index].updatedBy,
                                forecastDate: pfYear.forecastMonths[index].forecastDate,
                                curveAmount: pfYear.forecastMonths[index].curveAmount,
                            }

                            forecast.forecastMonths.push(forecastMonth)
                        })
                    } else {
                        pfYear.forecastMonths.forEach(function (pfMonth, index) {
                            const forecastMonth: PlannerForecastDto = {
                                id: index,
                                productId: index,
                                calendarYear: pfMonth.calendarYear,
                                fiscalYear: pfMonth.fiscalYear,
                                month: pfMonth.month,
                                monthAbbr: pfMonth.monthAbbr,
                                flowAmount: pfMonth.flowAmount * parentForecastBom.componentQuantity,
                                adjustmentAmount: pfMonth.adjustmentAmount * parentForecastBom.componentQuantity,
                                usageAmount: pfMonth.usageAmount | 0,
                                shipmentAmount: pfMonth.shipmentAmount! * parentForecastBom.componentQuantity,
                                updatedBy: pfMonth.updatedBy,
                                forecastDate: pfMonth.forecastDate,
                                curveAmount: pfMonth.curveAmount,
                            }

                            forecast.forecastMonths.push(forecastMonth)
                        })
                    }

                    parentForecastTotals.forecast.push(forecast)
                } else {
                    parentForecastTotals.forecast[yearIndex].flowTotal += (parentForecastBom.product!.replaceProjectedForecast ? pfYear.accountForecast.flowTotal * parentForecastBom.componentQuantity : pfYear.flowTotal * parentForecastBom.componentQuantity)
                    parentForecastTotals.forecast[yearIndex].shipmentTotal += parentForecastBom.product!.replaceProjectedForecast ? parentForecastBom.accountShipments[yearIndex].total * parentForecastBom.componentQuantity : pfYear.shipmentTotal * parentForecastBom.componentQuantity
                    parentForecastTotals.forecast[yearIndex].adjustmentTotal += (parentForecastBom.product!.replaceProjectedForecast ? pfYear.accountForecast.adjustmentTotal * parentForecastBom.componentQuantity : pfYear.adjustmentTotal * parentForecastBom.componentQuantity)
                    parentForecastTotals.forecast[yearIndex].usageTotal += pfYear.usageTotal | 0

                    if (parentForecastBom.product!.replaceProjectedForecast) {
                        pfYear.accountForecast.monthTotals.forEach(function (pfMonth, index) {
                            parentForecastTotals.forecast[yearIndex].forecastMonths[index].flowAmount += pfMonth.flowTotal * parentForecastBom.componentQuantity
                            parentForecastTotals.forecast[yearIndex].forecastMonths[index].adjustmentAmount += pfMonth.adjustmentTotal * parentForecastBom.componentQuantity
                            parentForecastTotals.forecast[yearIndex].forecastMonths[index].usageAmount += pfYear.forecastMonths[index].usageAmount | 0
                            parentForecastTotals.forecast[yearIndex].forecastMonths[index].shipmentAmount! += parentForecastBom.accountShipments[yearIndex].items[index].value * parentForecastBom.componentQuantity
                        })
                    } else {
                        pfYear.forecastMonths.forEach(function (pfMonth, index) {
                            parentForecastTotals.forecast[yearIndex].forecastMonths[index].flowAmount += pfMonth.flowAmount * parentForecastBom.componentQuantity
                            parentForecastTotals.forecast[yearIndex].forecastMonths[index].adjustmentAmount += pfMonth.adjustmentAmount * parentForecastBom.componentQuantity
                            parentForecastTotals.forecast[yearIndex].forecastMonths[index].usageAmount += pfMonth.usageAmount | 0
                            parentForecastTotals.forecast[yearIndex].forecastMonths[index].shipmentAmount! += pfMonth.shipmentAmount! * parentForecastBom.componentQuantity
                        })
                    }
                }
            })
        })

        return parentForecastTotals;
    }

}