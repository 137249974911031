/**
 * This class provides a common interface to get items of various types from any Storage mechanism supported by the browser.
 * Objects and arrays are purposely not supported in an attempt to discourage people from keeping large objects in
 * browser storage.
 */
class StorageAPI {
  private storage: Storage

  constructor(storage: Storage) {
    this.storage = storage
  }

  public set (key: string, value: string|boolean|number|null): string|boolean|number|null {
    if (value !== null) {
      this.storage.setItem(key, value!.toString())
    } else {
      this.storage.removeItem(key)
    }
    return value
  }

  public list (): string[] {
    const list: string[] = []
    const len = this.storage.length
    for (let i = 0; i < len; i++) {
      list.push(this.storage.key(i) as string)
    }
    return list
  }

  public get (key: string, defaultValue?: string): string|null {
    const val = this.storage.getItem(key)
    if (val === null) {
      return defaultValue || null
    }
    return val
  }

  public getNumber (key: string, defaultValue?: number): number|null {
    const val = this.storage.getItem(key)
    if (val !== null) {
      try {
        return parseInt(val)
      } catch (err) {
        return defaultValue !== undefined ? defaultValue : null
      }
    }
    return defaultValue !== undefined ? defaultValue : val
  }

  public getBoolean (key: string, defaultValue?: boolean): boolean|null {
    const val = this.storage.getItem(key)
    if (val !== null) {
      if (val === 'true') {
        return true
      } else if (val === 'false') {
        return false
      }
    }
    return defaultValue !== undefined ? defaultValue : null
  }

  public clear () {
    this.storage.clear()
  }
}

class StorageService {

  public readonly session: StorageAPI
  public readonly local: StorageAPI

  constructor() {
    this.session = new StorageAPI(sessionStorage)
    this.local = new StorageAPI(localStorage)
  }
}

export default new StorageService()