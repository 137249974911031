


















































































































import ApiService from "@/services/api-service";
import {
  EventBus,
  InterWindowEvents,
  SafioEvents,
  WindowBus
} from "@/services/event-bus";
import StorageService from "@/services/storage-service";
import ProductService from "@/services/v3/product-service";
import { WorksheetState } from "@/store/types";
import Common from "@/util/common";
import {
  PaginationResult,
  ProductAttributeDto,
  ProductDto,
  ProductFieldType,
  ProductFilter,
  ProductFilterField,
  ProductFilterOperation,
  TopAccountDto
} from "@basic-code/shared";
import { BFormInput, BFormSelect, BPopover, BSpinner } from "bootstrap-vue";
import _ from "lodash";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Mutation, State } from "vuex-class";
import WorksheetService from "../services/worksheet-service";
import { FilterChangeEventData, FilterSettings } from "../types";
import SkuAutoComplete from "./SkuAutoComplete.vue";
import SkuFilterRow from "./SkuFilterRow.vue";

const namespace = "worksheetStore";

@Component<SkuFilter>({
  components: {
    BPopover,
    BFormSelect,
    BFormInput,
    BSpinner,
    SkuAutoComplete,
    SkuFilterRow
  }
})
export default class SkuFilter extends Vue {
  @State(namespace) worksheetState: WorksheetState;
  @Mutation("setSkuFilters", { namespace }) setSkuFilters: Function;

  @Prop({ required: true, default: () => [] })
  topAccounts: Partial<TopAccountDto>[];

  @Prop()
  allowFilter: boolean;

  @Prop()
  runApply: boolean;

  @Prop({ default: "" })
  sku: string; // this is the initial sku off the query string.

  @Prop({ default: false, type: Boolean })
  loading: boolean;

  @Prop({ default: 10 })
  pageSize: number;

  @Prop({ default: false })
  worksheetHasFilters: boolean;

  @Prop()
  pageLocation: string;

  @Prop({ default: true })
  showFilterControls: boolean;

  filtering = false;
  popoverShowing = false;
  selectedIndex = 0;
  paginatedFilterResult: PaginationResult<ProductDto[]>;
  filterResults: ProductDto[] = [];
  filterSettings: FilterSettings = this.getEmptyFilterSettings();
  productAttributes: ProductAttributeDto[] = [];
  skuDisplay = "";
  firstLoad = true;

  get hasFilters(): boolean {
    return this.filterSettings.filters.length > 0;
  }

  get filterChangedEventData(): FilterChangeEventData {
    return {
      filters: this.filterSettings.filters,
      filterProperties: this.filterSettings.filters.map(f =>
        f.field === ProductFilterField.productAttribute ? f.fieldName! : f.field
      )
    };
  }

    data() {
    return {

      internalSku: this.sku,
    };
    }


  created() {
    EventBus.$on(SafioEvents.filterAdd, this.addFilter);
    EventBus.$on(SafioEvents.filterSort, this.updateSortDir);
    EventBus.$on(SafioEvents.skuSelected, this.onSkuSelectionChanged);
    EventBus.$on(SafioEvents.tenantLoaded, this.onTenantLoaded);
    EventBus.$on(SafioEvents.tenantChanged, this.onTenantChanged);
    EventBus.$on(SafioEvents.userLoaded, this.onUserLoaded);
    EventBus.$on(SafioEvents.assemblyCostUpdate, this.updateAssemblyCost);
    EventBus.$on(InterWindowEvents.skuSelected, this.onSkuSelectionChanged);
    EventBus.$on(
      InterWindowEvents.skuFilterPageChange,
      this.updateFilterResults
    );
    EventBus.$on(
      InterWindowEvents.filterResultsUpdated,
      this.updateFilterResults
    );
    EventBus.$on(
      InterWindowEvents.worksheetFiltersApply,
      this.applyWorksheetFilters
    );
    EventBus.$on(InterWindowEvents.assemblyCostUpdate, this.updateAssemblyCost);
  }

  beforeDestroy() {
    EventBus.$off(SafioEvents.filterAdd, this.addFilter);
    EventBus.$off(SafioEvents.filterSort, this.updateSortDir);
    EventBus.$off(SafioEvents.skuSelected, this.onSkuSelectionChanged);
    EventBus.$off(SafioEvents.tenantLoaded, this.onTenantLoaded);
    EventBus.$off(SafioEvents.tenantChanged, this.onTenantChanged);
    EventBus.$off(SafioEvents.userLoaded, this.onUserLoaded);
    EventBus.$off(SafioEvents.assemblyCostUpdate, this.updateAssemblyCost);
    EventBus.$off(InterWindowEvents.skuSelected, this.onSkuSelectionChanged);
    EventBus.$off(
      InterWindowEvents.skuFilterPageChange,
      this.updateFilterResults
    );
    EventBus.$off(
      InterWindowEvents.filterResultsUpdated,
      this.updateFilterResults
    );
    EventBus.$off(
      InterWindowEvents.worksheetFiltersApply,
      this.applyWorksheetFilters
    );
    EventBus.$off(
      InterWindowEvents.assemblyCostUpdate,
      this.updateAssemblyCost
    );
  }
  clearSkuSelection() {
    this.$emit('clear-sku-selection');

  }
  private async onUserLoaded() {
    this.productAttributes = await ProductService.getProductAttributes();
    if (this.runApply) {
      const storedFilterSettings = this.getStoredFilterSettings();
      this.filterSettings =
        storedFilterSettings ?? this.getEmptyFilterSettings();
      await this.applyFilterChanges(false, 1, false, !!storedFilterSettings);
    }
  }

  private async onTenantLoaded() {
    // determine if the filter settings were previously set, and then use this to trigger a sendFilterChange
    const storedFilterSettings = this.getStoredFilterSettings();
    this.filterSettings = storedFilterSettings ?? this.getEmptyFilterSettings();
    await this.applyFilterChanges(false, 1, false, !!storedFilterSettings);
  }

  private async onTenantChanged() {
    this.filterSettings = this.getEmptyFilterSettings();
    await this.applyFilterChanges(true, 1, false, true);
  }

  private async updateAssemblyCost(data: {
    productId: number;
    assemblyCost: number;
  }) {
    let filterResult = this.paginatedFilterResult.data.find(
      d => d.id === data.productId
    );
    if (filterResult) {
      filterResult.assemblyCost = data.assemblyCost;
    }
    filterResult = this.filterResults.find(fr => fr.id === data.productId);
    if (filterResult) {
      filterResult.assemblyCost = data.assemblyCost;
    }
  }

  private applyWorksheetFilters() {
    if (this.pageLocation === "worksheet") {
      WindowBus.$emit(InterWindowEvents.filterResultsUpdated, {
        paginatedFilterResult: this.paginatedFilterResult,
        selectedIndex: this.selectedIndex,
        filterSettings: this.filterSettings,
        finishLoadingSku: true
      });
    }
  }

  private updateFilterResults(data: any) {
    this.paginatedFilterResult = _.cloneDeep(data.paginatedFilterResult);
    this.filterResults = this.paginatedFilterResult.data;
    this.selectedIndex = data.selectedIndex;
    this.filterSettings = _.cloneDeep(data.filterSettings);

    if (data.finishLoadingSku) {
      this.selectSkuIfInResults(
        true,
        this.filterResults[this.selectedIndex]?.sku
      );
    }

    this.skuDisplay = this.getSkuDisplay();
    this.sendFilterChange();
  }

  private sendFilterChange() {
    this.setSkuFilters(this.filterSettings); // this is really just for the outlook display...
    EventBus.$emit(SafioEvents.filterChanged, this.filterChangedEventData);
  }

  /**
   * Attempts to grab the stored filter from the browser's session storage
   * If there is no filter or something goes wrong with parsing, it returns an empty filter settings object
   */
  private getStoredFilterSettings(): FilterSettings | undefined {
    const result = StorageService.session.get(
      `filter.${process.env.NODE_ENV}.${ApiService.tenant}`
    );
    if (result) {
      try {
        return JSON.parse(result);
      } catch (e) {
        return undefined;
      }
    }
    return undefined;
  }

  private getEmptyFilterSettings(): FilterSettings {
    return { filters: [], sortDirection: "" };
  }

  private async applyFilters(page: number, sku: string | null = null) {
    this.paginatedFilterResult = await WorksheetService.applyFilters(
      this.filterSettings,
      this.pageSize,
      page,
      sku
    );
    this.filterResults = this.paginatedFilterResult.data;
  }

  async applyFilterChanges(
    selectFirstIfSkuInvalid = false,
    page = 1,
    useSku = false,
    sendFilterChange = false
  ) {
    this.filtering = true;
    try {
      if (
        this.firstLoad &&
        this.worksheetHasFilters &&
        this.filterSettings.filters.length === 0
      ) {
        WindowBus.$emit(InterWindowEvents.worksheetFiltersApply, null);
        this.firstLoad = false;
      } else {
        const previousSku = this.filterResults[this.selectedIndex]?.sku;
        this.selectedIndex = -1;

        StorageService.session.set(
          `filter.${process.env.NODE_ENV}.${ApiService.tenant}`,
          JSON.stringify(this.filterSettings)
        );

        if ((!previousSku && this.sku) || useSku) {
          await this.applyFilters(page, !previousSku ? this.sku : previousSku);
        } else {
          await this.applyFilters(page);
        }

        if (this.filterResults && this.filterResults.length > 0) {
          EventBus.$emit(SafioEvents.filterResultsUpdated, this.filterResults);
          WindowBus.$emit(InterWindowEvents.filterResultsUpdated, {
            paginatedFilterResult: this.paginatedFilterResult,
            selectedIndex: this.selectedIndex,
            filterSettings: this.filterSettings
          });

          this.selectSkuIfInResults(selectFirstIfSkuInvalid, previousSku);
        }

        if (sendFilterChange) {
          this.sendFilterChange();
        }
      }
    } catch (err) {
      console.error(err);
      this.$bvToast.toast(
        "An error occurred while searching.  Please try again.",
        { autoHideDelay: 2500 }
      );
    } finally {
      this.filtering = false;
      this.popoverShowing = false;
    }
  }

  private selectSkuIfInResults(
    selectFirstIfInvalid = false,
    sku?: string
  ): boolean {
    // Did we get results?  Do we have an initial sku?  Is that sku in the results?
    const searchSku = sku || this.sku;
    if (this.filterResults?.length) {
      if (searchSku) {
        this.selectedIndex = this.filterResults.findIndex(
          result => result.sku === searchSku
        );
        if (this.selectedIndex === -1) {
          if (selectFirstIfInvalid) {
            this.selectedIndex = 0;
          } else {
            this.$emit("invalid-sku", searchSku);
          }
        }
      } else {
        this.selectedIndex = 0;
      }

      if (this.selectedIndex >= 0) {
        const isPageAutoRefresh = Common.getQueryStringValue(
          "pageAutoRefreshed",
          "false"
        );
        if (isPageAutoRefresh === "false") {
          WindowBus.$emit(
            InterWindowEvents.skuSelected,
            this.filterResults[this.selectedIndex]
          );
        }
        EventBus.$emit(
          SafioEvents.skuSelected,
          this.filterResults[this.selectedIndex]
        );
      }
      return true;
    }

    return false;
  }

  addFilter(filter?: ProductFilter) {
    if (!filter) {
      filter = {
        field: ProductFilterField.noSelection,
        operation: ProductFilterOperation.equal,
        fieldType: ProductFieldType.Text,
        options: []
      };
    }
    const existingFilter = this.filterSettings.filters.find(f => {
      return filter?.field === ProductFilterField.productAttribute
        ? f.fieldName === filter?.fieldName
        : f.field === filter?.field;
    });
    let madeChanges = true;
    if (!existingFilter) {
      this.filterSettings.filters.push(filter);
    } else if (filter.field === ProductFilterField.topCustomer) {
      // they sent a top account filter and we already have one.  Update it.
      existingFilter.value = filter.value;
      existingFilter.displayValue = filter.displayValue;
    } else {
      madeChanges = false;
    }
    if (madeChanges) {
      this.sendFilterChange();
      this.popoverShowing = true;
    }
  }

  async clearFilters() {
    if (this.filterSettings.filters.length) {
      this.filterSettings.filters = [];
      this.sendFilterChange();
      this.topAccounts;
      await this.applyFilterChanges(false, 1, true);
    }
  }

  removeFilter(index: number) {
    this.filterSettings.filters.splice(index, 1);
    // Emit the new list of property names that are filtered so that components can update their UIs.
    this.sendFilterChange();
  }

  updateSortDir(dir: string) {
    // todo: figure out where this gets triggered...
    if (this.filterSettings.sortDirection === dir) {
      this.filterSettings.sortDirection = "";
    } else {
      this.filterSettings.sortDirection = dir;
    }
    this.applyFilterChanges(false, 1, true);
  }

  onFilterRowUpdated(index: number, filter: ProductFilter) {
    this.filterSettings.filters.splice(index, 1, filter);
    this.sendFilterChange();
  }

  onFilterRemoved(index: number) {
    this.removeFilter(index);
  }

  /* Page Navigation Logic */
  getStatusDisplay() {
    const skuNumber = this.paginatedFilterResult
      ? (this.paginatedFilterResult.currentPage - 1) * this.pageSize +
        this.selectedIndex +
        1
      : 0;
    const totalSkus = this.paginatedFilterResult
      ? this.paginatedFilterResult.totalRecords
      : 0;
    return `${skuNumber} of ${totalSkus}`;
  }

  getSkuDisplay() {
    if (
      this.paginatedFilterResult &&
      this.paginatedFilterResult.data &&
      this.paginatedFilterResult.data.length &&
      this.selectedIndex !== -1
    ) {
      const sku = this.paginatedFilterResult.data[this.selectedIndex].sku;
      const description = this.paginatedFilterResult.data[this.selectedIndex]
        .description;
      return `${sku} - ${description}`;
    } else {
      return "";
    }
  }

  getTotalPages() {
    return this.paginatedFilterResult
      ? Math.ceil(
          this.paginatedFilterResult.totalRecords /
            this.paginatedFilterResult.perPage
        )
      : 0;
  }

  isFirstRecord() {
    return (
      this.paginatedFilterResult.currentPage === 1 && this.selectedIndex === 0
    );
  }

  isLastRecord() {
    return (
      this.paginatedFilterResult.currentPage === this.getTotalPages() &&
      this.selectedIndex === this.filterResults.length - 1
    );
  }

  async goFirst() {
    if (!this.isFirstRecord()) {
      this.selectedIndex = 0;
      await this.applyFilters(1);
      EventBus.$emit(
        SafioEvents.skuSelected,
        this.filterResults[this.selectedIndex]
      );
      WindowBus.$emit(InterWindowEvents.skuFilterPageChange, {
        paginatedFilterResult: this.paginatedFilterResult,
        selectedIndex: this.selectedIndex,
        filterSettings: this.filterSettings
      });
      WindowBus.$emit(
        InterWindowEvents.skuSelected,
        this.filterResults[this.selectedIndex]
      );
    }
  }

  async goPrev() {
    if (!this.isFirstRecord()) {
      if (this.selectedIndex === 0) {
        this.selectedIndex = this.pageSize - 1;
        await this.applyFilters(this.paginatedFilterResult.currentPage - 1);
      } else {
        this.selectedIndex -= 1;
      }
      EventBus.$emit(
        SafioEvents.skuSelected,
        this.filterResults[this.selectedIndex]
      );
      WindowBus.$emit(InterWindowEvents.skuFilterPageChange, {
        paginatedFilterResult: this.paginatedFilterResult,
        selectedIndex: this.selectedIndex,
        filterSettings: this.filterSettings
      });
      WindowBus.$emit(
        InterWindowEvents.skuSelected,
        this.filterResults[this.selectedIndex]
      );
    }
  }

  async goNext() {
    if (!this.isLastRecord()) {
      if (this.selectedIndex === this.filterResults.length - 1) {
        this.selectedIndex = 0;
        await this.applyFilters(this.paginatedFilterResult.currentPage + 1);
      } else {
        this.selectedIndex += 1;
      }
    }
    EventBus.$emit(
      SafioEvents.skuSelected,
      this.filterResults[this.selectedIndex]
    );
    WindowBus.$emit(InterWindowEvents.skuFilterPageChange, {
      paginatedFilterResult: this.paginatedFilterResult,
      selectedIndex: this.selectedIndex,
      filterSettings: this.filterSettings
    });
    WindowBus.$emit(
      InterWindowEvents.skuSelected,
      this.filterResults[this.selectedIndex]
    );
  }

  async goLast() {
    if (!this.isLastRecord()) {
      this.selectedIndex = this.filterResults.length - 1;
      await this.applyFilters(
        Math.ceil(this.paginatedFilterResult.totalRecords / this.pageSize)
      );
      EventBus.$emit(
        SafioEvents.skuSelected,
        this.filterResults[this.filterResults.length - 1]
      );
      WindowBus.$emit(InterWindowEvents.skuFilterPageChange, {
        paginatedFilterResult: this.paginatedFilterResult,
        selectedIndex: this.selectedIndex,
        filterSettings: this.filterSettings
      });
      WindowBus.$emit(
        InterWindowEvents.skuSelected,
        this.filterResults[this.selectedIndex]
      );
    }
  }


  async onSkuSelectionChanged(product: ProductDto) {
    this.selectedIndex = this.filterResults.findIndex(
      fr => fr.sku === product.sku
    );
    if (this.selectedIndex === -1) {
      await this.applyFilters(1, product.sku);
      this.selectedIndex = this.filterResults.findIndex(
        fr => fr.sku === product.sku
      );
    }

    this.skuDisplay = this.getSkuDisplay();
  }
}
