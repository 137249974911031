import { BaseHttpService } from '@/services/base-http-service'
import { DataGroupDataImportDto } from '@basic-code/shared'

class DataGroupDataImportService extends BaseHttpService {
    public async updateOrCreateDataGroupDataImports(dataGroupId: number, dataGroupDataImports: DataGroupDataImportDto[]): Promise<DataGroupDataImportDto[]> {
        const uri = '/data-group-data-imports'
        const response = await this.doApiPut<DataGroupDataImportDto[]>(uri, JSON.stringify({ dataGroupId, dataGroupDataImports }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new DataGroupDataImportService()
