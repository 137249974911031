import { BaseHttpService } from '@/services/base-http-service'
import { ViewDto } from '@/types'

class ViewService extends BaseHttpService {
    public async getView(id: number): Promise<ViewDto> {
        const uri = `/tenant-views/${id}`
        const response = await this.doApiGet<ViewDto>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getViews(includeCoreViews?: boolean): Promise<ViewDto[]> {
        const uri = `/tenant-views${includeCoreViews ? `?includeCoreViews=${includeCoreViews}` : ''}`
        const response = await this.doApiGet<ViewDto[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async createView(view: Partial<ViewDto>): Promise<ViewDto> {
        const uri = '/tenant-views'
        const response = await this.doApiPost<ViewDto>(uri, JSON.stringify({ view: view }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateView(id: number, view: Partial<ViewDto>, oldCreateOrder = -1): Promise<ViewDto> {
        const uri = `/tenant-views/${id}`
        const response = await this.doApiPut<ViewDto>(uri, JSON.stringify({ view: view, oldCreateOrder: oldCreateOrder }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async deleteView(id: number): Promise<{ response: any; success: boolean; message: string }> {
        const uri = `/tenant-views/${id}`
        const response = await this.doApiDelete(uri)
        if (response.success) {
            return response.response! as any
        }
        throw new Error(response.error!.message)
    }
}

export default new ViewService()
