import {ItemTotalType} from "@/types";
import Common from "@/util/common";
import CommonForecastHelper from "@/util/forecastCalculations/common-forecast-helper";
import PlannerFcCalculations from "@/util/forecastCalculations/planner-forecast-calculations";
import {ForecastMonthData, ForecastSummaryMonth, PlannerForecastYear, PlannerForecastDto} from "@basic-code/shared";

export default class ParentBomForecastCalculations {
    public static getPlannerBomUsageTotal(pfYear: PlannerForecastYear, totalType: ItemTotalType): number {
        let total = 0;
        for (const fcMonth of pfYear.forecastMonths) {
            total += this.getPlannerBomMonthUsage(fcMonth)
            if (CommonForecastHelper.hasReachedYtdTotal(totalType, fcMonth.month)) break;
        }

        return total
    }

    public static getPlannerBomMonthUsage(pfMonth: PlannerForecastDto | ForecastMonthData): number {
        return Common.getNumber(pfMonth.shipmentAmount);
    }

    public static getAccountBomUsageTotal(pfYear: PlannerForecastYear, totalType: ItemTotalType): number {
        let total = 0
        for (const fcMonth of pfYear.accountForecast.monthTotals) {
            total += this.getAccountBomMonthUsage(fcMonth);
            if (CommonForecastHelper.hasReachedYtdTotal(totalType, fcMonth.month)) break;
        }
        return total
    }

    public static getAccountBomMonthUsage(fcMonth: ForecastSummaryMonth | ForecastMonthData): number {
        return Common.getNumber(fcMonth.shipmentTotal)
    }

    public static getBomTotalMonthUsage(pfMonth: PlannerForecastDto): number {
        return pfMonth.adjustmentAmount + pfMonth.flowAmount;
    }

    public static getBomTotalUsage(pfYear: PlannerForecastYear, totalType: ItemTotalType): number {
        return PlannerFcCalculations.getFlowTotal(pfYear, totalType)
            + PlannerFcCalculations.getAdjustmentTotal(pfYear, totalType);
    }
}
