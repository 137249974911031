










import ApiService from '@/services/api-service'
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class AuthDisplay extends Vue {
  login () {
    this.$auth.loginWithRedirect({})
  }

  // Log the user out
  logout () {
    ApiService.accessToken = null
    ApiService.tenant = null
    this.$auth.logout({
      federated: true,
      returnTo: window.location.origin
    })
    return false
  }
}
