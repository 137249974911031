import { FilterSettings } from '@/types'
import { ForecastWorksheet, PlannerProposedOrdersDto, PlannerProposedOrdersDtoV3, ProductDto,
  TopAccountDto, PaginationResult } from '@basic-code/shared'
import { BaseHttpService } from './base-http-service'
import { subject as authSubject } from '@casl/ability'

class WorksheetService extends BaseHttpService {
  public async getForecastWorksheet(sku: string): Promise<ForecastWorksheet> {
    const uri = `/worksheets/${encodeURIComponent(sku)}`
    const response = await this.doApiGet<ForecastWorksheet>(uri)
    if (response.success) {
      authSubject('worksheet', response.response!)
      response.response!.product = ProductDto.create(response.response!.product)
      response.response!.plannerProposedOrders = response.response!.plannerProposedOrders.map(ppo => PlannerProposedOrdersDtoV3.create(ppo))
      return response.response!
    }
    throw new Error(response.error!.message)
  }

  public async searchFilterValues(searchText: string, field: string, fieldId?: number): Promise<any> {
    const uri = `/search/filter-values`
    const body = {
      searchText,
      field,
      fieldId
    }
    const response = await this.doApiPost<any>(uri, JSON.stringify(body))
    if (response.success) {
      return response.response!
    }
    throw new Error(response.error!.message)
  }

  public async applyFilters(filterSettings: FilterSettings, pageSize = 10, page = 1, sku: string|null = null): Promise<PaginationResult<ProductDto[]>> {
    const data = {
      filters: filterSettings.filters,
      sort: filterSettings.sortDirection,
      pageSize: pageSize,
      page: page,
      sku: sku
    }
    const uri = `/search/products`
    const response = await this.doApiPost<PaginationResult<ProductDto[]>>(uri, JSON.stringify(data))
    if (response.success) {
      return response.response!
    }
    throw new Error(response.error?.message || 'error searching')
  }

  public async getTopAccounts(): Promise<Partial<TopAccountDto>[]> {
    const uri = 'worksheets/top-accounts'
    const response = await this.doApiGet<Partial<TopAccountDto>[]>(uri)
    if (response.success) {
      return response.response!
    }
    throw new Error(response.error!.message)
  }

  public async updatePurchaseOrders(plannerOrders: PlannerProposedOrdersDto, sku: string, type: string) {
    const uri = `/products/${sku}/purchase-orders?type=${encodeURIComponent(type)}`
    const response = await this.doApiPut(uri, JSON.stringify({ plannerOrders }))
    return response.success
  }

  public async updateVendorPromotion(sku: string, vendorId: string, promote: boolean): Promise<boolean> {
    const uri = `/forecasts/${sku}/vendor/${vendorId}/promotion`
    const response = await this.doApiPut(uri, JSON.stringify({ promote }))
    return response.success
  }

  // public async getCurves() {
  //   const uri = `/curves`
  //   const response = await this.doApiGet<object[]>(uri)
  //   if (response.success) {
  //     return response.response!
  //   }
  //   throw new Error(response.error!.message)
  // }

  // public async updateSkuMarkers(markers: SkuMarker[], sku: string) {
  //   const uri = `/products/${sku}/markers`
  //   const response = await this.doApiPut(uri, JSON.stringify({ markers }))
  //   return response.success
  // }
}

export default new WorksheetService()
