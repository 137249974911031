import { BaseHttpService } from '@/services/base-http-service'
import { VendorLocationDto } from '@basic-code/shared'
import { FormAddEditVendorLocationDto } from '@/types'

class VendorLocationService extends BaseHttpService {
    public async createVendorLocation(vendorLocation: FormAddEditVendorLocationDto): Promise<VendorLocationDto> {
        const uri = '/vendor-locations'
        const response = await this.doApiPost<VendorLocationDto>(uri, JSON.stringify({ vendorLocation: vendorLocation }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateVendorLocation(id: number, vendorLocation: Partial<VendorLocationDto>): Promise<VendorLocationDto> {
        const uri = `/vendor-locations/${id}`
        const response = await this.doApiPut<VendorLocationDto>(uri, JSON.stringify({ vendorLocation: vendorLocation }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async deleteVendorLocation(id: number): Promise<boolean> {
        const uri = `/vendor-locations/${id}`
        const response = await this.doApiDelete(uri)
        return !!response
    }

    public async getVendorLocationByName(name: string): Promise<VendorLocationDto> {
        const uri = `/vendor-locations?name=${name}`
        const response = await this.doApiGet<VendorLocationDto>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new VendorLocationService()
