import { BaseHttpService } from '@/services/base-http-service'
import { SystemSettingDto } from '@basic-code/shared'
import { subject as authSubject } from '@casl/ability'

class SystemSettingsService extends BaseHttpService {
    public async getSystemSettings(): Promise<SystemSettingDto> {
        const uri = '/system-settings'
        const response = await this.doApiGet<SystemSettingDto>(uri)
        if (response.success) {
            authSubject('settings', response.response!)
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateSystemSettings(systemSettings: SystemSettingDto|null, changedProjectedForecastOverride: boolean): Promise<SystemSettingDto> {
        const uri = '/system-settings'
        const response = await this.doApiPut<SystemSettingDto>(uri, JSON.stringify({ systemSettings: systemSettings, changedProjectedForecastOverride: changedProjectedForecastOverride }))
        if (response.success) {
            authSubject('settings', response.response!)
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new SystemSettingsService()
