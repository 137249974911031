import { BaseHttpService } from '@/services/base-http-service'
import { ProductGroupDto } from '@basic-code/shared'

class ProductGroupService extends BaseHttpService {
    public async getByProductNewStatus(isNew: boolean, viewInAppProductsOnly = false): Promise<ProductGroupDto[]> {
        const uri = `/product-groups?isNew=${isNew}&viewInAppProductsOnly=${viewInAppProductsOnly}`
        const response = await this.doApiGet<ProductGroupDto[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new ProductGroupService()
