import { BaseHttpService } from '@/services/base-http-service'
import { ProductAttributeOptionDto, DropdownOption } from '@basic-code/shared'

class ProductAttributeOptionsService extends BaseHttpService {
    public async addProductAttributeOption(productAttributeId: number, optionValue: string): Promise<ProductAttributeOptionDto> {
        const uri = `/product-attributes/${productAttributeId}/product-attribute-options`
        const response = await this.doApiPost<ProductAttributeOptionDto>(uri, JSON.stringify({ optionValue: optionValue }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateProductAttributeOption(productAttributeId: number, productAttributeOptionId: number, optionValue: string): Promise<ProductAttributeOptionDto> {
        const uri = `/product-attributes/${productAttributeId}/product-attribute-options/${productAttributeOptionId}`
        const response = await this.doApiPut<ProductAttributeOptionDto>(uri, JSON.stringify({ optionValue: optionValue }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async deleteProductAttributeOption(productAttributeId: number, productAttributeOptionId?: number): Promise<boolean> {
        let uri = ''

        if (!productAttributeOptionId) {
            uri = `/product-attributes/${productAttributeId}/product-attribute-options`
        } else {
            uri = `/product-attributes/${productAttributeId}/product-attribute-options/${productAttributeOptionId}`
        }

        const response = await this.doApiDelete(uri)
        return !!response;
    }

    public async getProductAttributeOptions (productAttributeId: number): Promise<ProductAttributeOptionDto[]> {
        const uri = `/product-attributes/${productAttributeId}/product-attribute-options`
        const response = await this.doApiGet<ProductAttributeOptionDto[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getProductAttributeOptionsForForecastLock (salesReps: DropdownOption[], accounts: DropdownOption[], vendors: DropdownOption[], productAttributeId: number): Promise<ProductAttributeOptionDto[]> {
        const uri = `/product-attributes/${productAttributeId}/product-attribute-options-for-forecast-lock`
        const response = await this.doApiPost<ProductAttributeOptionDto[]>(uri, JSON.stringify({
            salesReps: salesReps.map(sr => sr.value),
            accounts: accounts.map(a => a.value),
            vendors: vendors.map(v => v.value),
        }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new ProductAttributeOptionsService()
