
































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component
export default class ProductImageDisplay extends Vue {

  @Prop({ default: '', type: String })
  url: string

  @Prop({ default: false, type: Boolean })
  large: boolean

  popoverShow = false
  broken = false

  @Watch('url')
  resetUrlBrokenStatus() {
    this.broken = false
  }
}
