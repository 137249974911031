import Vue from 'vue'
import { format as dateFormat, parseISO } from 'date-fns'
import { AccountForecastYear } from '@basic-code/shared'
import getSymbolForCurrency from 'currency-symbol-map'

declare type ItemData = {
  year: AccountForecastYear;
  collection: string;
  iteratorArray: string;
}

const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']

export function currencySymbol(currencyCode: string): string {
  return getSymbolForCurrency(currencyCode || 'USD') || '$'
}

export function percent(value: number, decimalPlaces = 1) {
  if (typeof value === 'number') {
    return `${value.toFixed(decimalPlaces)}%`
  }
  return value
}

export function date(value: Date|string|undefined, format?: string): string {
  try {
    if (value === undefined) {
      return ''
    }
    const date = typeof value === 'string' ? parseISO(value) : value
    if (date) {
      return dateFormat(date, format || 'yyyy-MM-dd')
    }
    return ''
  } catch (err) {
    return value?.toString() || ''
  }
}

export function twoDigitYear(year: string|number): string {
  return year ? `'` + year.toString().substring(2) : ''
}

export function maxLen(value: string, maxLen = 25): string {
  if (value && value.length > maxLen) {
    return value.slice(0, maxLen-1) + '\u2026'
  }
  return value
}

export function blankIfZero(value: number): string|number {
  return value !== 0 ? value : ''
}

export function valueIfBlank(value: string, valueIfBlank: string): string {
  return value && value.length ? value : valueIfBlank
}

export function commaNumber(value: number|string): string {
  if (value === null || value === undefined) return ''
  return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export function currency(value: number, currency?: string): string {
  if (typeof value === 'number') {
    const currSymbol = getSymbolForCurrency(currency || 'USD')
    return `${currSymbol}${commaNumber(value.toFixed(2))}`
  }
  return value
}

export function monthIndexToAbbr(value: number): string {
  if (value < 0 || value > 11) {
    return 'err'
  }
  return months[value]
}

Vue.filter('percent', percent)
Vue.filter('currency', currency)
Vue.filter('date', date)
Vue.filter('twoDigitYear', twoDigitYear)
Vue.filter('maxLen', maxLen)
Vue.filter('blankIfZero', blankIfZero)
Vue.filter('valueIfBlank', valueIfBlank)
Vue.filter('commas', commaNumber)
Vue.filter('monthIndexToAbbr', monthIndexToAbbr)
Vue.filter('currencySymbol', currencySymbol)
