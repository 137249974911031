import { DataImportRunTimeErrorsState, RootState } from '@/store/types'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { DataGroupDataImportRunLogDto, PaginationResult } from '@basic-code/shared'
import DataGroupDataImportRunLogService from '@/services/v3/data-group-data-import-run-log-service'
import DtoFactory from '@/util/dto-factory'

export const state: DataImportRunTimeErrorsState = {
    paginatedDataGroupDataImportRunLogs: DtoFactory.placeholderPaginationResult(),
    dataGroupId: -1,
    dataImportId: -1,
    page: 1,
    pageSize: 25,
}

const getters: GetterTree<DataImportRunTimeErrorsState, RootState> = {
    getPaginatedDataGroupDataImportRunLogs(state): PaginationResult<DataGroupDataImportRunLogDto[]> {
        return state.paginatedDataGroupDataImportRunLogs
    },

    getDataGroupId(state): number {
        return state.dataGroupId
    },

    getDataImportId(state): number {
        return state.dataImportId
    },

    getPage(state): number {
        return state.page
    },

    getPageSize(state): number {
        return state.pageSize
    }
}

const actions: ActionTree<DataImportRunTimeErrorsState, RootState> = {
    async paginatedDataGroupDataImportRunLogs({ commit, state }) {
        try {
            const results = await DataGroupDataImportRunLogService.getErrorLogs(state.dataImportId, state.dataGroupId, state.page, state.pageSize)
            commit('setPaginatedDataGroupDataImportRunLogs', results)
        } catch (err) {
            console.log(err)
        }
    }
}

const mutations: MutationTree<DataImportRunTimeErrorsState> = {
    setPaginatedDataGroupDataImportRunLogs(state, payload: PaginationResult<DataGroupDataImportRunLogDto[]>) {
        state.paginatedDataGroupDataImportRunLogs = payload
    },

    setDataGroupId(state, payload: number) {
        state.dataGroupId = payload
    },

    setDataImportId(state, payload: number) {
        state.dataImportId = payload
    },

    setPage(state, payload: number) {
        state.page = payload
    },

    setPageSize(state, payload: number) {
        state.pageSize = payload
    }
}

const namespaced = true

export const errorsStore: Module<DataImportRunTimeErrorsState, RootState> = {
    namespaced: namespaced,
    state,
    getters,
    actions,
    mutations
}
