import { BaseHttpService } from '@/services/base-http-service'
import { VendorProductDto } from '@basic-code/shared'
import { FormAddEditVendorProductDto } from '@/types'

class VendorProductService extends BaseHttpService {
    public async getVendorProducts(params?: { includeChildren?: boolean; productId?: number; vendorId?: number}): Promise<VendorProductDto[]> {
        let uri = '/vendor-products'
        if (params) {
          const urlParams: any = {}
          if (params.includeChildren !== undefined) {
            urlParams.include_children = params.includeChildren
          }
          if (params.productId !== undefined) {
            urlParams.productId = params.productId
          }
          if (params.vendorId !== undefined) {
            urlParams.vendorId = params.vendorId
          }
          uri = `${uri}?${this.buildQueryStringFromObject(urlParams)}`
        }
        const response = await this.doApiGet<VendorProductDto[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async createVendorProduct(vendorProduct: FormAddEditVendorProductDto): Promise<VendorProductDto> {
        const uri = '/vendor-products/'
        const response = await this.doApiPost<VendorProductDto>(uri, JSON.stringify({ vendorProduct: vendorProduct }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateVendorProduct(id: number, vendorProduct: Partial<VendorProductDto>, productId: number, oldPriority = -1, POContractedPriceUpdated = false): Promise<VendorProductDto> {
        const uri = `/vendor-products/${id}`
        const response = await this.doApiPut<VendorProductDto>(uri, JSON.stringify({ vendorProduct: vendorProduct, productId: productId, oldPriority: oldPriority, POContractedPriceUpdated: POContractedPriceUpdated }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async deleteVendorProduct(id: number): Promise<boolean> {
        const uri = `/vendor-products/${id}`
        const response = await this.doApiDelete(uri)
        return !!response
    }
}

export default new VendorProductService()
