





















































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { SystemSettingDto, VendorProductDto } from '@basic-code/shared'
import { EventBus, SafioEvents } from '@/services/event-bus'
import { Getter, State } from 'vuex-class'
import VendorProductService from '@/services/v3/vendor-product-service'
import { WorksheetState } from '@/store/types'

interface VendorOverrideDto {
  vendorProduct: VendorProductDto;
  desiredPrice: number;
}

const namespace ="worksheetStore"

@Component<VendorOverride>({})
export default class VendorOverride extends Vue {

  @Getter('settings') settings: SystemSettingDto
  @State(namespace) worksheetState: WorksheetState

  @Prop()
  promotedVendorName: string|undefined

  @Prop({ required: true, default: () => { return [] } })
  vendorProducts: VendorProductDto[]
  overrides: VendorOverrideDto[] = []
  popoverShow = false

  get vendorName() {
    return this.promotedVendorName || 'off'
  }

  getOverrideVendorName(vendorProduct: VendorProductDto) {
    return vendorProduct.vendor?.name || ''
  }

  @Watch('vendorProducts', { immediate: true })
  updateOverrides(vendorProducts: VendorProductDto[]|undefined) {
    if (!vendorProducts) return
    this.overrides = []
    for (const vp of vendorProducts) {
      this.overrides.push({
        vendorProduct: vp,
        desiredPrice: 0
      })
    }
  }

  getPpkQty(override: VendorOverrideDto) {
    return override.vendorProduct.orderIncrement ? Math.round(override.desiredPrice / override.vendorProduct.orderIncrement) : 0
  }

  getEaQty(override: VendorOverrideDto) {
    return override.vendorProduct.orderIncrement ? Math.round(override.desiredPrice / override.vendorProduct.orderIncrement) * override.vendorProduct.orderIncrement : 0
  }

  async togglePoOverride(override: VendorOverrideDto) {
    try {
      const originalOverride = this.overrides.find(or => or.vendorProduct.poOverride && or.vendorProduct.id !== override.vendorProduct.id)
      if (originalOverride) {
        await VendorProductService.updateVendorProduct(originalOverride.vendorProduct.id, {
          poOverride: false
        }, originalOverride.vendorProduct.productId)
      }
      await VendorProductService.updateVendorProduct(override.vendorProduct.id, {
        poOverride: override.vendorProduct.poOverride
      }, override.vendorProduct.productId)
      EventBus.$emit(SafioEvents.skuReload)
      setTimeout(() => {
        this.popoverShow = true
      }, 1000)
    } catch (err) {
      this.$bvToast.toast('Error updating override', { title: 'Override Update Error', autoHideDelay: 5000, variant: 'danger' })
    }
  }

}
