import Vue from 'vue'
import { Guid } from 'guid-typescript'

export const EventBus = new Vue()

export const SafioEvents = {
  accountFlowUpdated: 'account-flow-updated',
  accountOrProductGroupFilterApplied: 'account-or-product-group-filter-applied',
  accountUpdated: 'account-updated',
  addAccount: 'add-account',
  addForecast: 'add-forecast',
  addSku: 'add-sku',
  assemblyCostUpdate: 'assembly-cost-update',
  bomChanged: 'bom-change',
  childBomPageChange: 'child-bom-page-change',
  dataGroupSave: 'data-group-save',
  dataImportAdded: 'data-import-added',
  dataImportRunStatusChange: 'data-import-run-status-change',
  editorCancelAdd: 'cancel-account-add',
  editorUpdateStateCounts: 'editor-update-states',
  filterAdd: 'filter-add',
  filterChanged: 'filter-change',
  filterRemove: 'filter-remove',
  filterResultsUpdated: 'filter-results-updated',
  filterRowUpdated: 'filter-row-updated',
  filterSort: 'filter-sort',
  forecastActiveYear: 'forecast-active-year',
  forecastChangeDirty: 'forecast-change-dirty',
  forecastEditPageChange: 'forecast-edit-page-change',
  forecastEditTabChange: 'forecast-edit-tab-change',
  forecastFilterClear: 'forecast-filter-clear',
  forecastLockRulesChange: 'forecast-lock-rules-change',
  isDuplicateVendorProductVendorSku: 'is-duplicate-vendor-product-vendor-sku',
  newShippingMethodCleared: 'new-shipping-method-cleared',
  newVendorCleared: 'new-vendor-cleared',
  newVendorLocationCleared: 'new-vendor-location-cleared',
  newVendorProductCleared: 'new-vendor-product-cleared',
  originalRollupUpdated: 'rollup-updated',
  parentBomPageChange: 'parent-bom-page-change',
  productAttributeChange: 'product-attribute-change',
  productAttributeOptionChange: 'product-attribute-option-change',
  productAttributeOptionsPageChange: 'product-attribute-options-page-change',
  productAttributeValueChange: 'product-attribute-value-change',
  productGroupSelected: 'product-group-selected',
  productsTabProductUpdated: 'products-tab-product-updated',
  recordReviewedUpdated: 'record-reviewed-updated',
  replaceProjectedForecastChange: 'replace-projected-forecast-change',
  rollupReverted: 'forecast-reverted',
  runTimeErrorsPageChange: 'run-time-errors-page-change',
  shipmentsNoForecastFilterApplied: 'shipments-no-forecast-filter-applied',
  shipmentsNoForecastPageChange: 'shipments-no-forecast-page-change',
  shippingMethodsPageChange: 'shipping-methods-page-change',
  shopifyApiCredentialsChange: 'shopify-credentials-change',
  skuAddBomTotalsUpdated: 'sku-add-bom-totals-updated',
  skuDeleted: 'sku-deleted',
  skuReload: 'sku-reload',
  skuReplaceProjectedForecastUpdated: 'sku-replace-projected-forecast-updated',
  skuSelected: 'sku-selected',
  skuUpdated: 'sku-updated',
  systemSettingsReverted: 'system-settings-reverted',
  systemSettingsUpdated: 'system-settings-updated',
  tableauDataImportsAdded: 'tableau-data-imports-added',
  tenantChanged: 'tenant-change',
  tenantLoaded: 'tenant-loaded',
  toggleClick: 'toggle-click',
  topCustomerFilterChange: 'top-customer-filter-change',
  userLoaded: 'user-loaded',
  vendorChange: 'vendor-change',
  vendorLocationChange: 'vendor-location-change',
  vendorLocationsPageChange: 'vendor-locations-page-change',
  vendorProductChange: 'vendor-product-change',
  vendorProductsPageChange: 'vendor-products-page-change',
  vendorsPageChange: 'vendors-page-change',
  downloadFilterClear: 'download-filter-clear',
  downloadFilterRefreshed: 'download-filter-refreshed',
  downloadDataRefreshed: 'download-data-refreshed',
  resetForecastToggle: 'reset-forecast-toggle',
}

export const InterWindowEvents = {
  accountDeleted: 'inter-window.account-deleted',
  assemblyCostUpdate: 'inter-window.assembly-cost-update',
  skuSelected: 'inter-window.sku-selected',
  reloadSku: 'inter-window.reload-sku',
  updateSettings: 'inter-window.update-settings',
  updateViewInApp: 'inter-window.update-view-in-app',
  skuFilterPageChange: 'inter-window.sku-filter-page-change',
  filterResultsUpdated: 'inter-window.filter-results-updated',
  worksheetFiltersApply: 'inter-window.worksheet-filters-apply',
}

class WindowBusService {
  events: string[]
  id: string

  constructor() {
    this.id = Guid.create().toString()
    this.events = Object.values(InterWindowEvents)

    window.addEventListener('storage', (evt: StorageEvent) => {
      // evt.key is null when clearing.  evt.newValue is null when deleting or clearing.
      if (evt.key && evt.newValue) {
        const [ evtId, eventName ] = evt.key.split('|')

        // If this is an inter-window event and I'm not the sender...
        if (this.events.includes(eventName) && evtId !== this.id) {
          EventBus.$emit(eventName, JSON.parse(evt.newValue))
        }
      }
    })
  }

  $emit(event: string, value: any) {
    // set the item to trigger the event in other windows, then remove it immediately
    // so that we don't fill up local storage with one-time events.
    localStorage.setItem(`${this.id}|${event}`, JSON.stringify(value))
    localStorage.removeItem(`${this.id}|${event}`)
  }
}

export const WindowBus = new WindowBusService()
