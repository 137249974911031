import { BaseHttpService } from '@/services/base-http-service'
import { AccountClassDtoV3, ProductDto, SalesRepDtoV3, DropdownOption } from '@basic-code/shared'

class SalesRepService extends BaseHttpService {
    public async getSalesReps(): Promise<SalesRepDtoV3[]> {
        const uri = '/sales-reps'
        const response = await this.doApiGet<SalesRepDtoV3[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getSalesRep(id: number): Promise<SalesRepDtoV3> {
        const uri = `/sales-reps/${id}`
        const response = await this.doApiGet<SalesRepDtoV3>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getSalesRepByEmail(email: string): Promise<SalesRepDtoV3> {
        const uri = `/sales-reps?email=${email}`
        const response = await this.doApiGet<SalesRepDtoV3>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getSalesRepProducts(id: number): Promise<ProductDto[]> {
        const uri = `/sales-reps/${id}/products`
        const response = await this.doApiGet<ProductDto[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getSalesRepAccountClasses (id: number): Promise<AccountClassDtoV3[]> {
        const uri = `/sales-reps/${id}/account-classes`
        const response = await this.doApiGet<AccountClassDtoV3[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getSalesRepsForForecastLock (accounts: DropdownOption[], vendors: DropdownOption[], productAttributeId: number|null, productAttributeOptionId: number|null): Promise<SalesRepDtoV3[]> {
        const uri = '/sales-reps-for-forecast-lock'
        const response = await this.doApiPost<SalesRepDtoV3[]>(uri, JSON.stringify({
            accounts: accounts.map(a => a.value),
            vendors: vendors.map(v => v.value),
            productAttributeId: productAttributeId,
            productAttributeOptionId: productAttributeOptionId,
        }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new SalesRepService()
