import { BillOfMaterialDto, ForecastSummaryType, PaginationRequest, CurvesDto, WorksheetMonthSummaryDto, ProductAttributeOptionDto,
  ForecastFilter, SalesRepDtoV3, ProductDto, ProductAttributeDto, VendorProductDto, ProductFilter, ShopifyApiCredential,
  VendorDto, VendorLocationDto, PaginationResult, ShippingMethodDto, ForecastLockRule } from '@basic-code/shared'

export interface MonthMap {
  [index: string]: number;
}

export interface WorksheetLayoutCollection {
  collection: string;
  id: string;
  title?: string;
  warningMessage?: WorksheetWarningMessage;
  url?: string;
  target?: string;
  hideYtd?: boolean;
  hideZeros?: boolean;
  hideTotal?: boolean;
  hidePast?: boolean;
  flagNegatives?: boolean;
}

export interface Planner {
  planner: string;
  productIds: number[];
}

export interface AdminViewDto {
  id: number;
  schema: string;
  name: string;
  sqlStatement: string;
  createOrder: number|null;
}

export interface ViewDto {
  id: number;
  schema: string;
  name: string;
  sqlStatement: string;
  isCustomView: boolean;
  createOrder: number|null;
}

export interface FormAddEditVendorProductDto {
  id?: number;
  productId: number;
  vendorId: number;
  vendorSku: string;
  priority: number|null;
  purchaseLeadTime: number|null;
  contractedPrice: number|null;
  poLineItemMinOrderQty: number|string|null;
  poLineItemMaxOrderQty: number|string|null;
  poLineItemMinOrderAmt: number|null;
  poLineItemMaxOrderAmt: number|null;
  orderIncrement: number|null;
  packedIncrement: number|null;
}

export interface FormAddEditVendorLocationDto {
  vendorId: number;
  name: string;
  contactPerson: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  email: string;
  phone: string;
}

export interface FormAddEditVendorDto {
  id?: number;
  vendorCode: string;
  name: string;
  poMinOrderQty: number|string|null;
  poMaxOrderQty: number|string|null;
  poMinOrderAmt: number|null;
  poMaxOrderAmt: number|null;
  notes: string;
}

export interface FormAddEditShippingMethodDto {
  name: string;
  priority: number|null;
}

export interface SearchSku {
  productId: number;
  sku: string;
  description: string;
  productGroupId?: number;
  replaceProjectedForecast?: boolean;
  skuStatus?: string;
  id?: number;
}

export enum ItemType {
  adj = 'adj',
  flow = 'flow',
  usage = 'usage',
  shipment = 'shipment',
  grand = 'grand',
  origFc = 'origFc',
}

export enum ItemTotalType {
  ytd = 'ytd',
  total = 'total'
}

export enum MonthTotalType {
  accountForecastMonth = 'accountForecastMonth',
  forecastSummaryMonth = 'forecastSummaryMonth',
  plannerForecastDto = 'plannerForecastDto',
  forecastMonthData = 'forecastMonthData'
}

// todo Define more messages as we need them
export enum WorksheetWarningMessage {
  beginningOnHand = '',
  inventoryOnHand = '',
  purchaseOrders = '',
  salesOrders = '',
  shipments = '',
  projectedForecast = 'Projected Forecast replaced with Account Forecast',
  projectedForecastOnly = '',
  addBomTotals = 'Act/Est BOH, Estimated EOH, and Forecast numbers include BOM usage',
  endingOnHand = '',
  bomUsage = '',
  projectedForecastAndAddBomTotals = 'Projected Forecast replaced with Account Forecast; Act/Est BOH, Estimated EOH, and Forecast numbers include BOM usage'
}

export interface MonthTotal {
  accountForecastMonth: MonthTotalAccountForecastMonth;
  forecastSummaryMonth: MonthTotalForecastSummaryMonth;
  plannerForecastDto: MonthTotalPlannerForecastDto;
  [index: string]: string|MonthTotalAccountForecastMonth|MonthTotalForecastSummaryMonth|MonthTotalPlannerForecastDto;
}

export interface MonthTotalAccountForecastMonth {
  adj: string;
  flow: string;
  shipment: string;
  [index: string]: string;
}

export interface MonthTotalForecastSummaryMonth {
  adj: string;
  flow: string;
  shipment: string;
  usage: string;
  origFc: string;
  grand: string;
  [index: string]: string;
}

export interface MonthTotalPlannerForecastDto {
  adj: string;
  flow: string;
  shipment: string;
  usage: string;
  [index: string]: string;
}

export interface CollapseOptions {
  projectedOrAccountForecast: boolean;
  lyboh: boolean;
  originalForecast: boolean;
  projectedForecast: boolean;
}

export interface ProductAttributeValueInformation {
  attributeValueId: number;
  attributeId: number;
  attributeName: string;
  attributeOption: string;
  isExternal: boolean;
}

export interface WorksheetLayoutItem {
  collection: string;
  id: string;
  title?: string;
  url?: string;
  target?: string;
  hideZeros?: boolean;
  hideTotal?: boolean;
  hideYtd?: boolean;
  hidePast?: boolean;
  flagNegatives?: boolean;
  warningMessage?: WorksheetWarningMessage;
}

export interface SkuAccounts {
  sku: string;
  description: string;
  account: string;
  accountClass: string;
  skuStatus: string;
  productId: string;
  qtyShipped: number;
  salesRepId: number;
  name: string;
  email: string;
}

export interface NewForecast {
  accountId: number;
  productId: number;
  skuStatus: string;
  accountClassId: number;
  salesRep: SalesRepDtoV3;
}

export interface SkuFlowDependencies {
  curves: CurvesDto;
  sales: WorksheetMonthSummaryDto;
}

export interface FilterSettings {
  filters: ProductFilter[];
  sortDirection: string;
}

export interface FilterChangeEventData {
    filters: ProductFilter[];
    filterProperties: string[];
}

export class UIForecastFilterDto {
  showAccountsWithNoForecast: boolean;
  salesRep?: number;
  accounts?: number[];
  accountClassId?: number;
  accountClassName?: string;
  products?: Partial<ProductDto>[];
  productGroupId?: number;
  productGroupName?: string;

  public static create(existing?: UIForecastFilterDto): UIForecastFilterDto {
    const dto = new UIForecastFilterDto()
    if (existing) {
      dto.showAccountsWithNoForecast = existing.showAccountsWithNoForecast
      dto.accounts = existing.accounts
      dto.accountClassId = existing.accountClassId
      dto.accountClassName = existing.accountClassName
      dto.productGroupId = existing.productGroupId
      dto.productGroupName = existing.productGroupName
      dto.products = existing.products
      dto.salesRep = existing.salesRep
    } else {
      dto.showAccountsWithNoForecast = false
      dto.accounts = []
      dto.accountClassId = 0
      dto.accountClassName = ''
      dto.productGroupId = 0
      dto.productGroupName = ''
      dto.products = []
    }
    return dto
  }

  public clone(): UIForecastFilterDto {
    const dto = new UIForecastFilterDto()
    dto.showAccountsWithNoForecast = this.showAccountsWithNoForecast
    dto.accounts = Object.assign([], this.accounts)
    dto.accountClassId = this.accountClassId
    dto.accountClassName = this.accountClassName
    dto.products = Object.assign([], this.products)
    dto.productGroupId = this.productGroupId
    dto.productGroupName = this.productGroupName
    dto.salesRep = this.salesRep
    return dto
  }

  public toForecastFilter(type: ForecastSummaryType, pagination?: PaginationRequest): ForecastFilter {
    return {
      accounts: this.accounts || [],
      products: this.products ? this.products!.map(p => p.id!) : [],
      showAccountsWithNoForecast: this.showAccountsWithNoForecast,
      productGroups: this.productGroupId ? [this.productGroupId] : [],
      salesRep: this.salesRep,
      accountClass: this.accountClassId,
      type: type,
      pagination: pagination || { page: 1, perPage: 10 }
    }
  }

}

export enum ChangeType {
  Create = 'create',
  Update = 'update',
  Delete = 'delete'
}

export interface BomChangeEvent {
  component: BillOfMaterialDto;
  changeType: ChangeType;
  index: number;
}

export interface ProductAttributeOptionChangeEvent {
  productAttributeOption: ProductAttributeOptionDto;
  changeType: ChangeType;
  productAttributeId: number;
}

export interface ProductAttributeChangeEvent {
  productAttribute: ProductAttributeDto;
  changeType: ChangeType;
  oldSortOrder: number;
}

export interface VendorFilter {
  vendor: VendorDto;
  filteredVendorProducts: PaginationResult<VendorProductDto[]>;
  filteredVendorLocations: PaginationResult<VendorLocationDto[]>;
}

export interface ShippingMethodFilter {
  shippingMethod: ShippingMethodDto;
}

export interface VendorProductChangeEvent {
  vendorProduct: VendorProductDto;
  changeType: ChangeType;
  index: number;
  lastAdd: boolean;
  numDuplicates: number;
  oldPriority: number;
}

export interface VendorChangeEvent {
  vendor: VendorDto;
  changeType: ChangeType;
  index: number;
}

export interface VendorLocationChangeEvent {
  vendorId: number;
  vendorLocation: VendorLocationDto;
  changeType: ChangeType;
}

export interface ShopifyApiCredentialsChangeEvent {
  shopifyApiCredential: ShopifyApiCredential|null;
  changeType: ChangeType;
  index: number;
}

export interface ForecastLockRulesChangeEvent {
  forecastLockRule: ForecastLockRule|null;
  changeType: ChangeType;
  isMainLock: boolean;
}

/**
 * Type to support year-based navigation.
 */
export interface PanelNavigationYear {
  year: number;
  index: number;
  propertyName: string;
  offsetMonths: number;
}

/**
 * yearIterator contains only the year names that need to be iterated over in a v-for loop, especially excluding 'lastYear'
 *
 * yearNames will contain thisYear, year1, etc. as needed, with lastYear at THE END.
 * It is a string[], but in implementation is a JS Proxy of a string[] that allows using negative indexes.
 * In this way, UI v-for loops can always utilized [yearIndex - 1] to access the previous year's data, such as last year's shipments.
 * See common.ts for the implementation of this.
 */
export interface ForecastEditorYearMapping {
  yearIterator: string[];
  yearNames: string[];
}

export interface RawRule {
  action: string;
  subject: string;
  /** an array of fields to which user has (or not) access */
  fields?: string[];
  /** an object of conditions which restricts the rule scope */
  conditions?: any;
  /** indicates whether rule allows or forbids something */
  inverted?: boolean;
  /** message which explains why rule is forbidden */
  reason?: string;
}

export enum DownloadUploadType {
  MANUAL = 'Manual Account Forecasts',
  SKU_CURVE = 'SKU Curve Account Forecasts',
  PLANNER = 'Planner Projected Forecasts'
}

export enum FileImportName {
  PRODUCT_ACCOUNT_FORECASTS = 'product_account_forecasts',
  PLANNER_FORECASTS = 'planner_forecasts'

}