import { VueAuth } from '@/auth/vue-auth'
import { EventBus, InterWindowEvents, SafioEvents, WindowBus } from '@/services/event-bus'
import ForecastEditService from '@/services/forecast-edit-service'
import ProductService from '@/services/v3/product-service'
import SalesRepService from '@/services/v3/sales-rep-service'
import { UIForecastFilterDto } from '@/types'
import Common from '@/util/common'
import DtoFactory from '@/util/dto-factory'
import CommonForecastHelper from "@/util/forecastCalculations/common-forecast-helper"
import {
  AccountClassDtoV3,
  AccountDto,
  AccountForecastEdit,
  AccountForecastMonth,
  AccountForecastYear,
  ChildBillOfMaterialInfoDto,
  FlowType,
  Forecast,
  ForecastEditFilterData,
  ForecastEditorState,
  ForecastSummaryType,
  ForecastTotalDisplayDto,
  PaginationResult,
  ParentBillOfMaterialInfoDto,
  PlannerForecastDto,
  PlannerForecastEdit,
  ProductDto,
  SalesRepDtoV3,
  SystemSettingDto
} from '@basic-code/shared'
import { subject as authSubject } from '@casl/ability'
import { Guid } from 'guid-typescript'
import _ from 'lodash'
import { Vue } from 'vue-property-decorator'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { ForecastEditState, RootState } from '../types'

export const state: ForecastEditState = {
  activeTabIndex: -1,
  filterApplied: false,
  filter: UIForecastFilterDto.create(),
  mode: ForecastSummaryType.Sku,
  originalLoadComplete: false,
  filterData: {
    accountClasses: [],
    accounts: [],
    salesReps: [],
    productGroups: []
  },
  plannerForecasts: PlannerForecastEdit.create(),
  accountForecasts: DtoFactory.placeholderPaginationResult(),
  accountForecastTotals: DtoFactory.blankForecastTotalDisplayDto(),
  loadingAccountForecasts: false,
  loadingAddAccountForecast: false,
  loadingAccountForecastTotals: false,
  loadingAllProducts: false,
  loadingPlannerForecasts: false,
  loadingAccountClasses: false,
  loadingChildForecastTotals: false,
  loadingChildForecasts: false,
  loadingParentForecastTotals: false,
  loadingParentForecasts: false,
  savingAccountForecast: false,
  activeAccountForecastDirty: false,
  activeAccountForecastIndex: -1,
  originalAccountForecastEdit: null,
  originalAccountForecastTotals: null,
  originalPlannerForecasts: null,
  plannerForecastDirty: false,
  savingPlannerForecast: false,
  salesRepAccountClasses: [],
  salesRepAccounts: [],
  initialSku: '',
  initialProduct: null,
  replaceProjectedForecast: false,
  initialTenantSalesRep: null,
  paginatedChildForecastBoms: DtoFactory.placeholderPaginationResult(),
  paginatedParentForecastBoms: DtoFactory.placeholderPaginationResult(),
  childForecastBoms: [],
  parentForecastBoms: [],
  parentForecastBomTotals: null,
  forecast: null,
}

export const getLatestUpdatedMonth = (thisState: ForecastEditState): PlannerForecastDto | undefined => {
  let lastUpdatedMonth: PlannerForecastDto | undefined;

  if (thisState.plannerForecasts?.forecast?.length > 0) {
    thisState.plannerForecasts.forecast.forEach(yearlyForecast => {
      yearlyForecast.forecastMonths.forEach(monthForecast => {
        if (
          !lastUpdatedMonth ||
          (lastUpdatedMonth.updatedAt && monthForecast.updatedAt && lastUpdatedMonth.updatedAt < monthForecast.updatedAt)
        ) {
          lastUpdatedMonth = monthForecast;
        }
      });

    });
  }

  return lastUpdatedMonth;
};


const getters: GetterTree<ForecastEditState, RootState> = {
  isValidFilter(state): boolean {
    const hasProducts = (state.filter.products?.length || 0) > 0
    const hasAccounts = (state.filter.accounts?.length || 0) > 0
    const hasProductGroups = !!state.filter.productGroupId
    return hasProducts || hasAccounts || hasProductGroups
  },

  isEditing(state): boolean {
    return state.activeAccountForecastIndex >= 0
  },

  getForecast(state): Forecast | null {
    return state.forecast
  },

  getForecastTotals(state): ForecastTotalDisplayDto {
    return state.accountForecastTotals
  },

  replaceProjectedForecast(state): boolean {
    return state.replaceProjectedForecast
  },

  filteredAccount(state): AccountDto {
    if (state.filter.accounts?.length && state.filterData.accounts.length) {
      const acct = state.filterData.accounts.find(a => a.id === state.filter.accounts![0])
      return acct || AccountDto.create()
    }
    return AccountDto.create()
  },

  plannerUpdatedBy(state): string {
    const latestUpdatedMonth = getLatestUpdatedMonth(state)
    if (latestUpdatedMonth?.updatedBy)
      return latestUpdatedMonth.updatedBy
    return 'N/A'
  },

  plannerUpdatedAt(state): Date | string {
    const latestUpdatedMonth = getLatestUpdatedMonth(state)
    if (latestUpdatedMonth?.updatedAt)
      return latestUpdatedMonth.updatedAt
    return 'N/A'
  },

  plannerForecastDirty(state): boolean {
    return state.plannerForecastDirty
  },

  isPlannerForecastSaving(state): boolean {
    return state.savingPlannerForecast
  },
}

// actions can be synchronous or asynchronous
const actions: ActionTree<ForecastEditState, RootState> = {
  async getForecast({ commit }, payload: number) {
    commit('toggleLoadingParentForecasts')
    commit('toggleLoadingChildForecasts')
    const forecast = await ProductService.getForecast(payload)
    if (forecast) {
      commit('setForecast', forecast)
    }
    commit('toggleLoadingParentForecasts')
    commit('toggleLoadingChildForecasts')
  },

  getPaginatedBoms({ commit, state }, payload: { bomType: string; pageNum: number; perPage: number }) {
    if (payload.bomType === 'child') {
      commit('toggleLoadingChildForecasts')
    } else {
      commit('toggleLoadingParentForecasts')
    }

    const perPage = payload.perPage
    const totalRecords = payload.bomType === 'child' ? state.childForecastBoms.length : state.parentForecastBoms.length
    const startIndex = (payload.pageNum - 1) * perPage
    const endIndex = startIndex + perPage
    const data = payload.bomType === 'child' ? state.childForecastBoms.slice(startIndex, endIndex) : state.parentForecastBoms.slice(startIndex, endIndex)
    const paginatedBom = {
      currentPage: payload.pageNum,
      data: data,
      perPage: perPage,
      totalPages: Math.ceil(totalRecords / perPage),
      totalRecords: totalRecords,
    }

    if (payload.bomType === 'child') {
      commit('setPaginatedChildBoms', paginatedBom)
      commit('toggleLoadingChildForecasts')
    } else {
      commit('setPaginatedParentBoms', paginatedBom)
      commit('toggleLoadingParentForecasts')
    }
  },

  async getChildBom({ commit, dispatch }, payload: { productId: number }) {
    commit('toggleLoadingChildForecasts')
    const childBoms = await ProductService.getChildBom(payload.productId)
    if (childBoms) {
      commit('setChildBoms', childBoms)
      commit('toggleLoadingChildForecasts')
      dispatch('getPaginatedBoms', { bomType: 'child', pageNum: 1, perPage: 5 })
    } else {
      commit('toggleLoadingChildForecasts')
    }
  },

  async getParentBom({ commit, dispatch }, payload: { productId: number }) {
    commit('toggleLoadingParentForecasts')
    const parentBom = await ProductService.getParentBom(payload.productId)
    if (parentBom) {
      commit('setParentBoms', parentBom)
      commit('toggleLoadingParentForecasts')
      dispatch('getPaginatedBoms', { bomType: 'parent', pageNum: 1, perPage: 5 })
      await dispatch('getParentTotals')
    } else {
      commit('toggleLoadingParentForecasts')
    }
  },

  async getParentTotals({ state, commit }) {
    commit('toggleLoadingParentForecastTotals')
    const parentForecastBoms = _.cloneDeep(state.parentForecastBoms)

    if (parentForecastBoms.length) {
      const parentForecastTotals = CommonForecastHelper.getParentBomForecastTotalObjects(parentForecastBoms);
      commit('setParentBomTotals', parentForecastTotals)
    }

    commit('toggleLoadingParentForecastTotals')
  },

  async getInitialTenantSalesRep({ commit }, payload: string) {
    const salesRep = await SalesRepService.getSalesRepByEmail(payload)
    if (salesRep) {
      commit('setInitialTenantSalesRep', salesRep)
    }
  },

  async productByProductId({ state }, payload: number) {
    return await ProductService.getByProductId(payload, true)
  },

  async productBySku({ state }, payload: string) {
    return await ProductService.getBySku(payload, false, true)
  },

  async getFilterData({ commit }) {
    try {
      const data = await ForecastEditService.getForecastEditFilterData()
      commit('setFilterData', data)
    } catch (err) {
      console.error(err)
    }
  },

  async applyFilters({ state, dispatch }) {
    if (!state.filter) {
      return
    }

    await dispatch('loadEditorData')
    await dispatch('getEditorTotals')
    if (state.mode === ForecastSummaryType.Sku) {
      await dispatch('getPlannerForecast')
    }

    if (state.mode === ForecastSummaryType.Account || state.mode === ForecastSummaryType.Product) {
      EventBus.$emit(SafioEvents.accountOrProductGroupFilterApplied, state.filter)
    } else if (state.mode === ForecastSummaryType.ShipmentsNoForecast) {
      EventBus.$emit(SafioEvents.shipmentsNoForecastFilterApplied, state.filter)
    }
  },

  async filterAccountClassesBySalesRep({ state, commit }) {
    const salesRepId = state.filter.salesRep
    if (salesRepId !== undefined) {
      commit('toggleLoadingAccountClasses')
      try {
        const accountClasses = await SalesRepService.getSalesRepAccountClasses(salesRepId)
        commit('setSalesRepAccountClasses', accountClasses)
        const accounts: AccountDto[] = []
        for (const accountClass of accountClasses) {
          if (accountClass.account && !accounts.find(a => a.name === accountClass.account!.name)) {
            accounts.push(accountClass.account)
          }
        }
        commit('setSalesRepAccounts', accounts)
      } catch (err) {
        console.error(err)
      } finally {
        commit('toggleLoadingAccountClasses')
      }
    }
  },

  async getPlannerForecast({ commit, state }) {
    try {
      commit('toggleLoadingPlannerForecasts')
      commit('setPlannerForecasts', PlannerForecastEdit.create())
      const filter = state.filter.toForecastFilter(state.mode)
      const data = await ForecastEditService.getEditorData('planner', filter) as PlannerForecastEdit
      commit('setPlannerForecasts', authSubject('forecast', data))
    } catch (err) {
      console.error(err)
    } finally {
      commit('toggleLoadingPlannerForecasts')
    }
  },

  async loadEditorData({ commit, state }) {
    try {
      // if statement helps account for if the user clicks the By Account tab prior to the page fully loading
      if (state.mode !== 'account' || (state.mode === 'account' && state.filter && state.filter.accounts && state.filter.accounts.length > 0)) {
        commit('toggleLoadingAccountForecasts')
        commit('setAccountForecasts', DtoFactory.placeholderPaginationResult(state.accountForecasts))
        const editorType = state.mode === ForecastSummaryType.Account ? 'sku' : 'account'
        const filter = state.filter.toForecastFilter(state.mode, { page: state.accountForecasts.currentPage, perPage: state.accountForecasts.perPage })
        const data = await ForecastEditService.getEditorData(editorType, filter) as PaginationResult<AccountForecastEdit[]>
        commit('setAccountForecasts', data)
        if (state.mode === ForecastSummaryType.Sku && state.filter.products?.length) {
          WindowBus.$emit(InterWindowEvents.skuSelected, state.filter.products[0])
        }
      }
    } catch (err) {
      console.error(err)
    } finally {
      if (state.mode !== 'account' || (state.mode === 'account' && state.filter && state.filter.accounts && state.filter.accounts.length > 0)) {
        commit('toggleLoadingAccountForecasts')
      }
    }
  },

  async getEditorTotals({ commit, state }) {
    try {
      // if statement helps account for if the user clicks the By Account tab prior to the page fully loading
      if (state.mode !== 'account' || (state.mode === 'account' && state.filter && state.filter.accounts && state.filter.accounts.length > 0)) {
        commit('toggleLoadingAccountForecastTotals')
        const rollupType = state.mode === ForecastSummaryType.Sku ? 'account' : 'sku'
        const filter = state.filter.toForecastFilter(state.mode)
        const totals = await ForecastEditService.getAggregateRollupData(rollupType, filter)
        commit('setAccountForecastTotals', totals)
      }
    } catch (err) {
      console.error(err)
    } finally {
      if (state.mode !== 'account' || (state.mode === 'account' && state.filter && state.filter.accounts && state.filter.accounts.length > 0)) {
        commit('toggleLoadingAccountForecastTotals')
        EventBus.$emit(SafioEvents.replaceProjectedForecastChange, state.replaceProjectedForecast)
      }
    }
  },

  async addNewForecast({ commit, state }) {
    try {
      commit('toggleLoadingNewAccountForecast')

      let id = 0
      if (state.activeTabIndex === 0) {
        if (state.filter.products?.length) {
          id = state.filter.products[0].id!
          const afe = await ForecastEditService.getNewAccountForecast(id)
          commit('addNewAccountForecast', afe)
        }
      } else if (state.activeTabIndex === 1) {
        if (state.filter.accounts?.length) {
          id = state.filter.accounts[0]
          const afe = await ForecastEditService.getNewSkuForecast(id)
          commit('addNewAccountForecast', afe)
        }
      }
    } catch (err) {
      console.error(err)
    } finally {
      commit('toggleLoadingNewAccountForecast')
    }
  },

  async deleteAccountForecast({ commit, dispatch }, payload: AccountForecastEdit) {
    try {
      commit('toggleSavingAccountForecast')
      await ForecastEditService.deleteProductAccountForecast(payload.id!)
      commit('removeAccountForecast', payload)
      await dispatch('getEditorTotals')
      return true
    } catch (err) {
      console.error(err)
      return false
    } finally {
      commit('toggleSavingAccountForecast')
    }
  },

  clearAccountForecast({ state, rootState }, { updatedForecast, includeFlow, vueAuth, product, accountForecast }: { updatedForecast: AccountForecastEdit; includeFlow: boolean; vueAuth: VueAuth; product: ProductDto; accountForecast: AccountForecastEdit }) {
    const settings = rootState.settings
    const currentFiscalYear = Common.getCurrentFiscalYear(settings.fiscalMonth)
    const clearAfys = function (afys: AccountForecastYear[]) {
      for (const afy of afys) {
        if (afy.fiscalYear >= currentFiscalYear) {
          if (includeFlow) {
            afy.flowTotal = 0
          }
          afy.adjustmentTotal = 0
          afy.grandTotal = 0
          for (const afm of afy.forecastMonths) {
            const forecastMonthLockRulesMet = Common.areAnyForecastMonthLockRulesMet(afm.month, afm.calendarYear, settings, vueAuth, state.initialTenantSalesRep, product, [accountForecast])
            const forecastMonthOverrideRulesMet = Common.areAllForecastOverrideRulesMet(settings, vueAuth, state.initialTenantSalesRep, product, [accountForecast])

            if (includeFlow) {
              afm.flow = (forecastMonthLockRulesMet && !forecastMonthOverrideRulesMet) ? afm.flow : 0
            }
            afm.adjustments = forecastMonthLockRulesMet ? afm.adjustments : 0
            afm.forecastTotal = forecastMonthLockRulesMet ? afm.forecastTotal : 0
          }
        }
      }
    }

    const afys = state.accountForecasts.data[state.activeAccountForecastIndex].forecast
    clearAfys(afys)

    // have to re-set the AFE to trigger Vue updates.
    Vue.set(state.accountForecasts.data, state.activeAccountForecastIndex, state.accountForecasts.data[state.activeAccountForecastIndex])

    for (const afy of afys) {
      let fcYear = updatedForecast.forecast.find(af => af.fiscalYear === afy.fiscalYear)
      if (!fcYear && afy.fiscalYear >= currentFiscalYear) {
        fcYear = AccountForecastYear.create(afy.fiscalYear)
        for (const afm of afy.forecastMonths) {
          fcYear.forecastMonths.push(_.cloneDeep(afm))
        }
        updatedForecast.forecast.push(fcYear)
      }
    }

    clearAfys(updatedForecast.forecast)
  },
}

// mutations are always synchronous, and directly modify state
const mutations: MutationTree<ForecastEditState> = {
  setForecast(state, payload: Forecast) {
    state.forecast = payload
  },

  setPaginatedChildBoms(state, payload: PaginationResult<ChildBillOfMaterialInfoDto[]>) {
    state.paginatedChildForecastBoms = payload
  },

  setPaginatedParentBoms(state, payload: PaginationResult<ParentBillOfMaterialInfoDto[]>) {
    state.paginatedParentForecastBoms = payload
  },

  setChildBoms(state, payload: ChildBillOfMaterialInfoDto[]) {
    state.childForecastBoms = payload
  },

  setParentBoms(state, payload: ParentBillOfMaterialInfoDto[]) {
    state.parentForecastBoms = payload
  },

  setParentBomTotals(state, payload: PlannerForecastEdit) {
    state.parentForecastBomTotals = payload
  },

  setInitialTenantSalesRep(state, payload: SalesRepDtoV3 | null) {
    state.initialTenantSalesRep = payload
  },

  setActiveTab(state, payload: number) {
    state.activeTabIndex = payload
    state.filterApplied = false
  },

  setInitialSku(state, payload: string) {
    state.initialSku = payload
  },

  setInitialProduct(state, payload: ProductDto) {
    state.initialProduct = payload
    Vue.set(state.filter, 'products', [payload])
  },

  setReplaceProjectedForecast(state, payload: boolean) {
    state.replaceProjectedForecast = payload
    Vue.set(state, 'replaceProjectedForecast', payload)
  },

  clearFilter(state, payload) {
    if (payload.clearProducts) {
      state.filter.products = []
    }

    const salesRep = state.filter.salesRep
    state.filter = UIForecastFilterDto.create()

    // If this user is a sales rep, they can't clear the sales rep filter.
    if (payload.$auth && payload.$auth.user.isTenantSalesRep()) {
      state.filter.salesRep = salesRep
    }

    state.accountForecasts = DtoFactory.placeholderPaginationResult()
    state.accountForecastTotals = DtoFactory.blankForecastTotalDisplayDto()
    state.loadingAccountForecasts = false
    state.loadingAddAccountForecast = false
    state.loadingChildForecastTotals = false
    state.loadingChildForecasts = false
    state.loadingParentForecastTotals = false
    state.loadingParentForecasts = false
    state.activeAccountForecastDirty = false
    state.activeAccountForecastIndex = -1
    state.originalAccountForecastEdit = null
    state.originalAccountForecastTotals = null
    EventBus.$emit(SafioEvents.forecastFilterClear)
  },

  setFilter(state, payload: UIForecastFilterDto) {
    state.filter = UIForecastFilterDto.create({ ...payload } as UIForecastFilterDto)
  },

  setFilterProducts(state, payload: ProductDto[]) {
    Vue.set(state.filter, 'products', payload)
  },

  setFilterSalesRep(state, payload?: number) {
    state.filter.salesRep = payload
    Vue.set(state, 'filter', state.filter)
  },

  setMode(state, payload: ForecastSummaryType) {
    state.mode = payload
    switch (state.mode) {
      case ForecastSummaryType.Sku:
        state.filter.productGroupId = undefined
        state.filter.accountClassId = undefined
        state.filter.accounts = []
        break
      case ForecastSummaryType.Account:
        state.filter.products = []
        state.filter.productGroupId = undefined
        break
      case ForecastSummaryType.Product:
        state.filter.products = []
        state.filter.accountClassId = undefined
        state.filter.accounts = []
        break
      case ForecastSummaryType.ShipmentsNoForecast:
        break
    }
    Vue.set(state, 'filter', state.filter) // trigger refresh
  },

  setFilterData(state, payload: ForecastEditFilterData) {
    state.filterData = payload
  },

  setAccountForecasts(state, payload: PaginationResult<AccountForecastEdit[]>) {
    state.accountForecasts = payload
    state.accountForecasts.data.map(af => {
      af.guid = Guid.create()
      return authSubject('forecast', af)
    })
    state.activeAccountForecastIndex = -1
    state.originalAccountForecastEdit = null
    state.filterApplied = true
  },

  addAccountForecast(state) {
    state.accountForecasts.totalRecords = (typeof state.accountForecasts.totalRecords === 'string' ? parseInt(state.accountForecasts.totalRecords) : state.accountForecasts.totalRecords) + 1
    state.accountForecasts.totalPages = Math.ceil(state.accountForecasts.totalRecords / state.accountForecasts.perPage)
  },

  setSalesRepAccountClasses(state, payload: AccountClassDtoV3[]) {
    state.salesRepAccountClasses = payload
  },

  setSalesRepAccounts(state, payload: AccountDto[]) {
    state.salesRepAccounts = payload
  },

  toggleLoadingAccountClasses(state) {
    state.loadingAccountClasses = !state.loadingAccountClasses
  },

  toggleLoadingAccountForecasts(state) {
    state.loadingAccountForecasts = !state.loadingAccountForecasts
  },

  toggleLoadingNewAccountForecast(state) {
    state.loadingAddAccountForecast = !state.loadingAddAccountForecast
  },

  toggleLoadingAccountForecastTotals(state) {
    state.loadingAccountForecastTotals = !state.loadingAccountForecastTotals
  },

  toggleLoadingChildForecastTotals(state) {
    state.loadingChildForecastTotals = !state.loadingChildForecastTotals
  },

  toggleLoadingChildForecasts(state) {
    state.loadingChildForecasts = !state.loadingChildForecasts
  },

  toggleLoadingParentForecastTotals(state) {
    state.loadingParentForecastTotals = !state.loadingParentForecastTotals
  },

  toggleLoadingParentForecasts(state) {
    state.loadingParentForecasts = !state.loadingParentForecasts
  },

  toggleSavingAccountForecast(state) {
    state.savingAccountForecast = !state.savingAccountForecast
  },

  toggleLoadingAllProducts(state) {
    state.loadingAllProducts = !state.loadingAllProducts
  },

  editAccountForecast(state, payload: AccountForecastEdit) {
    const index = state.accountForecasts.data.findIndex(paf => paf.id! === payload.id)
    state.activeAccountForecastIndex = index
    // Vue.set(state, 'activeAccountForecastIndex', index)
    state.originalAccountForecastEdit = authSubject('forecast', _.cloneDeep(state.accountForecasts.data[index]))
    state.originalAccountForecastTotals = _.cloneDeep(state.accountForecastTotals)
    Vue.set(state.accountForecasts.data[index], 'editorState', ForecastEditorState.Editing)
  },

  cancelEditingAccountForecast(state) {
    if (state.activeAccountForecastIndex >= 0) {
      if (state.originalAccountForecastEdit) {
        state.accountForecasts.data.splice(state.activeAccountForecastIndex, 1, authSubject('forecast', state.originalAccountForecastEdit))
        state.accountForecastTotals = state.originalAccountForecastTotals!
      }
      // If creating a new record.
      if (state.activeAccountForecastIndex === 0 && (!state.accountForecasts.data[0].id || state.accountForecasts.data[0].id === 0)) {
        state.accountForecasts.data.shift() // remove the newly added record from the beginning of the array.
      }
      state.activeAccountForecastIndex = -1
    }
    state.originalAccountForecastEdit = null
    state.originalAccountForecastTotals = null
    state.activeAccountForecastDirty = false
  },

  revertAccountForecast(state) {
    if (state.activeAccountForecastIndex >= 0 && state.originalAccountForecastEdit) {
      const fcEdit = authSubject('forecast', _.cloneDeep(state.originalAccountForecastEdit))
      fcEdit.editorState = ForecastEditorState.Editing
      Vue.set(state.accountForecasts.data, state.activeAccountForecastIndex, fcEdit)
      state.accountForecastTotals = _.cloneDeep(state.originalAccountForecastTotals!)
      state.activeAccountForecastDirty = false
    }
  },

  markAccountForecastDirty(state) {
    state.activeAccountForecastDirty = true
    EventBus.$emit(SafioEvents.forecastChangeDirty, true)
  },

  stopEditingAccountForecastAfterSave(state) {
    Vue.set(state, 'originalAccountForecastEdit', null)
    Vue.set(state, 'originalAccountForecastTotals', null)
    state.activeAccountForecastDirty = false
    Vue.set(state.accountForecasts.data[state.activeAccountForecastIndex], 'editorState', ForecastEditorState.Off)
    state.activeAccountForecastIndex = -1
  },

  refreshAccountForecastAfterSave(state, payload: AccountForecastEdit) {
    if (state.activeAccountForecastIndex >= 0) {
      state.accountForecasts.data.splice(state.activeAccountForecastIndex, 1, authSubject('forecast', payload))
      state.activeAccountForecastIndex = -1
    }
    Vue.set(state, 'originalAccountForecastEdit', null)
    Vue.set(state, 'originalAccountForecastTotals', null)
    state.activeAccountForecastDirty = false
  },

  addNewAccountForecast(state, payload: AccountForecastEdit) {
    state.accountForecasts.data.unshift(authSubject('forecast', payload))
    state.activeAccountForecastIndex = 0
    Vue.set(state, 'originalAccountForecastEdit', _.cloneDeep(payload))
    Vue.set(state, 'originalAccountForecastTotals', _.cloneDeep(state.accountForecastTotals))
    state.activeAccountForecastDirty = false
  },

  removeAccountForecast(state, payload: AccountForecastEdit) {
    const index = state.accountForecasts.data.findIndex(af => af.id === payload.id)
    if (index >= 0) {
      state.accountForecasts.data.splice(index, 1)
    }
  },

  /**
   *
   * @param state
   * @param payload
   */
  updateDataFromUpload(state, { settings, selectedFlowType, headers, adjustments, flow, forecastId, vueAuth, product, accountForecasts }:
    {
      settings: SystemSettingDto; selectedFlowType: FlowType; headers: string[]; adjustments: number[]; flow: number[];
      forecastId: number; vueAuth: VueAuth; product: ProductDto; accountForecasts: AccountForecastEdit[];
    }) {
    const existingIndex = state.accountForecasts.data.findIndex(af => af.id === forecastId)
    const existing = state.accountForecasts.data[existingIndex]
    if (existing) {
      let header: string[]
      let month: number
      let year: number
      let fiscalYear: number
      let newAdj: number
      let newFlow: number
      const useFlow = selectedFlowType === FlowType.Manual || selectedFlowType === FlowType.LyShipments

      for (const fc of existing.forecast) {
        fc.adjustmentTotal = 0
        if (useFlow) {
          fc.flowTotal = 0
        }
      }

      for (let index = 0; index < headers.length; index++) {
        header = headers[index].split(' \'')
        month = Common.monthAbbrs.indexOf(header[0].toLowerCase())
        year = Number(header[1])
        if (month < 0 || isNaN(year)) {
          continue // Non month column.  skip it.
        }
        year += 2000
        fiscalYear = Common.getFiscalYear(year, month, settings.fiscalMonth)
        newAdj = adjustments[index]
        newFlow = flow[index]

        const afy = existing.forecast.find(afy => afy.fiscalYear === fiscalYear)
        const afm = afy?.forecastMonths.find(afm => afm.monthName === Common.monthAbbrs[month])
        if (afm) {
          const forecastMonthLockRulesMet = Common.areAnyForecastMonthLockRulesMet(month, year, settings, vueAuth, state.initialTenantSalesRep, product, accountForecasts)
          const forecastMonthOverrideRulesMet = Common.areAllForecastOverrideRulesMet(settings, vueAuth, state.initialTenantSalesRep, product, accountForecasts)

          afm.adjustments = (forecastMonthLockRulesMet && !forecastMonthOverrideRulesMet) ? afm.adjustments : newAdj
          afy!.adjustmentTotal += (forecastMonthLockRulesMet && !forecastMonthOverrideRulesMet) ? afm.adjustments : newAdj
          if (useFlow) {
            afm.flow = (forecastMonthLockRulesMet && !forecastMonthOverrideRulesMet) ? afm.flow : newFlow
            afy!.flowTotal += (forecastMonthLockRulesMet && !forecastMonthOverrideRulesMet) ? afm.flow : newFlow
          }
          afm.forecastTotal = afm.adjustments + afm.flow
          afy!.grandTotal += afm.forecastTotal
        }
      }
      state.accountForecasts.data.splice(existingIndex, 1, existing) // trigger vue update
    }
  },

  // Totals related
  setAccountForecastTotals(state, payload: ForecastTotalDisplayDto) {
    state.accountForecastTotals = payload
  },

  /**
   * Update account forecast totals based on the year changed and the given forecast id
   * @param state
   * @param param1
   */
  updateTotalForYearChange(state, { afy, forecastId }: { afy: AccountForecastYear; forecastId: number }) {
    const totalYear = state.accountForecastTotals.forecast.find(f => f.fiscalYear === afy.fiscalYear)!
    const plannerForecastYear = state.plannerForecasts.forecast.find(pf => pf.fiscalYear === afy.fiscalYear)!
    const originalTotalYear = state.originalAccountForecastTotals!.forecast.find(f => f.fiscalYear === afy.fiscalYear)!

    if (forecastId === state.originalAccountForecastEdit?.id) {
      const forecastEditor = state.originalAccountForecastEdit
      // find the right year
      const afyIndex = forecastEditor.forecast.findIndex(f => f.fiscalYear === afy.fiscalYear)
      const originalAfy = forecastEditor.forecast[afyIndex]

      if (originalAfy && totalYear && originalTotalYear) {
        const diffYearFlowTotal = afy.flowTotal - originalAfy.flowTotal // 0 - 300 = -300
        const diffYearAdjTotal = afy.adjustmentTotal - originalAfy.adjustmentTotal
        const diffYearGrandTotal = diffYearFlowTotal + diffYearAdjTotal
        totalYear.flowTotal = originalTotalYear.flowTotal + diffYearFlowTotal
        totalYear.grandTotal = originalTotalYear.grandTotal + diffYearGrandTotal
        totalYear.adjustmentTotal = originalTotalYear.adjustmentTotal + (afy.adjustmentTotal - originalAfy.adjustmentTotal)
        if (plannerForecastYear) {
          plannerForecastYear.accountForecast.adjustmentTotal = totalYear.adjustmentTotal
        }

        let diffFlow: number
        let diffAdj: number
        let diffTotal: number
        for (let index = 0; index < afy.forecastMonths.length; index++) {
          diffFlow = afy.forecastMonths[index].flow - originalAfy.forecastMonths[index].flow
          diffAdj = afy.forecastMonths[index].adjustments - originalAfy.forecastMonths[index].adjustments
          diffTotal = diffFlow + diffAdj
          totalYear.monthTotals[index].flowTotal = originalTotalYear.monthTotals[index].flowTotal + diffFlow
          totalYear.monthTotals[index].grandTotal = originalTotalYear.monthTotals[index].grandTotal + diffTotal
          totalYear.monthTotals[index].adjustmentTotal = originalTotalYear.monthTotals[index].adjustmentTotal + diffAdj
          if (plannerForecastYear) {
            plannerForecastYear.accountForecast.monthTotals[index].adjustmentTotal = totalYear.monthTotals[index].adjustmentTotal
          }
        }

        Vue.set(state, 'accountForecastTotals', state.accountForecastTotals) // trigger update
        Vue.set(state, 'plannerForecasts', state.plannerForecasts)
      }
    }
  },

  updateTotalForMonthChange(state, { afm, fiscalYear, forecastId }: { afm: AccountForecastMonth; fiscalYear: number; forecastId: number }) {
    // find the right total year
    const totalYear = state.accountForecastTotals.forecast.find(f => f.fiscalYear === fiscalYear)!
    const plannerForecastYear = state.plannerForecasts.forecast.find(pf => pf.fiscalYear === fiscalYear)!
    const originalTotalYear = state.originalAccountForecastTotals?.forecast.find(f => f.fiscalYear === fiscalYear)!

    // Find the right account forecast
    if (forecastId === state.originalAccountForecastEdit?.id) {
      const originalForecastEditor = state.originalAccountForecastEdit
      // find the right year
      const afyIndex = originalForecastEditor.forecast.findIndex(f => f.fiscalYear === fiscalYear)
      const originalAfy = originalForecastEditor.forecast[afyIndex]
      if (originalAfy && originalTotalYear && totalYear) {
        // find the right month; cannot lookup by detailId here because all detail ids are 0 for new forecasts until saved.
        const originalAfm = originalAfy.forecastMonths.find(m => m.calendarYear === afm.calendarYear && m.month === afm.month)
        const originalTotalMonth = originalTotalYear.monthTotals.find(m => m.calendarYear === afm.calendarYear && m.month === afm.month)!
        if (originalAfm) {
          // do the update!
          const diffAdj = afm.adjustments - originalAfm.adjustments
          const diffFlow = afm.flow - originalAfm.flow
          const diffTotal = diffAdj + diffFlow

          totalYear.flowTotal = originalTotalYear.flowTotal + diffFlow
          totalYear.adjustmentTotal = originalTotalYear.adjustmentTotal + diffAdj
          totalYear.grandTotal = originalTotalYear.grandTotal + diffTotal

          const totalMonth = totalYear.monthTotals.find(m => m.calendarYear === afm.calendarYear && m.month === afm.month)!
          if (plannerForecastYear) {
            const plannerMonth = plannerForecastYear.accountForecast.monthTotals.find(m => m.calendarYear === afm.calendarYear && m.month === afm.month)!
            plannerMonth.adjustmentTotal = totalMonth.adjustmentTotal
          }
          totalMonth.adjustmentTotal = originalTotalMonth.adjustmentTotal + diffAdj
          totalMonth.flowTotal = originalTotalMonth.flowTotal + diffFlow
          totalMonth.grandTotal = originalTotalMonth.grandTotal + diffTotal

          Vue.set(state, 'accountForecastTotals', state.accountForecastTotals) // trigger update
          Vue.set(state, 'plannerForecasts', state.plannerForecasts)
        }
      }
    }
  },

  // Planner Forecast Related Mutations
  toggleLoadingPlannerForecasts(state) {
    state.loadingPlannerForecasts = !state.loadingPlannerForecasts
  },

  toggleSavingPlannerForecast(state) {
    state.savingPlannerForecast = !state.savingPlannerForecast
  },

  revertPlannerForecast(state) {
    const original = authSubject('forecast', _.cloneDeep(state.originalPlannerForecasts!))
    state.plannerForecasts = original
    state.plannerForecastDirty = false
  },

  clearPlannerForecast(state, { settings, vueAuth, plannerForecast }: { settings: SystemSettingDto; vueAuth: VueAuth; plannerForecast: PlannerForecastEdit }) {
    const fc = state.plannerForecasts.forecast
    for (const pfYear of fc) {
      for (const pfMonth of pfYear.forecastMonths) {
        const forecastMonthLockRulesMet = Common.areAnyForecastMonthLockRulesMet(pfMonth.month, pfMonth.calendarYear, settings, vueAuth, state.initialTenantSalesRep, plannerForecast.product, plannerForecast.product.accountForecasts!)
        const forecastMonthOverrideRulesMet = Common.areAllForecastOverrideRulesMet(settings, vueAuth, state.initialTenantSalesRep, plannerForecast.product, plannerForecast.product.accountForecasts!)

        if (!forecastMonthLockRulesMet || forecastMonthOverrideRulesMet) {
          pfMonth.adjustmentAmount = 0
        }
      }
    }
    Vue.set(state, 'plannerForecasts', state.plannerForecasts)
    state.plannerForecastDirty = true
  },

  markPlannerForecastDirty(state) {
    state.plannerForecastDirty = true
  },

  setPlannerForecasts(state, payload: PlannerForecastEdit) {
    state.plannerForecasts = payload
    state.originalPlannerForecasts = _.cloneDeep(payload)
  },

  markPlannerForecastUpdated(state) {
    Vue.set(state, 'plannerForecasts', state.plannerForecasts)
    state.plannerForecastDirty = true
  },

  resetPlannerForecastAfterSave(state) {
    state.plannerForecastDirty = false
    state.originalPlannerForecasts = _.cloneDeep(state.plannerForecasts)
  },
}

const namespaced = true

export const forecastEdit: Module<ForecastEditState, RootState> = {
  namespaced: namespaced,
  state,
  getters,
  actions,
  mutations
}
