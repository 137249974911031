



























import { EventBus, InterWindowEvents, SafioEvents, WindowBus } from '@/services/event-bus'
import ProductService from '@/services/v3/product-service'
import { ProductDto } from '@basic-code/shared'
import { BSpinner } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { FilterSettings, SearchSku } from '../types'

const skipRegex = / - /

@Component<SkuAutoComplete>({
  components: {
    BSpinner,
    ValidationProvider,
  }
})
export default class SkuAutoComplete extends Vue {
  private skus: ProductDto[] = []

  value = ''

  // eslint-disable-next-line
  private searchTimeout: any
  private searching = false

  @Prop({ default: '' })
  sku: string

  @Prop({type: String, default: ''})
  customClass: string

  @Prop({ default: 'sm' })
  size: string

  @Prop({ default: false })
  readonly: boolean

  @Prop({ default: (s: ProductDto) => `${s.sku} - ${s.description}`, type: Function, validator: d => d instanceof Function })
  serializer: Function

  @Prop({ default: true })
  emitSkuSelected: boolean

  @Prop({ default: true })
  onSkuFilter: boolean

  @Prop()
  filterSettings: FilterSettings

  @Watch('sku', { immediate: true })
  onActiveSkuChanged() {
    this.value = this.sku
    const typeahead = this.$refs.typeahead as any
    if (typeahead) {
      typeahead.inputValue = this.sku
    }
  }

  @Watch('value')
  onSearchTextChange(qry: string) {
    return this.searchSkus(qry)
  }

  public async searchSkus(query: string) {
    if (query.length < 2 || skipRegex.test(query)) { return }
    if (this.searchTimeout) { clearTimeout(this.searchTimeout) }
    this.searchTimeout = setTimeout(async () => {
      this.searching = true
      if (!this.onSkuFilter) {
        this.skus = await ProductService.getAllProductsForFilter(true, query)
      } else {
        this.skus = await ProductService.getAllProductsForFilterUsingFilterSettings(this.filterSettings, query)
      }
      this.searching = false
    }, 500)

  }

  private selectSku($event: SearchSku) {
    this.$emit('local-sku-select', $event)
    if (this.emitSkuSelected) {
      EventBus.$emit(SafioEvents.skuSelected, $event)
      WindowBus.$emit(InterWindowEvents.skuSelected, $event)
    }
  }


clear() {
  this.value = '';
  (document.getElementsByClassName('sku-auto-complete-input')[0] as HTMLElement).focus();
  this.$emit('clear-sku-selection'); // Emit event to inform that SKU should be cleared.
}


  getStyleClass (existing: any) {
    const classes: any = existing || {}
    if (this.customClass) {
      classes[this.customClass] = true
    }
    return classes
  }

  getStyleClassString (existing: any) {
    const parts: string[] = []
    for (const key of Object.keys(existing)) {
      if (existing[key]) {
        parts.push(key)
      }
    }
    return parts.join(' ')
  }
}
