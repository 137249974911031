












import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ValidationProvider } from "vee-validate"

@Component({
  components: {
    ValidationProvider,
  },
})
export default class ValidatingDatePicker extends Vue {

  @Prop({})
  public vid: string

  @Prop({ default: '' })
  public rules: string

  @Prop({})
  value: any

  public innerValue: any = ''

  @Watch('innerValue')
  onInnerValueChange(newVal: any) {
    this.$emit('input', newVal)
  }

  @Watch('value')
  onValueChange(newVal: any) {
    this.innerValue = newVal
  }

  onContextChange(context: any) {
    this.$emit('context', context)
  }
}
