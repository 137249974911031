import { Vue } from 'vue-property-decorator'
import { EventBus, SafioEvents } from '@/services/event-bus'
import purchaseOrderService from '@/services/v3/purchase-order-service'
import { PlannerProposedOrdersDtoV3, ProductFilter, ProductFilterField } from '@basic-code/shared'
import _ from 'lodash'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState, WorksheetState } from '../types'
import { FilterSettings } from '@/types'

const state: WorksheetState = {
  placeholder: '',
  proposedOrders: [],
  originalProposedOrders: [],
  loadingProposedOrders: false,
  skuFilters: {
    filters: [],
    sortDirection: ''
  },
}

const getters: GetterTree<WorksheetState, RootState> = {
  currentProposedQty(state): number {
    const today = new Date()
    const ppo = state.proposedOrders.find(ppo => ppo.calendarYear === today.getFullYear() && ppo.month === today.getMonth())
    return ppo?.forecastOrders || 0
  },

  totalProposedQty(state): number {
    return state.proposedOrders.reduce((prev, curr) => { return prev + curr.forecastOrders }, 0)
  },
  skuFilters(state): FilterSettings {
    return state.skuFilters
  },
  topCustomerFilter(state): ProductFilter|undefined {
    if (state.skuFilters.filters?.length) {
      return state.skuFilters.filters.find(f => f.field === ProductFilterField.topCustomer)
    }
  }
}

const actions: ActionTree<WorksheetState, RootState> = {
  async saveProposedOrders({ commit }) {
    commit('toggleLoadingProposedOrders')

    try {
      const result = await purchaseOrderService.savePlannerProposedOrders(state.proposedOrders)
      if(result){
        EventBus.$emit(SafioEvents.recordReviewedUpdated, new Date())
        commit('setProposedOrders', result)
      }
    } catch (err) {
      console.error(err)
      throw err
    } finally {
      commit('toggleLoadingProposedOrders')
    }
  }
}

const mutations: MutationTree<WorksheetState> = {

  toggleLoadingProposedOrders(state) {
    state.loadingProposedOrders = !state.loadingProposedOrders
  },

  setProposedOrders(state, payload: PlannerProposedOrdersDtoV3[]) {
    state.proposedOrders = payload
    state.originalProposedOrders = _.cloneDeep(payload)
  },

  revertProposedOrders(state) {
    state.proposedOrders = state.originalProposedOrders
    state.originalProposedOrders = _.cloneDeep(state.proposedOrders)
  },

  clearProposedOrders(state) {
    for (const ppo of state.proposedOrders) {
      ppo.forecastOrders = 0
    }
    Vue.set(state, 'proposedOrders', state.proposedOrders)
  },

  setSkuFilters(state, payload: FilterSettings) {
    state.skuFilters = payload
  }
}

const namespaced = true

export const worksheetStore: Module<WorksheetState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}