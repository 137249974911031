import {BaseHttpService} from '@/services/base-http-service'
import {FilterSettings} from '@/types'
import {
    BillOfMaterialDto,
    ChildBillOfMaterialInfoDto,
    Forecast,
    ParentBillOfMaterialInfoDto,
    ProductActionDto,
    ProductAttributeDto,
    ProductAttributeValueDto,
    ProductDto,
    ProductMarkerDto
} from '@basic-code/shared'

declare type ShopifyVariant = {
    shopName: string;
    variantId: number;
    sku: string;
}

export type PlannerForecastFilter = {
    planner: string|null;
    vendorId: number|null;
    attributeId: number|null;
    attributeOptionId: number|null;
    productGroupId: number|null;
}

class ProductService extends BaseHttpService {
    public async getProduct(id: number, includeProductAttributeOptions = true, includeProductAttributeValues = true): Promise<ProductDto> {
        const uri = `/products/${id}?includeProductAttributeOptions=${includeProductAttributeOptions}&includeProductAttributeValues=${includeProductAttributeValues}`
        const response = await this.doApiGet<ProductDto>(uri)
        if (response.success) {
            return ProductDto.create(response.response!)
        }
        throw new Error(response.error!.message)
    }

    public async getProducts(searchValue?: string): Promise<ProductDto[]> {
        const uri = `/products${searchValue ? `?searchValue=${searchValue}` : ''}`
        const response = await this.doApiGet<ProductDto[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateProduct(id: number, product: Partial<ProductDto>, shopifyVariant: ShopifyVariant|null = null): Promise<ProductDto> {
        const uri = `/products/${id}`
        const response = await this.doApiPut<ProductDto>(uri, JSON.stringify({ product: product, shopifyVariant: shopifyVariant }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateWorksheetForProducts(idList: number[] = []): Promise<boolean> {
        const uri=`/update-worksheet-for-products`
        const response = await this.doApiPut(uri, JSON.stringify({ idList: idList }))
        if (response.success) {
            return response.response! as boolean
        }
        throw new Error(response.error!.message)
    }

    public async getByNewStatus(isNew: boolean, viewInAppProductsOnly = false): Promise<ProductDto[]> {
        const uri = `/products?isNew=${isNew}&viewInAppProductsOnly=${viewInAppProductsOnly}`
        const response = await this.doApiGet<ProductDto[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getByProductId(productGroupId: number, countOnly = false): Promise<ProductDto[]|number> {
        const uri = `/products?productGroupId=${productGroupId}&counterOnly=${countOnly}`
        const response = await this.doApiGet<ProductDto[]|number>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getBySku(sku: string, includeProductAttributeOptions = true, includeProductAttributeValues = true): Promise<ProductDto> {
        const uri = `/products?sku=${encodeURIComponent(sku)}&includeProductAttributeOptions=${includeProductAttributeOptions}&includeProductAttributeValues=${includeProductAttributeValues}`
        const response = await this.doApiGet<ProductDto>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getProductsForLockOverrideRules(id: number|null, skus: string[]|null): Promise<ProductDto[]> {
        const encodedSkuQueryParams = skus === null
            ? `skus[]=${skus}`
            : skus!.map(sku => `skus[]=${encodeURIComponent(sku)}`).join("&");

        const uri = `/product-for-lock-override?id=${id}&${encodedSkuQueryParams}`
        const response = await this.doApiGet<ProductDto[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getProductsWithCurves(id: number|null = null): Promise<Partial<ProductDto>[]> {
        const uri = `/product-curves${id ? `?id=${id}` : ''}`
        const response = await this.doApiGet<Partial<ProductDto>[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async checkForDuplicateSku(sku: string, productId: number|null = null): Promise<boolean> {
        const uri = `/check-duplicate-sku?sku=${encodeURIComponent(sku)}${productId ? `&productId=${productId}` : ''}`
        const response = await this.doApiGet(uri)
        if (response.success) {
            return response.response! as boolean
        }
        throw new Error(response.error!.message)
    }

    public async updateProductCurves(curveAttr1Id: number, curveAttr2Id: number, curveAttr3Id: number): Promise<boolean> {
        const uri = '/update-product-curves'
        const response = await this.doApiPut<any>(uri, JSON.stringify({
            curveAttr1Id: curveAttr1Id,
            curveAttr2Id: curveAttr2Id,
            curveAttr3Id: curveAttr3Id,
        }))
        if (response.success) {
            return response.response! as boolean
        }
        throw new Error(response.error!.message)
    }

    public async updateProjectedForecastOverride(id: number, replaceProjectedForecast: boolean): Promise<boolean> {
        const uri = `/products/${id}/update-projected-forecast-override`
        const response = await this.doApiPut<boolean>(uri, JSON.stringify({ replaceProjectedForecast: replaceProjectedForecast }))
        if (response.success) {
            return response.response! as boolean
        }
        throw new Error(response.error!.message)
    }

    public async updateAddBomTotals(id: number, addBomTotals: boolean): Promise<boolean> {
        const uri = `/products/${id}/update-add-bom-totals`
        const response = await this.doApiPut<boolean>(uri, JSON.stringify({ addBomTotals: addBomTotals }))
        if (response.success) {
            return response.response! as boolean
        }
        throw new Error(response.error!.message)
    }

    public async getPlannerForecastProductIds(plannerForecastDownloadFilter: PlannerForecastFilter): Promise<number[]> {
        let queryString = ''
        if (plannerForecastDownloadFilter.planner || plannerForecastDownloadFilter.vendorId || plannerForecastDownloadFilter.attributeId ||
            plannerForecastDownloadFilter.attributeOptionId || plannerForecastDownloadFilter.productGroupId) {
            queryString += '?'
            if (plannerForecastDownloadFilter.planner) {
                queryString+= `planner=${encodeURIComponent(plannerForecastDownloadFilter.planner)}`
            }
            if (plannerForecastDownloadFilter.vendorId) {
                queryString += `${queryString.length > 1 ? '&' : ''}vendorId=${plannerForecastDownloadFilter.vendorId}`
            }
            if (plannerForecastDownloadFilter.attributeId && plannerForecastDownloadFilter.attributeOptionId) {
                queryString += `${queryString.length > 1 ? '&' : ''}attributeId=${plannerForecastDownloadFilter.attributeId}&attributeOptionId=${plannerForecastDownloadFilter.attributeOptionId}`
            }
            if (plannerForecastDownloadFilter.productGroupId) {
                queryString += `${queryString.length > 1 ? '&' : ''}productGroupId=${plannerForecastDownloadFilter.productGroupId}`
            }
        }
        const uri = `/planner-forecast-product-ids${queryString}`
        const response = await this.doApiGet<number[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getChildBom(productId: number): Promise<ChildBillOfMaterialInfoDto[]> {
        const uri = `/products/${productId}/child-bom`
        const response = await this.doApiGet<ChildBillOfMaterialInfoDto[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getAllProductsForFilter(viewInAppProductsOnly: boolean, searchValue: string|null = null): Promise<ProductDto[]> {
        const uri = `/products/?viewInAppProductsOnly=${viewInAppProductsOnly}&autocomplete=true${searchValue ? `&searchValue=${encodeURIComponent(searchValue)}` : ''}`
        const response = await this.doApiGet<ProductDto[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getParentBom(productId: number): Promise<ParentBillOfMaterialInfoDto[]> {
        const uri = `/products/${productId}/parent-bom`
        const response = await this.doApiGet<ParentBillOfMaterialInfoDto[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getAllProductsForFilterUsingFilterSettings(filterSettings: FilterSettings, searchValue: string): Promise<ProductDto[]> {
        const data = {
            filters: filterSettings.filters,
            searchValue: searchValue,
        }
        const uri = '/search/products'
        const response = await this.doApiPost<ProductDto[]>(uri, JSON.stringify(data))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message || 'error searching')
    }

    public async getProductAttributeValues(productId: number, includeProductAttributeOptions = false): Promise<ProductAttributeValueDto[]> {
        const uri = `/products/${productId}/product-attribute-values?includeProductAttributeOptions=${includeProductAttributeOptions}`
        const response = await this.doApiGet<ProductAttributeValueDto[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getForecast(productId: number): Promise<Forecast> {
        const uri = `/products/${productId}/forecast`
        const response = await this.doApiGet<Forecast>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getProductAttributes(): Promise<ProductAttributeDto[]> {
        const uri = '/product-attributes'
        const response = await this.doApiGet<ProductAttributeDto[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateProductAction(productId: number, markerName: string, value: string): Promise<ProductActionDto[]> {
        const uri = `/products/${productId}/product-actions`
        const response = await this.doApiPut<ProductActionDto[]>(uri, JSON.stringify({
            name: markerName,
            value: value,
        }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getProductMarkers(): Promise<ProductMarkerDto[]> {
        const uri = '/product-markers'
        const response = await this.doApiGet<ProductMarkerDto[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getBillOfMaterials(productId: number): Promise<BillOfMaterialDto[]> {
        const uri = `/products/${productId}/bill-of-materials`
        const response = await this.doApiGet<BillOfMaterialDto[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new ProductService()
