import { BaseHttpService } from '@/services/base-http-service'
import { DataImportDto } from '@basic-code/shared'

class DataImportService extends BaseHttpService {
    public async getDataImport(type: string, id: number): Promise<DataImportDto> {
        const uri = `/data-imports/${type}/${id}`
        const response = await this.doApiGet<DataImportDto>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getDataImports(type: string): Promise<DataImportDto[]> {
        const uri = `/data-imports/${type}`
        const response = await this.doApiGet<DataImportDto[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateDataImport(id: number, dataImport: Partial<DataImportDto>): Promise<DataImportDto> {
        const uri = `/data-imports/${id}`
        const response = await this.doApiPut<DataImportDto>(uri, JSON.stringify({ dataImport: dataImport }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async runDataImport(id: number, dataImport: DataImportDto, importMultipleFiles: boolean, dataGroupDataImportId: number|null = null): Promise<boolean> {
        const uri = `/data-imports/${id}/run`
        const response = await this.doApiPost<boolean>(uri, JSON.stringify({ dataImport: dataImport, importMultipleFiles: importMultipleFiles, dataGroupDataImportId: dataGroupDataImportId }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async processCSV(formData: FormData): Promise<any[]> {
        const uri = '/data-import/process-csv-file'
        const response = await this.doFileUpload<any[]>('POST', uri, formData)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async uploadFlatFile(data: any[], importName: string, fileType: string): Promise<any> {
        const uri = `/data-import/${importName.toLowerCase().replaceAll('_', '-')}/manual-import`
        const response = await this.doApiPost(uri, JSON.stringify({ data: data, source: `${importName}.${fileType}` }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getErrorNumber(importName: string): Promise<string> {
        const uri = `/data-import/error-numbers/${importName}`
        const response = await this.doApiGet<string>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new DataImportService()
