import { BaseHttpService } from '@/services/base-http-service'
import { AccountDto, DropdownOption } from '@basic-code/shared'

class AccountService extends BaseHttpService {
    public async getAccount(id: number): Promise<AccountDto> {
        const uri = `/accounts/${id}`
        const response = await this.doApiGet<AccountDto>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getAccounts(): Promise<AccountDto[]> {
        const uri = '/accounts'
        const response = await this.doApiGet<AccountDto[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getAccountsForForecastLock (salesReps: DropdownOption[], vendors: DropdownOption[], productAttributeId: number|null, productAttributeOptionId: number|null): Promise<AccountDto[]> {
        const uri = '/accounts-for-forecast-lock'
        const response = await this.doApiPost<AccountDto[]>(uri, JSON.stringify({
            salesReps: salesReps.map(sr => sr.value), vendors: vendors.map(v => v.value), productAttributeId: productAttributeId, productAttributeOptionId: productAttributeOptionId
        }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new AccountService()
