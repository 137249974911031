import Vue from 'vue'
import { BootstrapVue } from 'bootstrap-vue'

export default class GlobalConfig {
  public static registerBootstrapVue () {
    Vue.use(BootstrapVue, {
      BTooltip: { // Eliminates need for v-b-tooltip.hover.blur="blah"
        container: window,
        placement: 'top',
        delay: {
          show: 800,
        },
        variant: 'primary',
      },
      BSpinner: {
        variant: 'primary',
        type: 'border'
      }
    })
    Vue.directive('safio-focus', {
      
      inserted: function focusDirective(el: HTMLElement) {
        el.onfocus = () => {
          const inputEl = el as HTMLInputElement
          inputEl.select()
        }
      }
    })
  }
}
