import { BaseHttpService } from '@/services/base-http-service'
import { AdminViewDto } from '@/types'

class AdminViewService extends BaseHttpService {
    public async getAdminView(id: number): Promise<AdminViewDto> {
        const uri = `/admin-views/${id}`
        const response = await this.doApiGet<AdminViewDto>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getAdminViews(): Promise<AdminViewDto[]> {
        const uri = '/admin-views'
        const response = await this.doApiGet<AdminViewDto[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async createAdminView(adminView: Partial<AdminViewDto>): Promise<AdminViewDto> {
        const uri = '/admin-views'
        const response = await this.doApiPost<AdminViewDto>(uri, JSON.stringify({ adminView: adminView }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateAdminView(id: number, adminView: Partial<AdminViewDto>, oldCreateOrder = -1): Promise<AdminViewDto> {
        const uri = `/admin-views/${id}`
        const response = await this.doApiPut<AdminViewDto>(uri, JSON.stringify({ adminView: adminView, oldCreateOrder: oldCreateOrder }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async deleteAdminView(id: number): Promise<{ response: any; success: boolean; message: string }> {
        const uri = `/admin-views/${id}`
        const response = await this.doApiDelete(uri)
        if (response.success) {
            return response.response! as any
        }
        throw new Error(response.error!.message)
    }

    public async rebuildAllViews(): Promise<{ response: any; success: boolean; message: string }> {
        const uri = '/rebuild-all-views'
        const response = await this.doApiPost(uri)
        if (response.success) {
            return response.response! as any
        }
        throw new Error(response.error!.message)
    }
}

export default new AdminViewService()
